import React, { FC } from "react";
import { shallowEqual } from "react-redux";
import { useAppSelector, useAppDispatch } from "@src/hooks";
import Date from "@components/AddedDate";
import { Grey } from "@components/ColoredText";
import {
  TableHeadCell,
  TableHeader,
  TableBodyRow,
  TableBodyCell
} from "@components/WeVatTableV2";
import { RetailerQuerieSort } from "@api/retailers";
import { RetailerResponse } from "@api/retailers";
import { capitaliseFirst } from "@utils/strings";
import { onSortChange } from "@store/reducers/retailers-table-reducer";
import { startEditRetailer } from "@store/reducers/edit-retailer-reducer"

import "../css/retailers-table.css";

const gridTemplateColumns = "25px 2fr 1fr 3fr 1fr 1fr 1fr 1fr 1fr";

const SUPPORTED_COUNTRIES_FLAGS_MAP = {
  GB: "🇬🇧", 
  FR: "🇫🇷"
};

const RetailersTable = () => {

  const dispatch = useAppDispatch();

  const {
    retailers,
    sort,
  } = useAppSelector(mapStateToProps, shallowEqual);

  const onSortChangeDispatch =  onSortChange(dispatch);
  const startEditRetailerDispatch =  startEditRetailer(dispatch);
  
  return <div className="retailers-table">
      <TitleRow sort={sort} onSortChange={onSortChangeDispatch} />
      {retailers && retailers.size === 0 ? (
        <span>No results...</span>
      ) : (
        retailers.map((r, index) => (
          <RetailerRow
            index={index}
            key={r.retailerId}
            retailer={r}
            onClick={() => startEditRetailerDispatch(r)} 
            />
        ))
      )}
    </div>
};

const TitleRow: FC<{ sort: RetailerQuerieSort; onSortChange: (sort: RetailerQuerieSort) => void }> = ({
  sort,
  onSortChange
}) => (
  <TableHeader gridTemplateColumns={gridTemplateColumns}>
    <TableHeadCell title="" />
    <TableHeadCell title="Retailer Name" />
    <TableHeadCell title="Representative" />
    <TableHeadCell title="Address" />
    <TableHeadCell title="VAT no." />
    <TableHeadCell title="SIREN" />
    <TableHeadCell title="SIRET" />

    <TableHeadCell
      title="Added"
      alignCenter={true}
      index="CREATED_AT"
      sortBy={sort.by}
      sortDirection={sort.direction}
      onSortChange={(sortBy, sortDirection) =>
        onSortChange({ by: sortBy, direction: sortDirection })
      }
    />
    <TableHeadCell
      title="Last Modified"
      alignCenter={true}
      index="MODIFIED_AT"
      sortBy={sort.by}
      sortDirection={sort.direction}
      onSortChange={(sortBy, sortDirection) =>
        onSortChange({ by: sortBy, direction: sortDirection })
      }
    />
  </TableHeader>
);


const RetailerRow: FC<{ retailer: RetailerResponse; onClick: () => void; index: number }> = ({
  retailer,
  onClick,
  index
}) => (
  <TableBodyRow
    index={index}
    className="user-table-view"
    gridTemplateColumns={gridTemplateColumns}
    onClick={onClick}
  >
    <TableBodyCell alignRight={true}>{SUPPORTED_COUNTRIES_FLAGS_MAP[retailer.country]}</TableBodyCell>
    <TableBodyCell>
      <p>{(retailer.deprecated ? "[DEPRECATED] " : "") + retailer.retailerName}</p>
    </TableBodyCell>
    <TableBodyCell>
      <p>{retailer.isBrandRepresentative ? "Yes" : "No"}</p>
    </TableBodyCell>
    <TableBodyCell>
      <p>{retailer && retailer.addressLine ? `${retailer.addressLine}, ${retailer.addressPostalCode}, ${retailer.addressCity}` : <Grey>&lt;no address provided&gt;</Grey>}</p>
    </TableBodyCell>
    <TableBodyCell>
      <p>{retailer.vatNumber}</p>
    </TableBodyCell>
    <TableBodyCell>
      <p>{retailer.siren}</p>
    </TableBodyCell>
    <TableBodyCell>
      <p>{retailer.siret}</p>
    </TableBodyCell>
    <TableBodyCell alignCenter={true}>
      {retailer?.createdAt && (
        <Date
          defaultFormat="yyyy-MM-dd hh:mm"
          date={retailer.createdAt}
        />
      )}
    </TableBodyCell>
    <TableBodyCell alignCenter={true}>
      {retailer?.modifiedAt && (
        <Date
          defaultFormat="yyyy-MM-dd hh:mm"
          date={retailer.modifiedAt}
        />
      )}
    </TableBodyCell>
  </TableBodyRow>
);

const mapStateToProps = (state) => {
  return {
    ...state.retailersTable,
  };
};

export default RetailersTable;
