import React from "react";
import { LocalDateTime, DateTimeFormatter, LocalDate } from "js-joda";
import {
  WEVAT_DF,
  WEVAT_DTF,
  WEVAT_TIME_FORMATTER,
  isToday,
  dayMonthString,
  isDateTime
} from "../utils/dates";
import { Grey } from "./ColoredText";

export const AddedDate = ({ defaultFormat = "", ...props }) => (
  <Grey {...props}>
    <Date date={props.date} defaultFormat={defaultFormat} />
  </Grey>
);

export const Date = ({ date, defaultFormat }) => {
  const noTime = !isDateTime(date);
  const parsedDate = LocalDate.parse(date, noTime ? WEVAT_DF : WEVAT_DTF);
  const parsedDateTime = !noTime ? LocalDateTime.parse(date, WEVAT_DTF) : null;

  if (isToday(parsedDate)) {
    return `Today ${noTime ? "" : parsedDateTime.format(WEVAT_TIME_FORMATTER)}`;
  }

  if (isToday(parsedDate.minusDays(1))) {
    return `Tomorrow ${
      noTime ? "" : parsedDateTime.format(WEVAT_TIME_FORMATTER)
    }`;
  }

  if (isToday(parsedDate.plusDays(1))) {
    return `Yesterday ${
      noTime ? "" : parsedDateTime.format(WEVAT_TIME_FORMATTER)
    }`;
  }

  if (defaultFormat) {
    return noTime
      ? parsedDate.format(
          DateTimeFormatter.ofPattern(defaultFormat.split(" ")[0])
        )
      : parsedDateTime.format(DateTimeFormatter.ofPattern(defaultFormat));
  }

  return `${dayMonthString(parsedDate)}${
    !noTime ? " " + parsedDateTime.format(WEVAT_TIME_FORMATTER) : ""
  }`;
};

export default AddedDate;
