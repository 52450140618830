import React, { FC } from "react";
import classNames from "classnames";

import "./ReceiptStatus.scss";

type Props = {
  size?: "small" | "auto";
  theme?: "gray" | "green" | "red" | "dark-gray" | "blue";
  label?: string;
  status?: string;
};

const ReceiptStatus: FC<Props> = ({ size, status, theme, label }) => {
  const receipt = getStatus(status);
  return (
    <div
      className={classNames("receipt-status", {
        "receipt-status--pending": receipt.isPending || theme === "gray",
        "receipt-status--accepted": receipt.isAccepted || theme === "green",
        "receipt-status--rejected": receipt.isRejected || theme === "red",
        "receipt-status--select-all": theme === "blue",
        "receipt-status--dark-gray": theme === "dark-gray",
        "receipt-status--small": size === "small",
        "receipt-status--auto": size === "auto"
      })}
    >
      {receipt.label || label}
    </div>
  );
};

const getStatus = (status: string) => {
  switch (status) {
    case "PENDING":
    case "NEEDS_REVIEW":
    case "CONFIRM_REJECTION":
      return {
        label: "Pending",
        isPending: true
      };
    case "REVIEWED":
      return {
        label: "Accepted",
        isAccepted: true
      };
    case "REJECTED":
      return {
        label: "Rejected",
        isRejected: true
      };
    case "CONTACT_SUPPORT":
      return {
        label: "Contact support",
        isRejected: true
      };
    case "PURCHASE_VALUE_OVER_LIMIT":
      return {
        label: "Over €2,000",
        isRejected: true
      };
    default:
      return { label: "" };
  }
};

export default ReceiptStatus;
