import { wevat } from "../../api/http-client";
import {
  clearToken,
  setToken,
  stripBearer,
  throwIfNotAdmin,
} from "../../api/tokens";

export const attemptLogin = (username, password) => {
  return wevat({ authRequired: false, adminApi: false })
    .post("/auth/login", { username, password })
    .then((response) => {
      cacheJwt(response);
      cacheUsername(username);
      navigateToRoot();
      throwIfNotAdmin();
    })
    .catch((err) => {
      clearToken();
      clearUsername();
      alert(`Error logging in - ${err.message}`);
    });
};

export const logout = () => {
  clearUsername();
  clearToken();
  window.location.reload();
};

const clearUsername = () => (localStorage.username = undefined);
const cacheUsername = (username) => (localStorage.username = username);
const cacheJwt = (response) =>
  setToken(stripBearer(response.headers.get("Authorization")));
const navigateToRoot = () => window.location.replace("/");
