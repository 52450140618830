import React from "react";

const Red = props => <Text color={"red"} {...props} />;

const Green = props => <Text color={"#23b28d"} {...props} />;

const Grey = props => <Text color={"#909090"} {...props} />;

const Blue = props => <Text color={"#173b5c"} {...props} />;

const Yellow = props => <Text color={"#f2b41e"} {...props} />;

const Text = props => (
  <span style={{ ...props.style, color: props.color }} {...props}>
    {props.children}
  </span>
);

export { Red, Green, Grey, Blue, Yellow };
