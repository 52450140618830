import React, { FC } from "react";
import classNames from "classnames";
import dayjs from "dayjs";

import {
  selectRejectionReasons,
  selectReceipt,
  selectIsRejectOrAccepted,
  selectTrip
} from "@store/selectors";
import { updateState } from "@store/actions";
import { formatCurrencyMoney } from "@utils/currency";
import { round } from "@utils/numbers";
import { useAppSelector, useAppDispatch } from "@src/hooks";
import ReceiptFooter from "../receipt-footer";

import "./ReceiptVerification.scss";

const baseClassName = "receipt-verification";

type Props = {
  onShowContact: () => void;
};

const ReceiptVerification: FC<Props> = ({ onShowContact }) => {
  const dispatch = useAppDispatch();
  const reasons = useAppSelector(selectRejectionReasons);
  const {
    items,
    currency,
    retailer,
    purchaseTime,
    total,
    approvedTaxTotal,
    approvedTotal,
    rejectionReasons,
    receiptNumber,
    isDigitalReceipt,
  } = useAppSelector(selectReceipt);
  const { userId, status } = useAppSelector(selectTrip);

  const receiptDateHasTime = purchaseTime?.indexOf(" ") !== -1;
  const receiptDate = dayjs(purchaseTime);
  const { isRejected, isAccepted, isDeleted } = useAppSelector(
    selectIsRejectOrAccepted
  );
  const retailerName = retailer?.retailerName ?? "";
  const retailerAddress = retailer && retailer.addressLine ? `${retailer.addressLine}, ${retailer.addressPostalCode}, ${retailer.addressCity}` : "";
  const retailerVatNumber = retailer?.vatNumber ?? "";
  const retailerSiret = retailer?.siret ?? "";

  const reasonsList = reasons.filter((r) => rejectionReasons.includes(r.id));

  const onReverify = () => {
    dispatch(updateState({ isReverify: true }));
  };

  const getTitle = () => {
    if (isDeleted) return "Deleted";
    return isAccepted ? "Accepted" : "Rejected";
  };

  return (
    <div className={baseClassName}>
      <div
        className={classNames(`${baseClassName}__title`, {
          [`${baseClassName}__title--accepted`]: isAccepted,
          [`${baseClassName}__title--rejected`]: isRejected
        })}
      >
        {getTitle()}
      </div>

      <div className={`${baseClassName}__content`}>
        {isRejected && (
          <div className={`${baseClassName}__card`}>
            <div className={`${baseClassName}__card--title`}>
              Rejection reasons
            </div>
            <div className={`${baseClassName}__table`}>
              {reasonsList.map((r, index) =>
                renderCol2(
                  <div className="wrap-items">
                    {`Rejection reason ${index + 1}`}
                    {r.isAutomated && (
                      <span className="reason__auto-reject">Auto-reject</span>
                    )}
                  </div>,
                  <div className="wrap-items">
                    {r.name}
                    {r.id === "CONTACT_SUPPORT" && (
                      <span
                        className="reason__contact-support"
                        onClick={onShowContact}
                      />
                    )}
                  </div>,
                  r.id
                )
              )}
            </div>
          </div>
        )}

        <div className={`${baseClassName}__card`}>
          <div className={`${baseClassName}__card--title`}>Invoice details</div>
          <div className={`${baseClassName}__table`}>
            {renderCol2("Invoice no.", receiptNumber)}
            {renderCol2("Invoice type", isDigitalReceipt ? "E-invoice" : "Paper invoice")}
            {renderCol2(
              "Purchase date",
              receiptDate.isValid() ? receiptDate.format("DD MMMM YYYY") : ""
            )}
            {purchaseTime &&
              renderCol2(
                "Purchase time",
                receiptDate.isValid() && receiptDateHasTime
                  ? receiptDate.format("HH:mm")
                  : ""
              )}

            {renderCol2("Retailer name", retailerName)}
            {renderCol2("Retailer address", retailerAddress)}
            {renderCol2("SIRET no.", retailerSiret)}
            {renderCol2("VAT no.", retailerVatNumber)}

            {renderCol2(
              "Total amount",
              total !== undefined ? formatCurrencyMoney(total, currency) : ""
            )}
            {renderCol2(
              "Approved amount",
              approvedTotal !== undefined
                ? formatCurrencyMoney(approvedTotal, currency)
                : ""
            )}
            {renderCol2(
              "Approved VAT",
              approvedTaxTotal !== undefined
                ? formatCurrencyMoney(approvedTaxTotal, currency)
                : ""
            )}
          </div>
        </div>

        <div className={`${baseClassName}__card`}>
          <div className={`${baseClassName}__card--title`}>Item details</div>
          <div className={`${baseClassName}__table`}>
            {renderCol6("Cat.", "Desc.", "VAT", "Qty.", "Price", "Status")}
            {items.map((item) =>
              renderCol6(
                item.categoryCode,
                item.description,
                `${round(item.vatRate, 1)}%`,
                `${item.quantity}`,
                formatCurrencyMoney(item.cost, currency),
                getStatus(item.isRejected),
                item.itemId
              )
            )}
          </div>
        </div>
      </div>

      <ReceiptFooter
        isConfirmDisabled={status === "COMPLETED"}
        confirmLabel="Re-verify"
        onConfirm={onReverify}
        userId={userId}
        onContact={onShowContact}
      />
    </div>
  );
};

const renderTd = (value: React.ReactNode) => (
  <div className={`${baseClassName}__table--td`}>{value || "-"}</div>
);

const renderCol6 = (
  col1: string,
  col2: string,
  col3: string,
  col4: string,
  col5: string,
  col6: string,
  key?: string
) => (
  <div className={`${baseClassName}__table--tr-6`} key={key}>
    {renderTd(col1)}
    {renderTd(col2)}
    {renderTd(col3)}
    {renderTd(col4)}
    {renderTd(col5)}
    {renderTd(col6)}
  </div>
);

const renderCol2 = (
  col1: React.ReactNode,
  col2: React.ReactNode,
  key?: string
) => (
  <div className={`${baseClassName}__table--tr-2`} key={key}>
    {renderTd(col1)}
    {renderTd(col2)}
  </div>
);

const getStatus = (isRejected: boolean) =>
  isRejected ? "Rejected" : "Accepted";

export default ReceiptVerification;
