import React, { FC, useState, useEffect, useCallback, useMemo } from "react";
import classNames from "classnames";

import closeIconWhite from "@assets/images/close-white.svg";
import { Grey } from "../ColoredText";
import TextInput from "../TextInput";
import "./DropdownV2.scss";

type Option = { label: React.ReactNode; value: string };

interface DropdownV2Props {
  isSearchable?: boolean;
  placeholder?: string;
  options: Option[];
  value?: string | null;
  onChange?: (value: string | null) => void;
  onMultiSelect?: (value: string[] | null) => void;
  width?: number | string;
  hideClear?: boolean;
  className?: string;
  isMultiSelect?: boolean;
  selectedOptions?: string[];
  defaultValue?: string;
  dataProp?: any;
  labelStyles?: object;
}

const DropdownV2: FC<DropdownV2Props> = ({
  isSearchable,
  placeholder = "Select option",
  options,
  value,
  onChange,
  width = "100%",
  hideClear,
  className,
  isMultiSelect,
  onMultiSelect,
  selectedOptions,
  defaultValue,
  dataProp,
  labelStyles
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [filteredList, setFilteredList] = useState<Option[]>([]);
  const [keyword, setKeyword] = useState("");
  const [dropdownValue, setDropdownValue] = useState(value || "");
  const [isFocused, setIsFocused] = useState(false);
  const list = filteredList.length ? filteredList : options;
  const [selectedItems, setSelectedItems] = useState(selectedOptions || []);
  const isItemsSelected = useMemo(
    () => isMultiSelect && selectedItems.length > 0,
    [isMultiSelect, selectedItems]
  );

  const toggleDropDown = () => setIsOpen((prevValue) => !prevValue);

  const closeDropDown = useCallback(() => {
    setIsOpen(false);
    handleInputBlur();
  }, []);

  const clearSelected = useCallback(() => {
    onMultiSelect?.([]);
    setSelectedItems([]);
    closeDropDown();
  }, [closeDropDown]);

  const handleSelectItem = (val: string) => {
    if (isMultiSelect) {
      let list = [...selectedItems];

      if (list.includes(val)) {
        const updatedList = list.filter((l) => l !== val);
        list = updatedList.length ? updatedList : [defaultValue];
      } else {
        list.push(val);
      }

      setSelectedItems(list);
      onMultiSelect?.(list);
    } else {
      onChange?.(val);
      setDropdownValue(val);
    }
    closeDropDown();
  };

  const handleMouseEvent = useCallback(
    (e: any) => {
      if (!e.target.className.includes("dropdown-v2")) {
        closeDropDown();
      }
    },
    [closeDropDown]
  );

  const handleChange = (text: string) => {
    const matchedResults = options.filter((r) => {
      const label = r.label as string;
      return label.toLowerCase().includes(text.toLowerCase());
    });
    setFilteredList(matchedResults);
    setKeyword(text);
  };

  const handleInputFocus = () => {
    setKeyword("");
    setIsFocused(true);
    setIsOpen(true);
  };

  const handleInputBlur = () => {
    setIsFocused(false);

    setFilteredList([]);
    setKeyword("");
  };

  const getLabel = () => {
    if (isMultiSelect && selectedOptions?.length) {
      return selectedOptions.join(", ");
    }
    return dropdownValue
      ? options.find((o) => o.value === dropdownValue).label
      : "";
  };

  const checkItemSelected = (itemValue: string) => {
    const list = selectedOptions?.length ? selectedOptions : selectedItems;
    return list?.includes(itemValue);
  };

  useEffect(() => {
    window.addEventListener("mouseup", handleMouseEvent);

    return () => {
      window.removeEventListener("mouseup", handleMouseEvent);
    };
  }, [handleMouseEvent]);

  return (
    <div
      className={classNames("dropdown-v2", className, {
        "dropdown-v2__items-selected": isItemsSelected
      })}
      style={{ width }}
      data-prop={dataProp}
      data-value={dropdownValue}
    >
      {isItemsSelected && (
        <div className="dropdown-v2__clear" onClick={clearSelected}>
          <img src={closeIconWhite} alt="close" />
        </div>
      )}
      {isSearchable ? (
        <TextInput
          placeholder={placeholder}
          className="dropdown-v2__input"
          value={isFocused ? keyword : (getLabel() as string)}
          onChange={(v) => handleChange(v)}
          onFocus={(e) => {
            e.stopPropagation();
            handleInputFocus();
          }}
        />
      ) : (
        <div
          style={{ width, ...labelStyles }}
          className={classNames("dropdown-v2__value", {
            "dropdown-v2__placeholder": !dropdownValue
          })}
          onClick={toggleDropDown}
        >
          {isItemsSelected || dropdownValue ? (
            <span className="t-value">{getLabel()}</span>
          ) : (
            <Grey>{placeholder}</Grey>
          )}
        </div>
      )}
      <div
        className={classNames("dropdown-v2__list", {
          "dropdown-v2__list--open": isOpen
        })}
      >
        {!hideClear && list.length && (
          <div
            className="dropdown-v2__list-item"
            onClick={() => handleSelectItem(null)}
          >
            All
          </div>
        )}
        {list.map((o, i) => (
          <div
            key={`list-item-${i}`}
            className={classNames("dropdown-v2__list-item", {
              "dropdown-v2__list-item--selected":
                dropdownValue === o.value || checkItemSelected(o.value)
            })}
            onClick={() => handleSelectItem(o.value)}
          >
            {o.label}
          </div>
        ))}
      </div>
    </div>
  );
};

export default DropdownV2;
