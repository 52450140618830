import { wevat } from "../http-client";
import { withMock } from "../../utils/prod-test";

export const createRetailerRequest = function (retailer) {
  return withMock(
    function () {
      return wevat().post("/retailers", retailer);
    },
    () => Promise.resolve()
  )();
};

export const updateRetailerRequest = function (retailerId, retailer) {
  return wevat().patch("/retailers/" + retailerId, retailer);
};


export const importRetailersRequest = function (retailer) {
  return withMock(
    function () {
      return wevat().post("/retailers/societe/import", retailer);
    },
    () => Promise.resolve()
  )();
};
