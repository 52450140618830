import { List } from "immutable";
import { UserDetailsAction } from "../actions/user-details-actions";
import { FormLogging } from "./form-logging-reducer";
import { listify, merge } from "../../utils/functions";
import { ReceiptViewActions } from "../actions/receipt-view-actions";

const initialState = {
  trips: List(),
  personalInfo: undefined,
  uniqueInfo: undefined,
  isLoading: false,
  needsReload: false
};

export const userProfileState = (state = initialState, action) => {
  switch (action.type) {
    case UserDetailsAction.RESET_STATE:
      return initialState;

    case UserDetailsAction.START_LOADING:
      return merge(state, { isLoading: true });

    case UserDetailsAction.USER_DETAILS_LOADED:
      return onUserDetailsLoaded(state, action.body);

    case FormLogging.FORM_LOGGING_SUCCESS:
      return reload(state);

    case UserDetailsAction.RELOAD:
    case UserDetailsAction.RECEIPT_DELETE_SUCCESS:
    case UserDetailsAction.RECEIPT_UPDATE_SUCCESS:
    case "UPDATE_TRIP_DETAILS_SUCCESS":
    case UserDetailsAction.UPDATE_USER_DETAILS_SUCCESS:
    case ReceiptViewActions.CLOSE_RECEIPT_VIEW:
      return reload(state);

    default:
      return state;
  }
};

const onUserDetailsLoaded = (state, user) => {
  return merge(state, {
    isLoading: false,
    needsReload: false,
    trips: listify(user.trips),
    personalInfo: user.personalInfo,
    uniqueInfo: user.uniqueInfo,
    // TODO: check if this can be removed
    paymentInfo: user.paymentInfo
  });
};

const reload = (state) => merge(state, { needsReload: true, isLoading: true });
