export const UserAction = {
  USERS_LOADED: "USERS_LOADED",
  OPEN_USER_PROFILE: "OPEN_USER_PROFILE",
  HIDE_USER_PROFILE: "HIDE_USER_PROFILE",
  START_LOADING: "USER_START_LOADING"
};

export const FilterActions = {
  SIGNUP_METHOD_CHANGED: "SIGNUP_METHOD_CHANGED",
  TRIP_STATUS_CHANGED: "TRIP_STATUS_CHANGED",
  TOGGLE_NEW_ONLY: "TOGGLE_NEW_ONLY",
  APPLY_FILTER: "APPLY_FILTER",
  MINIMUM_COUNT_CHANGED: "MINIMUM_COUNT_CHANGED",
  MINIMUM_COUNT_TOGGLED: "MINIMUM_COUNT_TOGGLED",
  SEARCH_INPUT_CHANGED: "SEARCH_INPUT_CHANGED",
  SUBMIT_SEARCH: "SUBMIT_SEARCH",
  NEXT_PAGE: "NEXT_PAGE",
  PREVIOUS_PAGE: "PREVIOUS_PAGE",
  START_LOADING: "FILTER_START_LOADING",
  RESET_FILTER_OPTIONS: "RESET_FILTER_OPTIONS"
};

export const onSignupMethodChanged = (dispatch) => (label, method) =>
  dispatch({ type: FilterActions.SIGNUP_METHOD_CHANGED, method });

export const onTripStatusChanged = (dispatch) => (label, tripStatus) =>
  dispatch({ type: FilterActions.TRIP_STATUS_CHANGED, tripStatus });

export const onNewOnlyToggled = (dispatch) => () =>
  dispatch({ type: FilterActions.TOGGLE_NEW_ONLY });

export const onApplyButtonClicked = (dispatch) => () =>
  dispatch({ type: FilterActions.APPLY_FILTER });

export const onMinimumReceiptCountChanged = (dispatch) => (minimumCount) =>
  dispatch({ type: FilterActions.MINIMUM_COUNT_CHANGED, minimumCount });

export const onMinimumReceiptCountToggled = (dispatch) => () =>
  dispatch({ type: FilterActions.MINIMUM_COUNT_TOGGLED });

export const onSearchInputChanged = (dispatch) => (searchText) =>
  dispatch({ type: FilterActions.SEARCH_INPUT_CHANGED, searchText });

export const onSearchSubmit = (dispatch) => () =>
  dispatch({ type: FilterActions.SUBMIT_SEARCH });

export const onResetFilterOptions = (dispatch) => () =>
  dispatch({ type: FilterActions.RESET_FILTER_OPTIONS });

export const onNextPage = (dispatch) => () =>
  dispatch({ type: FilterActions.NEXT_PAGE });

export const onPreviousPage = (dispatch) => () =>
  dispatch({ type: FilterActions.PREVIOUS_PAGE });

export const openUserProfile = (dispatch) => (userId) => {
  dispatch({ type: UserAction.OPEN_USER_PROFILE, userId });
  // save to state
};

export const hideUserProfile = (dispatch) => () =>
  dispatch({ type: UserAction.HIDE_USER_PROFILE });
