import { RootState } from "../../../store/store";

export const selectReceipts = ({ receiptsTable }: RootState) =>
  receiptsTable.receipts;

export const selectIsReceiptsLoading = ({ receiptsTable }: RootState) =>
  receiptsTable.isLoading;

export const selectReceiptsPagination = ({ receiptsTable }: RootState) =>
  receiptsTable.pagination;

export const selectReceiptsPooling = ({ receiptsTable }: RootState) =>
  receiptsTable.isPooling;

export const selectQueries = ({ receiptsTable }: RootState) => ({
  page: receiptsTable.page,
  sortBy: receiptsTable.sortBy,
  sortDirection: receiptsTable.sortDirection,
  status: receiptsTable.status,
  retailerId: receiptsTable.retailerId,
  rejectionReason: receiptsTable.rejectionReason,
  lastVerifiedBy: receiptsTable.lastVerifiedBy
});
