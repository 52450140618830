import React from "react";
import { AnimatePresence, motion } from "framer-motion";

const variants = {
  visible: (custom) => ({
    opacity: 1,
    x: 0,
    scale: 1,
    transition: { delay: custom * 0.025 }
  })
};

interface AnimatedDivListProps {
  index?: number;
  className?: string;
  onClick?: () => void;
  children: React.ReactNode;
  style?: any;
}

const AnimatedDivList = ({
  index = 0,
  children,
  ...props
}: AnimatedDivListProps) => (
  <AnimatePresence>
    <motion.div
      custom={index}
      initial={{ opacity: 0, x: -15, scale: 0.95 }}
      animate="visible"
      variants={variants}
      {...props}
    >
      {children}
    </motion.div>
  </AnimatePresence>
);

export default AnimatedDivList;
