import { connect } from "react-redux";
import { updateTripDetailsAction } from "../../../store/service/trip-details-bindings";
import EditTripDatesView from "./EditTripDatesView";

const mapStateToProps = (state, ownProps) => {
  const tripId = state.selectedTripState.selectedTripId;

  return {
    tripId,
    trip: state.userProfileState.trips?.find(trip => trip.tripId === tripId),
    arrivalDate: ownProps.arrivalDate,
    departureDate: ownProps.departureDate
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateTripDetails: updateTripDetailsAction(dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditTripDatesView);
