import { List } from "immutable";

import { merge } from "../../utils/functions";
import { RetailerDropdownActions } from "./retailer-dropdown-reducer";
import { createRetailerRequest, importRetailersRequest } from "../../api/add-retailer/add-retailer-service";
import { getRetailers } from "../actions";

import { setNetworkError } from "../global";

export const AddRetailerActions = {
  SET_RETAILERS_LIST: "SET_RETAILERS_LIST",
  OPEN_ADD_RETAILER: "OPEN_ADD_RETAILER",
  CLOSE_ADD_RETAILER: "CLOSE_ADD_RETAILER",
  NAME_CHANGED: "NAME_CHANGED",
  COUNTRY_CHANGED: "COUNTRY_CHANGED",
  VATNUMBER_CHANGED: "VATNUMBER_CHANGED",
  IMPORT_VATNUMBER_CHANGED: "IMPORT_VATNUMBER_CHANGED",
  SIREN_CHANGED: "SIREN_CHANGED",
  SIRET_CHANGED: "SIRET_CHANGED",
  ADDRESS_LINE_CHANGED: "ADDRESS_LINE_CHANGED",
  ADDRESS_POSTAL_CODE_CHANGED: "ADDRESS_POSTAL_CODE_CHANGED",
  ADDRESS_CITY_CHANGED: "ADDRESS_CITY_CHANGED",
  CREATE_RETAILER_STARTED: "CREATE_RETAILER_STARTED",
  CREATE_RETAILER_SUCCESS: "CREATE_RETAILER_SUCCESS",
  RETAILER_CREATED: "RETAILER_CREATED"
};

export const startAddingRetailer = (dispatch) => () =>
  dispatch({ type: AddRetailerActions.OPEN_ADD_RETAILER });
export const stopAddingRetailer = (dispatch) => () =>
  dispatch({ type: AddRetailerActions.CLOSE_ADD_RETAILER });
export const onNameChanged = (dispatch) => (retailerName) =>
  dispatch({ type: AddRetailerActions.NAME_CHANGED, retailerName });
export const onCountryChanged = (dispatch) => (country) =>
  dispatch({ type: AddRetailerActions.COUNTRY_CHANGED, country });
export const onVatnumberChanged = (dispatch) => (vatnumber) =>
  dispatch({ type: AddRetailerActions.VATNUMBER_CHANGED, vatnumber });
export const onSiretChanged = (dispatch) => (siret) =>
  dispatch({ type: AddRetailerActions.SIRET_CHANGED, siret });
export const onSirenChanged = (dispatch) => (siren) =>
  dispatch({ type: AddRetailerActions.SIREN_CHANGED, siren });
export const onAddressLineChanged = (dispatch) => (addressLine) =>
  dispatch({ type: AddRetailerActions.ADDRESS_LINE_CHANGED, addressLine });
export const onAddressPostalCodeChanged = (dispatch) => (addressPostalCode) =>
  dispatch({ type: AddRetailerActions.ADDRESS_POSTAL_CODE_CHANGED, addressPostalCode });
export const onAddressCityChanged = (dispatch) => (addressCity) =>
  dispatch({ type: AddRetailerActions.ADDRESS_CITY_CHANGED, addressCity });

export const createRetailer = function (dispatch) {
  return function ({
    retailerName,
    country,
    addressLine,
    addressPostalCode,
    addressCity,
    vatnumber,
    siret,
    siren
  }) {
    dispatch({ type: AddRetailerActions.CREATE_RETAILER_STARTED });

    const newRetailer = {
      retailerName,
      country,
      addressLine,
      addressPostalCode,
      addressCity,
      vatnumber,
      siret,
      siren
    };

    return createRetailerRequest(newRetailer)
      .then((createdRetailer) => {
        dispatch({
          type: AddRetailerActions.CREATE_RETAILER_SUCCESS,
          retailer: createdRetailer
        });
        dispatch({ type: AddRetailerActions.CLOSE_ADD_RETAILER });
        dispatch({
          type: getRetailers.type,
          payload: { retailerId: createdRetailer.retailerId }
        });
      })
      .catch((error) => dispatch(setNetworkError(error)));
  };
};

export const importRetailers = function (dispatch) {
  return function ({
    vatnumber
  }) {
    dispatch({ type: AddRetailerActions.CREATE_RETAILER_STARTED });

    const toImport = {
      vatnumber
    };

    return importRetailersRequest(toImport)
      .then((createdRetailerList) => {
        dispatch({
          type: AddRetailerActions.CREATE_RETAILER_SUCCESS,
          retailer: createdRetailerList[0]
        });
        dispatch({ type: AddRetailerActions.CLOSE_ADD_RETAILER });
        dispatch({
          type: getRetailers.type,
          payload: { retailerId: createdRetailerList[0].retailerId }
        });
      })
      .catch((error) => dispatch(setNetworkError(error)));
  };
};

const initialState = {
  retailers: List(),
  show: false,
  retailerName: "",
  country: "FR",
  vatnumber: "",
  siret: null,
  siren: null,
  addressLine: null,
  addressPostalCode: null,
  addressCity: null,
  isLoading: false
};

export const addRetailerModal = (state = initialState, action) => {
  switch (action.type) {
    case AddRetailerActions.OPEN_ADD_RETAILER:
      return merge(state, { show: true });

    case AddRetailerActions.CLOSE_ADD_RETAILER:
      return initialState;

    case AddRetailerActions.NAME_CHANGED:
      return merge(state, { retailerName: action.retailerName });

    case AddRetailerActions.COUNTRY_CHANGED:
      return merge(state, { country: action.country });

    case AddRetailerActions.VATNUMBER_CHANGED:
      return merge(state, { vatnumber: action.vatnumber });

    case AddRetailerActions.SIREN_CHANGED:
      return merge(state, { siren: action.siren });

    case AddRetailerActions.SIRET_CHANGED:
      return merge(state, { siret: action.siret });

    case AddRetailerActions.ADDRESS_LINE_CHANGED:
      return merge(state, { addressLine: action.addressLine });

    case AddRetailerActions.ADDRESS_POSTAL_CODE_CHANGED:
      return merge(state, { addressPostalCode: action.addressPostalCode });

    case AddRetailerActions.ADDRESS_CITY_CHANGED:
      return merge(state, { addressCity: action.addressCity });

    case RetailerDropdownActions.TEXT_CHANGED:
      return merge(state, { retailerName: action.retailerName });

    case AddRetailerActions.SET_RETAILERS_LIST:
      return merge(state, { retailers: action.retailers });

    case AddRetailerActions.CREATE_RETAILER_STARTED:
      merge(state, { disableAddRetailerButtons: true })

    default:
      return state;
  }
};
