import { RetailersTableAction } from "@store/reducers/retailers-table-reducer";
import { AddRetailerActions } from "@store/reducers/add-retailer-reducer";

import { getRetailers} from "@api/retailers";

export const fetchRetailers = function (dispatch) {
  return function (request) {
    dispatch({ type: RetailersTableAction.RETAILERS_TABLE_START_LOADING });
    return getRetailers(request)
      .then((retailersList) => {
        dispatch({ 
          type: RetailersTableAction.RETAILERS_TABLE_LOADED, 
          retailers: retailersList.retailers,
          maxPages: retailersList.maxPages
        });
        dispatch({
          type: AddRetailerActions.SET_RETAILERS_LIST,
          retailers: retailersList.retailers
        });
      })
      .catch((error) => {
        console.log("An error ocurred" + error)
        dispatch({
          type: RetailersTableAction.RETAILERS_TABLE_LOAD_ERROR,
          error
        })
      }
      );
  };
};
