import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const Toast = (props) =>
    MySwal.fire({
    position: "bottom-start",
    showConfirmButton: false,
    toast: true,
    timer: 1500,
    timerProgressBar: true,
    onOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
    ...props,
    icon: props.type || props.icon || "info",
  });

export const ErrorToast = (props) => Toast({ ...props, icon: "error" });

export const LoadingToast = (props) =>
  Toast({
    ...props,
    position: "bottom-end",
    icon: null,
  });

export default Toast;
