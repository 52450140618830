import { getTokenInfo } from "./tokens";

export enum ROLES {
  ADMIN = "ADMIN",
  READ_ONLY_ADMIN = "READ_ONLY_ADMIN",
  RECEIPT_REVIEWER_ADMIN = "RECEIPT_REVIEWER_ADMIN"
}

export const isMaxAdmin = () => {
  return getTokenInfo().ROLE === ROLES.ADMIN;
};

export const isAdmin = () => {
  return isMaxAdmin() || getTokenInfo().ROLE === ROLES.READ_ONLY_ADMIN;
};

export const getRole: () => ROLES = () => {
  return getTokenInfo().ROLE;
};
