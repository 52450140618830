import { List } from "immutable";
import { compose, merge } from "@utils/functions";

export const RetailersTableAction = {
  RETAILERS_TABLE_LOADED: "RETAILERS_TABLE_LOADED",
  OPEN_RETAILER_PROFILE: "OPEN_RETAILER_PROFILE",
  HIDE_RETAILER_PROFILE: "HIDE_RETAILER_PROFILE",
  RETAILERS_TABLE_START_LOADING: "RETAILERS_TABLE_START_LOADING",
  RETAILERS_TABLE_LOAD_ERROR: "RETAILERS_TABLE_LOAD_ERROR",
  RETAILERS_TABLE_RELOAD: "RETAILERS_TABLE_RELOAD",
};

export const FilterActions = {
  SEARCH_INPUT_CHANGED: "SEARCH_INPUT_CHANGED",
  SUBMIT_SEARCH: "SUBMIT_SEARCH",
  NEXT_PAGE: "NEXT_PAGE",
  SORT_CHANGED: "SORT_CHANGED",
  PREVIOUS_PAGE: "PREVIOUS_PAGE",
  START_LOADING: "FILTER_START_LOADING"
};

const defaultSort = {
  direction: "DESC", 
  by: "CREATED_AT"
}

const initialState = {
  retailers: List(),
  searchText: "",
  needsLoad: true,
  page: 1,
  maxPages: 1,
  showRetailerProfileModal: false,
  selectedRetailerProfile: undefined,
  isLoading: false,
  sort: defaultSort
};

export const onSearchInputChanged = dispatch => searchText =>
  dispatch({ type: FilterActions.SEARCH_INPUT_CHANGED, searchText });

export const onSortChange = dispatch => (sort) =>
  dispatch({ type: FilterActions.SORT_CHANGED, sort });

export const onSearchSubmit = dispatch => () =>
  dispatch({ type: FilterActions.SUBMIT_SEARCH });

export const onNextPage = dispatch => () =>
  dispatch({ type: FilterActions.NEXT_PAGE });

export const onPreviousPage = dispatch => () =>
  dispatch({ type: FilterActions.PREVIOUS_PAGE });

export const openRetailerProfile = dispatch => retailerId =>
  dispatch({ type: RetailersTableAction.OPEN_RETAILER_PROFILE, retailerId });

export const hideRetailerProfile = dispatch => () =>
  dispatch({ type: RetailersTableAction.HIDE_RETAILER_PROFILE });


export const retailersTable = (state = initialState, action) => {
  switch (action.type) {
    case RetailersTableAction.RETAILERS_TABLE_START_LOADING:
      return merge(state, { isLoading: true, needsLoad: false });

    case RetailersTableAction.RETAILERS_TABLE_LOADED:
      return updateRetailers(state, action);

    case RetailersTableAction.RETAILERS_TABLE_RELOAD:
        return reload(state);

    case RetailersTableAction.RETAILERS_TABLE_LOAD_ERROR:
      return merge(state, { isLoading: false });

    case FilterActions.SEARCH_INPUT_CHANGED:
      return changeSearchInput(state, action.searchText);

    case FilterActions.SORT_CHANGED:
      return changeSort(state, action.sort);

    case FilterActions.SUBMIT_SEARCH:
      return reload(state);

    case FilterActions.NEXT_PAGE:
      return compose(chanteToNextPage, reload)(state);

    case FilterActions.PREVIOUS_PAGE:
      return compose(changeToPreviousPage, reload)(state);

    default:
      return state;
  }
};

function updateRetailers(state, action) {
  const retailers = action.retailers

  return merge(state, {
    retailers: retailers,
    maxPages: action?.maxPages || 1,
    needsLoad: false,
    isLoading: false,
  });
}

function changeSearchInput(state, searchText) {
  const needsLoad = searchText === "";
  if (!needsLoad) {
    return merge(state, {
      searchText,
      needsLoad
    });
  }
  return initialState;
}

function changeSort(state, sort) {
  return merge(reload(state), {
    sort
  });
}

function reload(state) {
  return merge(state, { needsLoad: true });
}

function chanteToNextPage(state) {
  if (state.page === state.maxPages) {
    return state;
  }

  return merge(state, { page: state.page + 1 });
}

function changeToPreviousPage(state) {
  if (state.page === 1) {
    return state;
  }

  return merge(state, { page: state.page - 1 });
}
