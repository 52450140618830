import React, { FC } from "react";
import { AnimatePresence, motion } from "framer-motion";

import closeIcon from "@assets/images/close.svg";
import closeIconWhite from "@assets/images/close-white.svg";
import TransparentButton from "../TransparentButton";

import "../css/modals.css";

type Props = {
  type: "large" | "medium-large" | "medium" | "small" | "error" | "fullscreen";
  show: boolean;
  showCloseButton?: boolean;
  onHide?: () => void;
  height?: string;
  width?: string;
  closeWhite?: boolean;
  className?: string;
};

const WevatModal: FC<Props> = ({
  children,
  onHide,
  height,
  width,
  type = "large",
  show = false,
  showCloseButton = true,
  closeWhite = false,
  className
}) => {
  const backdropRef = React.useRef<HTMLDivElement | null>(null);
  const modalRef = React.useRef<HTMLDivElement | null>(null);

  const renderCloseBtn = () => (
    <div className="close-container">
      <TransparentButton
        className="transparent-button modal-close"
        onClick={onHide}
      >
        <img src={closeWhite ? closeIconWhite : closeIcon} alt="Close" />
      </TransparentButton>
    </div>
  );

  React.useEffect(() => {
    if (modalRef.current) {
      if (height) modalRef.current.style.height = `${height}px`;
      if (width) modalRef.current.style.width = `${width}px`;
    }
  }, [show, modalRef.current]);

  return (
    <AnimatePresence>
      {show && (
        <motion.div
          ref={backdropRef}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.1 }}
          className={`modal-backdrop ${className || ""}`}
          onClick={(event) => event?.target === backdropRef.current && onHide()}
        >
          <motion.div
            ref={modalRef}
            initial={{ opacity: 0, y: -100 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -100 }}
            transition={{ duration: 0.2 }}
            className={`modal ${type}`}
          >
            {showCloseButton && renderCloseBtn()}
            {children}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default WevatModal;
