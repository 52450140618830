import { merge } from "../../utils/functions";
import { UserDetailsAction } from "../actions/user-details-actions";

const initialState = {
  selectedIndex: 0
};

export const userProfileTabView = (state = initialState, action) => {
  switch (action.type) {
    case UserProfileActions.SELECT_TAB:
      return merge(state, { selectedIndex: action.selectedIndex });

    default:
      return state;
  }
};

export const UserProfileActions = {
  SELECT_TAB: "SELECT_TAB"
};

export const onTabRefreshed = (dispatch) => () =>
  dispatch({ type: UserDetailsAction.RELOAD });

export const onTabSelected = (dispatch) => (selectedIndex) =>
  dispatch({ type: UserProfileActions.SELECT_TAB, selectedIndex });
