import React from "react";

import Button from "../../components/Button";
import WevatModal from "../../components/modals/WevatModal";

const SkipReceipt = ({ skipReceipt, onDismiss }) => (
  <WevatModal type="small" show={true} showCloseButton={false}>
    <h2>This receipt is already open by someone else!</h2>
    <Button type="primary" size="x-big" onClick={skipReceipt}>
      Skip
    </Button>
    <br />
    <br />
    <Button type="blue" size="small" onClick={onDismiss}>
      I know what I'm doing
    </Button>
  </WevatModal>
);

export default SkipReceipt;
