import React from "react";
import PropTypes from "prop-types";
import { Currency, formatCurrencyMoney } from "../utils/currency";

const Money = (props) => (
  <span {...props}>{`${formatCurrencyMoney(
    props.amount,
    props.currency
  )}`}</span>
);

Money.propTypes = {
  currency: PropTypes.oneOf(Object.values(Currency)),
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

Money.defaultProps = {
  currency: Currency.EUR,
  // currency: Currency.GBP,
  amount: "0"
};

export default Money;
