import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import "./Popup.css";

const mixin = Swal.mixin({
  confirmButtonColor: "#23b28d",
  cancelButtonColor: "#bc0000",
  customClass: {
    title: "popup-custom-header"
  }
});

export default withReactContent(mixin);
