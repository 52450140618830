// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../assets/images/arrow-down.png";
import ___CSS_LOADER_URL_IMPORT_1___ from "../../assets/images/arrow-down-disabled.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".arrow {\n  height: 10px;\n  width: 10px;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;\n  background-size: contain;\n  outline: none;\n  border: none;\n  cursor: pointer;\n}\n\n.arrow:disabled {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat;\n  background-size: contain;\n}\n\n.arrow.up {\n  transform: rotate(180deg);\n  margin-bottom: 0.5em;\n}\n\n.arrow.down {\n  margin-top: 0.5em;\n}\n\n.arrow.right {\n  transform: rotate(270deg);\n  margin-left: 0.5em;\n}\n\n.arrow.left {\n  transform: rotate(90deg);\n  margin-right: 0.5em;\n}\n", "",{"version":3,"sources":["webpack://src/components/css/arrow-button.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;EACX,6DAA+D;EAC/D,wBAAwB;EACxB,aAAa;EACb,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,6DAAwE;EACxE,wBAAwB;AAC1B;;AAEA;EACE,yBAAyB;EACzB,oBAAoB;AACtB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,wBAAwB;EACxB,mBAAmB;AACrB","sourcesContent":[".arrow {\n  height: 10px;\n  width: 10px;\n  background: url(\"../../assets/images/arrow-down.png\") no-repeat;\n  background-size: contain;\n  outline: none;\n  border: none;\n  cursor: pointer;\n}\n\n.arrow:disabled {\n  background: url(\"../../assets/images/arrow-down-disabled.png\") no-repeat;\n  background-size: contain;\n}\n\n.arrow.up {\n  transform: rotate(180deg);\n  margin-bottom: 0.5em;\n}\n\n.arrow.down {\n  margin-top: 0.5em;\n}\n\n.arrow.right {\n  transform: rotate(270deg);\n  margin-left: 0.5em;\n}\n\n.arrow.left {\n  transform: rotate(90deg);\n  margin-right: 0.5em;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
