import React, { FC } from "react";

import "./tooltip.scss";

type Props = {
  tooltipText: string;
  className?: string;
};

const Tooltip: FC<Props> = ({ children, tooltipText, className }) => (
  <div className={`tooltip ${className || ""}`}>
    {children}
    <span className="tooltip-text">{tooltipText}</span>
  </div>
);

export default Tooltip;
