import React, { Suspense, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";

import { hasToken } from "@api/tokens";
import { resetErrors } from "@store/global";
import { isStub } from "@src/config";
import { isAdmin } from "@api/roles";
import { isQABeta } from "@utils/functions";
import { useAppSelector, useAppDispatch } from "@src/hooks";
import { selectError, selectErrorMessage } from "@store/selectors";
import Login from "@views/login/Login";
import { Notification } from "@components/Notification";
import ErrorModal from "@components/modals/ErrorModal";
import MainLayout from "@components/MainLayout";
import Loader from "@components/Loader";
import ModalWrapper from "@components/modals/ModalWrapper";
import { ErrorToast } from "@components/Toast";
import { openUserProfile } from "@store/actions/user-page-actions";
import Retailers from "@views/retailers/retailers-table/RetailersPage";

import "@assets/css/base.css";

const Player = React.lazy(() => import("@views/rrweb-player/Player"));
const StatsPage = React.lazy(() => import("@views/stats/StatsPage"));
const FormsPage = React.lazy(() => import("@views/forms/FormsPage"));
const Payments = React.lazy(() => import("@views/payments"));
const Trips = React.lazy(() => import("@views/trips"));
const UserProfile = React.lazy(() => import("@views/trips/user-profile"));
const Receipts = React.lazy(() => import("@views/receipts"));
const Receipt = React.lazy(() => import("@views/receipt"));

const App = () => {
  const isLoggedIn = hasToken() || isStub;
  const dispatch = useAppDispatch();
  const error = useAppSelector(selectError);
  const errorMessage = useAppSelector(selectErrorMessage);
  const openUserProfileDispatch = openUserProfile(dispatch);

  const getBasePath = () => {
    if (isQABeta()) return "/beta-qa";
    return undefined;
  };

  useEffect(() => {
    const [category, categoryId] = document.location.pathname
      .split("/")
      .filter((i) => i);

    switch (category) {
      case "user":
      case "users":
      case "trips":
        if (!isAdmin()) {
          ErrorToast({ title: "Sorry, you don't have access to this" });
          break;
        }

        if (categoryId) openUserProfileDispatch(categoryId);
        break;

      default:
    }
  }, [openUserProfileDispatch]);

  if (!isLoggedIn) {
    return <Login />;
  }

  return (
    <Router basename={getBasePath()}>
      <div className="App">
        <Suspense fallback={<Loader />}>
          <Switch>
            <Redirect exact from="/" to="/invoices" />
            {isAdmin() && <Route exact path="/player" component={Player} />}
            {isAdmin() && <Route exact path="/forms" component={FormsPage} />}
            {isAdmin() && <Route exact path="/stats" component={StatsPage} />}

            <MainLayout>
              <Route exact path="/invoices" component={Receipts} />
              <Route exact path="/invoices/:receiptId" component={Receipt} />

              {isAdmin() && <Route exact path="/trips" component={Trips} />}
              {isAdmin() && (
                <Route exact path="/trips/:userId" component={UserProfile} />
              )}
              {isAdmin() && (
                <Route exact path="/users/:userId?" component={UserProfile} />
              )}

              {isAdmin() && (
                <Route exact path="/payments" component={Payments} />
              )}
              {isAdmin() && (
                <Route exact path="/retailers" component={Retailers} />
              )}
            </MainLayout>
          </Switch>

          <ModalWrapper />
          <ErrorModal
            show={error}
            message={errorMessage}
            onHide={() => dispatch(resetErrors())}
          />
          <Notification />
        </Suspense>
      </div>
    </Router>
  );
};

export default App;
