import React, { FC } from "react";
import classNames from "classnames";

import "./ButtonIcon.scss";

type Props = {
  label: string;
  disabled?: boolean;
  iconName: "user-profile" | "help" | "bin" | "reject" | "refresh";
  onClick: () => void;
};

const ButtonIcon: FC<Props> = ({ label, disabled, onClick, iconName }) => {
  return (
    <button
      className={classNames("button-icon", {
        [`${iconName}-btn`]: !!iconName,
        "btn--disabled": disabled
      })}
      disabled={disabled}
      onClick={() => onClick()}
    >
      {label}
    </button>
  );
};

export default ButtonIcon;
