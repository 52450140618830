import EditTripDatesContainer from "../../views/trips/edit-trip-dates/EditTripDatesContainer";
import { ModalActions } from "../actions/modal-actions";

export const hideModal = function (dispatch) {
  return function () {
    dispatch({ type: ModalActions.CLOSE_MODAL });
  };
};

export const onEditTrip = (dispatch) => (trip) => {
  dispatch({
    type: ModalActions.OPEN_MODAL,
    component: EditTripDatesContainer,
    componentProps: {
      tripId: trip.tripId,
      arrivalDate: trip.arrivalDate,
      departureDate: trip.departureDate
    },
    modalProps: {
      type: "medium"
    }
  });
};
