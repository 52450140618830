import React from "react";
import PropTypes from "prop-types";

import "./css/badges.css";

const Badge = props => (
  <span className={`badge ${props.type} ${props.size}`}>
    {props.text || props.children}
  </span>
);

Badge.propTypes = {
  text: PropTypes.string,
  type: PropTypes.oneOf(["blue", "red", "gray", "green", "orange", "yellow"]),
  size: PropTypes.oneOf(["small", "normal", "big", "fixed"]),
  children: PropTypes.node
};

Badge.defaultProps = {
  type: "blue",
  size: "normal",
  text: ""
};

export default Badge;
