import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ReceiptsState, Queries } from "./types";

const initialState: ReceiptsState = {
  receipts: [],
  pagination: {
    current: 0,
    itemsLimit: 0,
    itemsTotal: 0,
    next: 0,
    total: 0
  },
  page: 0,
  isLoading: true,
  sortBy: null,
  sortDirection: null,
  isPooling: false,
  status: "PENDING",
  rejectionReason: null,
  lastVerifiedBy: null,
  retailerId: null
};

const receiptVerification = createSlice({
  name: "receiptsTable",
  initialState,
  reducers: {
    getReceipts: (state, action) => ({
      ...state,
      isLoading: true,
      isPooling: action.payload?.isPooling
    }),
    setQueries: (state, action: PayloadAction<Partial<Queries>>) => ({
      ...state,
      isLoading: true,
      ...action.payload
    }),
    resetQueries: (state) => ({
      ...state,
      sortBy: null,
      status: "PENDING",
      retailerId: null,
      sortDirection: null,
      lastVerifiedBy: null,
      rejectionReason: null
    }),
    setReceipts: (state, action) => ({
      ...state,
      isLoading: false,
      receipts: [...action.payload.receipts],
      pagination: { ...action.payload.pagination }
    }),
    updateReceipts: (state, action) => ({
      ...state,
      receipts: [...action.payload.receipts]
    }),
    startPooling: (state) => ({
      ...state,
      isPooling: true
    }),
    stopPooling: (state) => ({
      ...state,
      isPooling: false
    })
  }
});

export default receiptVerification.reducer;

export const {
  getReceipts,
  setQueries,
  setReceipts,
  resetQueries,
  startPooling,
  stopPooling,
  updateReceipts
} = receiptVerification.actions;
