import { List } from "immutable";
import { wevat } from "./http-client";
import { withMock } from "./../utils/prod-test";

export interface RetailerResponse {
  retailerName: string;
  country: string;
  retailerId: string;
  addressLine: string;
  addressPostalColde: string;
  addressCity: string;
  vatNumber: string;
  siren: string;
  siret: string;
  deprecated: boolean;
  itemsWithVat: boolean;
  createdAt: string;
  modifiedAt: string;
  defaultCategory: string;
  ocrItemExtraction: boolean;
  isBrandRepresentative: boolean;
}

export interface RetailerListResponse {
  maxPages: Number;
  retailers: List<RetailerResponse>;
}

export interface RetailerQueries {
  query?: string;
  retrieveAll?: boolean;
  pagination?: RetailerQueriePagination;
  sort?: RetailerQuerieSort
}

export interface RetailerQueriePagination {
  itemsLimit: number;
  current: number;
}

export interface RetailerQuerieSort {
  direction: "DESC" | "ASC";
  by: "CREATED_AT" | "MODFIED_AT";
}

const defaultSort : RetailerQuerieSort = {
  direction: "DESC", 
  by: "CREATED_AT"
}

const defaultPagination : RetailerQueriePagination = {
  itemsLimit: 50,
  current: 0
}

const emptyQuery : RetailerQueries = {
  query: "",
  retrieveAll: true,
  sort: defaultSort,
  pagination: defaultPagination,
}

export const getRetailers = (query: RetailerQueries = emptyQuery): Promise<RetailerListResponse> => {

  const params = new URLSearchParams({
    query: query?.query == null ? "" : query.query,
    retrieveAll: `${query?.retrieveAll}`,
    "pagination.itemsLimit": `${query?.pagination?.itemsLimit}`,
    "pagination.current": `${query?.pagination?.current}`,
    "sort.direction": query?.sort?.direction.toUpperCase(),
    "sort.by": query?.sort?.by.toUpperCase()
  });

  if (!query.query) {
    params.delete("query");
  }

  const queryParams = `?${params.toString()}`

  return withMock(() => {
    return wevat()
      .get(`/retailers${queryParams}`)
  }, fetchMockRetailers)();
};

const fetchMockRetailers = () => {
  return Promise.resolve(
    List([
      {
        storeId: "124124124",
        retailerName: "Harrods",
        postcode: "BT4 1FY"
      },
      {
        storeId: "22124124",
        retailerName: "Harrods",
        postcode: "BT4 2FY"
      },
      {
        storeId: "1111124124",
        retailerName: "Selfridges",
        postcode: "W14 9HS"
      }
    ])
  );
};
