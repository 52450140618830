import { hasToken, redirectToLogin, withAuthHeaderIfSet } from "./tokens";
import { API_SUFFIX, API_SUFFIX_NO_ADMIN, WEVAT_GATEWAY } from "../config";
import {
  hasJsonContentType,
  headersFrom,
  isErrorResponse,
  jsonHeaders,
  mergeHeaders
} from "./http";
import Toast from "../components/Toast";

let alert: any = {
  toast: {}
};

type Params =
  | {
      authRequired?: boolean;
      oldApi?: boolean;
      adminApi?: boolean;
      discardError?: (response: Response) => boolean;
    }
  | undefined;

export const wevat = ({
  authRequired = true,
  oldApi = false,
  adminApi = true,
  discardError = (response: Response) => false
}: Params = {}) => {
  const url = resolveBaseUrl(oldApi, adminApi);

  if (authRequired && !hasToken()) {
    redirectToLogin();
  }

  return {
    get: (context, headers = {}) =>
      get(
        url + context,
        withAuthHeaderIfSet(
          mergeHeaders(defaultHeaders(), headersFrom(headers))
        ),
        discardError
      ),
    post: (context, body) =>
      post(
        url + context,
        withAuthHeaderIfSet(defaultHeaders()),
        body,
        discardError
      ),
    patch: (context, body) =>
      patch(
        url + context,
        withAuthHeaderIfSet(defaultHeaders()),
        body,
        discardError
      ),
    put: (context, body?: any) =>
      put(
        url + context,
        withAuthHeaderIfSet(defaultHeaders()),
        body,
        discardError
      ),
    delete: (context) =>
      deleteBy(
        url + context,
        withAuthHeaderIfSet(defaultHeaders()),
        discardError
      )
  };
};

const resolveBaseUrl = (oldApi = false, adminApi = true) => {
  if (oldApi) {
    return WEVAT_GATEWAY;
  }

  if (adminApi) {
    return WEVAT_GATEWAY + API_SUFFIX;
  }

  return WEVAT_GATEWAY + API_SUFFIX_NO_ADMIN;
};

const post = (url, headers = defaultHeaders(), body = {}, discardError) => {
  const options = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(body)
  };

  return fetchAndHandleError(url, options, discardError);
};

const put = (url, headers = defaultHeaders(), body = {}, discardError) => {
  const options = {
    method: "PUT",
    headers: headers,
    body: JSON.stringify(body)
  };

  return fetchAndHandleError(url, options, discardError);
};

const patch = (url, headers = defaultHeaders(), body = {}, discardError) => {
  const options = {
    method: "PATCH",
    headers: headers,
    body: JSON.stringify(body)
  };

  return fetchAndHandleError(url, options, discardError);
};

const get = (url, headers = defaultHeaders(), discardError) => {
  return fetchAndHandleError(url, { headers }, discardError);
};

const deleteBy = (url, headers = defaultHeaders(), discardError) => {
  return fetchAndHandleError(url, { method: "DELETE", headers }, discardError);
};

const defaultHeaders = () => {
  return jsonHeaders();
};

const fetchAndHandleError = (url, config, discardError) => {
  return fetch(url, config).then(responseOrError(discardError));
};

const responseOrError = (discardError) => (response) => {
  alert.toast?.stopTimer?.();
  alert.toast?.close?.();

  if (isErrorResponse(response)) {
    if (!discardError(response) && hasJsonContentType(response))
      response.json().then((json) => {
        alert.toast = Toast({
          title: `${json.message}`,
          type: "error",
          timer: 6000
        });
      });

    return Promise.reject(response.status);
  }

  if(response.status === 204){
    return response;
  }

  if (hasJsonContentType(response)) {
    return response.json();
  }

  return response;
};
