import PropTypes from "prop-types";

export const LoginProvider = {
  FACEBOOK: "FACEBOOK",
  WECHAT: "WECHAT",
  EMAIL: "EMAIL",
  GOOGLE: "GOOGLE",
  ALL: "ALL"
};

export const LoginProviderPropType = PropTypes.oneOf(
  Object.values(LoginProvider)
);
