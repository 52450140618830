import React, { useEffect, useCallback, useRef } from "react";
import { useHistory } from "react-router-dom";

import Loader from "@components/Loader";
import { TableBody } from "@components/WeVatTableV2";

import { fetchReceiptsTableData } from "@src/api";
import { useAppDispatch, useAppSelector } from "@src/hooks";
import {
  getReceipts,
  updateReceipts,
  setReceiptViewList
} from "@store/actions";
import {
  selectIsReceiptsLoading,
  selectReceipts,
  selectQueries,
  selectReceiptsPooling
} from "@store/selectors";
import ReceiptRow from "./receipts-row";

const updateEvery = 7500;

export const ReceiptsTable = () => {
  const timer = useRef<any>();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const isLoading = useAppSelector(selectIsReceiptsLoading);
  const receipts = useAppSelector(selectReceipts);
  const queries = useAppSelector(selectQueries);
  const isPooling = useAppSelector(selectReceiptsPooling);

  const startBackgroundRefresh = useCallback(() => {
    clearInterval(timer.current);

    timer.current = setInterval(async () => {
      const response = await fetchReceiptsTableData(queries);
      dispatch(updateReceipts(response));
    }, updateEvery);
  }, [queries, dispatch]);

  useEffect(() => {
    if (isPooling) {
      startBackgroundRefresh();
    } else {
      clearInterval(timer.current);
    }

    return () => {
      clearInterval(timer.current);
    };
  }, [isPooling, startBackgroundRefresh]);

  useEffect(() => {
    dispatch(getReceipts({ isPooling: true }));
  }, []);

  const handleOpenReceipts = (invoiceId) => {
    dispatch(setReceiptViewList(receipts));
    history.push(`/invoices/${invoiceId}`, {
      fromView: `/invoices${history.location.search}`,
      goBack: true
    });
  };

  return (
    <Loader loading={isLoading} waitingMessage="Loading invoices...">
      <TableBody>
        {receipts.length === 0 ? (
          <div className="no-results">No results...</div>
        ) : (
          receipts.map((r, index) => (
            <ReceiptRow
              index={index}
              key={r.receiptId}
              receipt={r}
              onClick={handleOpenReceipts}
            />
          ))
        )}
      </TableBody>
    </Loader>
  );
};
