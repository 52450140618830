import React, { useEffect, useState, useCallback, Fragment } from "react";
import classnames from "classnames";
import { useHistory, useParams } from "react-router-dom";
import { shallowEqual } from "react-redux";

import WevatModal from "@components/modals/WevatModal";
import { Grey } from "@components/ColoredText";
import Loader from "@components/Loader";
import EyeIcon from "@assets/images/isOpen.svg";
import { useAppDispatch, useAppSelector } from "@src/hooks";
import * as actions from "@store/actions";
import * as selectors from "@store/selectors";
import { ContactModal } from "@components/ContactModal";
import { UploadFileModal } from "@components/UploadFileModal";
import { ReceiptViewer } from "./receipt-viewer";
import ApproveReject from "./approve-reject";
import { ReceiptDetails } from "./receipt-details";
import SkipReceipt from "./SkipReceipt";
import ReceiptHeader from "./receipt-header";
import AddRetailerContainer from "../add-retailer";
import ReceiptVerification from "./receipt-verification";

import "./Receipt.scss";

const baseClassName = "receipt";

const Receipt = () => {
  const dispatch = useAppDispatch();
  const history = useHistory<Partial<{ fromView: string; stayOnSameView: boolean, goBack: boolean }>>();
  const locationState = history.location.state;
  const stayOnSameView = locationState?.stayOnSameView ?? false;
  // const goBackToPath = locationState?.fromView ?? "/invoices";
  const { receiptId } = useParams<{ receiptId: string }>();

  const [receiptImage, setReceiptImage] = useState("");
  const [showContact, setShowContact] = useState(false);
  const [showAttach, setShowAttach] = useState(false);

  const isSkipModalActive = useAppSelector(selectors.selectIsSkipModalActive);
  const isReverify = useAppSelector(selectors.selectIsReverify);
  const isFirstStep = useAppSelector(selectors.selectIsFirstStep);
  const isLoading = useAppSelector(selectors.selectIsReceiptLoading);
  const isError = useAppSelector(selectors.selectIsReceiptError);
  const receipt = useAppSelector(selectors.selectReceipt, shallowEqual);
  const trip = useAppSelector(selectors.selectTrip, shallowEqual);
  const receipts = useAppSelector(selectors.selectReceiptList);

  const fetchNextReceipt = useAppSelector(selectors.selectFetchNextReceipt);
  const isReceiptsTableLoading = useAppSelector(
    selectors.selectIsReceiptsLoading
  );
  const currentIndex = receipts.findIndex((r) => r.receiptId === receiptId);
  const isLastIndex = currentIndex + 1 >= receipts.length;
  const receiptIndex = isLastIndex ? receipts.length : currentIndex;
  const isReceiptVerification = useAppSelector(
    selectors.selectIsReceiptVerification
  );

  const fetchReceipt = useCallback(async () => {
    dispatch(actions.openReceipt(receiptId));
  }, [receiptId, dispatch]);

  const hideSkipModal = useCallback(
    () => dispatch(actions.setIsSkipModalActive(false)),
    [dispatch]
  );

  const resetState = useCallback(() => {
    dispatch(actions.resetReceipt());

    setReceiptImage("");
    hideSkipModal();
    setShowContact(false);
  }, [dispatch, hideSkipModal]);

  const handleHideModal = () => {
    resetState();
    dispatch(actions.setRetailerId(null));
    dispatch(actions.clearReceiptViewList());
    // history.push(goBackToPath);

    if (locationState && locationState.goBack) history.goBack();
    else history.push("/invoices");
  };

  const onClickPrevious = () => {
    const prevReceipt = receipts[receiptIndex - 1];
    if (prevReceipt != null) {
      setReceiptImage("");
      resetState();
      history.replace(`/invoices/${prevReceipt.receiptId}`, { goBack: true });
      dispatch(actions.setTripId(prevReceipt?.trip?.id));
    } else {
      resetState();
    }
  };

  const nextReceipt = useCallback(() => {
    const nextItem = receipts[receiptIndex + 1];
    if (nextItem != null) {
      history.replace(`/invoices/${nextItem.receiptId}`, { goBack: true });
      resetState();
      dispatch(actions.setTripId(nextItem?.trip?.id));
    } else {
      resetState();
    }
  }, [receipts, history, resetState, dispatch, receiptIndex]);

  const onClickDone = () => {
    if (isReceiptVerification) {
      handleHideModal();
    } else {
      dispatch(
        actions.saveReceiptChanges({
          status: !isFirstStep ? "REVIEWED" : "",
          nextReceipt: stayOnSameView ? false : true,
          isFirstStep: stayOnSameView ? true : isFirstStep,
          isReverify: stayOnSameView ? false : isReverify
        })
      );
    }
  };

  const showContactModal = () => setShowContact(true);
  const hideContactModal = () => setShowContact(false);

  const showAttachModal = () => setShowAttach(true);
  const hideAttachModal = () => setShowAttach(false);

  useEffect(() => {
    dispatch(actions.setRetailerId(receipt?.retailer?.retailerId ?? null));
  }, [receipt?.retailer, dispatch]);

  useEffect(() => {
    if (receipt.imageUrls.length && !receiptImage) {
      setReceiptImage(receipt.imageUrls[0]);
    }
  }, [receipt, receiptImage]);

  useEffect(() => {
    if (receiptId && !isError) {
      fetchReceipt();
    }

    return () => {
      resetState();
    };
  }, [receiptId, isError]);

  useEffect(() => {
    if (fetchNextReceipt) {
      nextReceipt();
    }
  }, [fetchNextReceipt, nextReceipt]);

  if (!receiptId) return null;

  const renderDefault = () => (
    <div
      id="slide-container"
      className={classnames(`slide-container ${baseClassName}__details`, {
        [`${baseClassName}__details--hide`]: isFirstStep,
        [`${baseClassName}__details--show`]: !isFirstStep
      })}
    >
      <ApproveReject
        userId={trip.userId}
        moveToNextReceipt={onClickDone}
        rejectionReasons={receipt.rejectionReasons}
        onContact={showContactModal}
      />
      <ReceiptDetails onShowFileAttach={showAttachModal} showAttach={showAttach} />
    </div>
  );

  return (
    <Fragment>
      <WevatModal type="fullscreen" show={true} onHide={handleHideModal}>
        <div className={baseClassName}>
          {!isError && (
            <ReceiptHeader
              receiptSize={receipts.length}
              onClickSkip={nextReceipt}
              onClickPrevious={onClickPrevious}
              onClickDone={onClickDone}
              className={`${baseClassName}__header`}
            />
          )}

          <div className={`${baseClassName}__body`}>
            {(isLoading || isReceiptsTableLoading || isError) && (
              <div className={`${baseClassName}__body--loader`}>
                {isError ? (
                  <div>Unable to load the page, try again later.</div>
                ) : (
                  <Loader loading={true} waitingMessage="Loading invoice..." />
                )}
              </div>
            )}

            {!isError && (
              <div className={`${baseClassName}__image`}>
                {!!receiptImage && (
                  <ReceiptViewer
                    receiptUrl={receiptImage}
                    totalConfidence={receipt.totalConfidence}
                  />
                )}

                <br />
                <Grey>Invoice ID: {receiptId}</Grey>
                <Grey>Uploaded: {receipt.uploadedTime}</Grey>
                {!!receipt?.verifiedBy?.name && (
                  <Grey>
                    Verified: {receipt.verifiedBy.name} on {receipt.lastUpdated}
                  </Grey>
                )}
              </div>
            )}

            {!isError && (
              <div className={`${baseClassName}__content`}>
                {!isReverify && isReceiptVerification ? (
                  <ReceiptVerification onShowContact={showContactModal} />
                ) : (
                  renderDefault()
                )}
              </div>
            )}
          </div>

          {receipt.isOpen && (
            <div className="receipt-is-open">
              <img src={EyeIcon} alt="is open" />
            </div>
          )}
        </div>

        {isSkipModalActive && (
          <SkipReceipt skipReceipt={nextReceipt} onDismiss={hideSkipModal} />
        )}
      </WevatModal>
      {showContact && (
        <ContactModal onSubmit={nextReceipt} onClose={hideContactModal} />
      )}
      {showAttach && (
        <UploadFileModal title="Upload file" subtitle="Upload images or PDF files associated with this invoice" refEntityId={receiptId} fileType="receipts_misc" onClose={hideAttachModal} />
        )
      }
      <AddRetailerContainer />
    </Fragment>
  );
};

export default Receipt;
