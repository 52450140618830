import {
  LABELLED_REMITTANCE_PROVIDERS,
  BACKEND_PAYABLE_METHODS
} from "../constants";

export function labelledRemittanceProviders() {
  return LABELLED_REMITTANCE_PROVIDERS;
}

export function remittanceProviders() {
  return labelledRemittanceProviders().map((m) => m.value);
}

export function backendPayableMethods() {
  return BACKEND_PAYABLE_METHODS;
}

export function isBackendPayable(refundMethod) {
  return backendPayableMethods().indexOf(refundMethod) > -1;
}

export function paymentString(payment) {
  return `${payment.remittanceProvider} • ${payment.originalAmountUsingFromCurrency} ${payment.fromCurrency} → ${payment.finalAmountUsingToCurrency} ${payment.toCurrency} • ID: ${payment.transactionId}`;
}

export function getRemittanceProvider(userPaymentInfo) {
  const bankName = userPaymentInfo.bankName;
  const refundMethod = userPaymentInfo.method;

  if (refundMethod === "UNIONPAY") {
    if (bankName || bankName !== "") {
      return "WYRE";
    }
  } else if (refundMethod === "WECHAT") {
    return "WECHAT";
  } else if (refundMethod === "ALIPAY") {
    return "ALIPAY";
  } else if (refundMethod === "CARD_PAYOUT") {
    return "CHECKOUT";
  } else if (refundMethod === "PAYPAL") {
    return "PAYPAL";
  } else {
    return "TRANSFERWISE";
  }
}
