import React, { Fragment } from "react";

import NavBar from "../NavBar";
import RecordButton from "../RecordButton";

const MainLayout = ({ children }) => {
  return (
    <Fragment>
      <NavBar />
      {children}
      <RecordButton />
    </Fragment>
  );
};

export default MainLayout;
