import React from "react";
import PropTypes from "prop-types";
import TransparentButton from "./TransparentButton";

import "./css/arrow-button.css";

const ArrowButton = props => (
  <TransparentButton
    className={`arrow ${props.direction}`}
    onClick={props.onClick}
    disabled={!props.enabled}
  />
);

const directions = ["up", "down", "left", "right"];

ArrowButton.propTypes = {
  direction: PropTypes.oneOf(directions),
  enabled: PropTypes.bool,
  onClick: PropTypes.func
};

ArrowButton.defaultProps = {
  direction: "up",
  enabled: true
};

export default ArrowButton;
