import { LocalDate } from "js-joda";
import { merge } from "../../utils/functions";
import { WEVAT_DATE_FORMATTER } from "../../utils/dates";

export const FormLogging = {
  OPEN_FORM_LOGGING: "OPEN_FORM_LOGGING",
  CLOSE_FORM_LOGGING: "CLOSE_FORM_LOGGING",
  STAMPED_DATE_CHANGED: "STAMPED_DATE_CHANGED",
  RECEIVED_DATE_CHANGED: "RECEIVED_DATE_CHANGED",
  START_FORM_IMAGE_UPLOAD: "START_FORM_IMAGE_UPLOAD",
  SCAN_ONLY_TOGGLED: "SCAN_ONLY_TOGGLED",
  TRAVELEX_TOGGLED: "TRAVELEX_TOGGLED",
  FORM_IMAGE_UPLOADED: "FORM_IMAGE_UPLOADED",
  FORM_IMAGE_UPLOAD_ERROR: "FORM_IMAGE_UPLOAD_ERROR",
  FORM_IMAGE_DELETED: "FORM_IMAGE_DELETED",
  FORM_IMAGE_SELECTED: "FORM_IMAGE_SELECTED",
  FORM_LOGGING_START: "FORM_LOGGING_START",
  FORM_LOGGING_SUCCESS: "FORM_LOGGING_SUCCESS",
  FORM_LOGGING_ERROR: "FORM_LOGGING_ERROR"
};

export const openFormLogging = (dispatch) => (form) =>
  dispatch({ type: FormLogging.OPEN_FORM_LOGGING, form });

export const closeFormLogging = (dispatch) => () =>
  dispatch({ type: FormLogging.CLOSE_FORM_LOGGING });

export const onStampedDateChanged = (dispatch) => (date) =>
  dispatch({ type: FormLogging.STAMPED_DATE_CHANGED, date });

export const onReceivedDateChanged = (dispatch) => (date) =>
  dispatch({ type: FormLogging.RECEIVED_DATE_CHANGED, date });

export const onScanOnlyToggled = (dispatch) => () =>
  dispatch({ type: FormLogging.SCAN_ONLY_TOGGLED });

export const onTravelexToggled = (dispatch) => () =>
  dispatch({ type: FormLogging.TRAVELEX_TOGGLED });

export const onFormImageSelected = (dispatch) => (image) =>
  dispatch({ type: FormLogging.FORM_IMAGE_SELECTED, image });

const initialState = {
  show: false,
  formId: void 0,
  formUploadIsLoading: false,
  stampedDate: "",
  receivedDate: "",
  noReceivedDate: true,
  scanOnly: false,
  travelexStamped: false,
  formImage: "",
  imageFile: void 0
};

export const formLoggingModal = (state = initialState, action) => {
  switch (action.type) {
    case FormLogging.FORM_LOGGING_SUCCESS:
      return initialState;

    case FormLogging.OPEN_FORM_LOGGING:
      return onFormOpened(state, action.form);

    case FormLogging.CLOSE_FORM_LOGGING:
      return initialState;

    case FormLogging.FORM_IMAGE_SELECTED:
      return merge(state, { imageFile: action.image });

    case FormLogging.START_FORM_IMAGE_UPLOAD:
      return merge(state, { formUploadIsLoading: true });

    case FormLogging.FORM_IMAGE_UPLOADED:
      return merge(state, {
        formImage: action.url,
        formUploadIsLoading: false
      });

    case FormLogging.FORM_IMAGE_UPLOAD_ERROR:
      return merge(state, { formUploadIsLoading: false });

    case FormLogging.STAMPED_DATE_CHANGED:
      return merge(state, { stampedDate: action.date });

    case FormLogging.RECEIVED_DATE_CHANGED:
      return merge(state, { receivedDate: action.date, noReceivedDate: false });

    case FormLogging.SCAN_ONLY_TOGGLED:
      return merge(state, {
        scanOnly: !state.scanOnly,
        travelexStamped: false
      });

    case FormLogging.TRAVELEX_TOGGLED:
      return merge(state, { travelexStamped: !state.travelexStamped });

    case FormLogging.FORM_IMAGE_DELETED:
      return merge(state, { formImage: void 0 });

    default:
      return state;
  }
};

const onFormOpened = (state, form) => {
  return merge(state, {
    show: true,
    formId: form.formId,
    stampedDate: form.stampedDate,
    receivedDate:
      form.receivedDate || LocalDate.now().format(WEVAT_DATE_FORMATTER),
    noReceivedDate: form.receivedDate === "",
    scanOnly: form.scanOnly || false,
    travelexStamped: form.travelexStamped || false,
    formImage: form.formImage
  });
};
