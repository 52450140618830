import React, { useEffect, useState, useRef, useCallback } from "react";
import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";

import { useAppDispatch, useAppSelector } from "../../hooks";
import { clearNotification } from "../../store/global";

import "./Notification.scss";

export const Notification = () => {
  const dispatch = useAppDispatch();
  const { message, type = "error" } = useAppSelector(selectNotification);

  const timer = useRef(null);
  const [show, setShow] = useState(false);

  const autoHide = useCallback(() => {
    clearTimeout(timer.current);

    timer.current = setTimeout(() => {
      setShow(false);
      dispatch(clearNotification());
    }, 3000);
  }, [dispatch]);

  useEffect(() => {
    if (message) setShow(true);
  }, [message]);

  useEffect(() => {
    if (show) autoHide();

    return () => {
      clearTimeout(timer.current);
    };
  }, [show, autoHide]);

  return (
    <AnimatePresence>
      {show && (
        <motion.div
          initial={{ opacity: 0, top: -45 }}
          animate={{ opacity: 1, top: 0 }}
          exit={{ opacity: 0, y: -45 }}
          transition={{ duration: 0.2 }}
        >
          <div
            className={classNames("notification", {
              notification__error: type === "error",
              notification__success: type !== "error"
            })}
          >
            {message}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

const selectNotification = ({ global }) => ({
  message: global.notificationMessage,
  type: global.notificationType
});
