export const UserDetailsAction = {
  START_LOADING: "START_LOADING",
  RESET_STATE: "RESET_STATE",
  USER_DETAILS_LOADED: "USER_DETAILS_LOADED",
  UPDATE_USER_DETAILS_SUCCESS: "UPDATE_USER_DETAILS_SUCCESS",
  SELECT_TRIP: "SELECT_TRIP",
  RECEIPT_DELETE_START: "RECEIPT_DELETE_START",
  RECEIPT_DELETE_SUCCESS: "RECEIPT_DELETE_SUCCESS",
  RECEIPT_UPDATE_START: "RECEIPT_UPDATE_START",
  RECEIPT_UPDATE_SUCCESS: "RECEIPT_UPDATE_SUCCESS",
  RELOAD: "RELOAD"
};

export const startLoading = (dispatch) => () =>
  dispatch({ type: UserDetailsAction.START_LOADING });

export const resetState = (dispatch) => () =>
  dispatch({ type: UserDetailsAction.RESET_STATE });

export const onUserDetailsLoaded = (dispatch) => (body) =>
  dispatch({ type: UserDetailsAction.USER_DETAILS_LOADED, body });

export const onRefundOptionLoaded = (dispatch) => (body) =>
  dispatch({ type: UserDetailsAction.USER_DETAILS_LOADED, body });
