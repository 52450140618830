import React from "react";
import PropTypes from "prop-types";
import { List } from "immutable";
import classNames from "classnames";

import { onBlurTree } from "../utils/dom-functions";
import { maybe } from "../utils/functions";

import "./css/dropdown.css";

class Dropdown extends React.Component {
  constructor(props) {
    super(props);

    this.state = { open: false };
  }

  componentWillUpdate(props) {
    if (this.props.selected !== props.selected) {
      this.props.onChange(null, props.selected);
    }
  }

  componentWillMount() {
    if (this.props.selected) {
      this.props.onChange(null, this.props.selected);
    }
  }

  toggleDropdown = () => this.setState({ open: !this.state.open });

  hideDropdown = () => this.setState({ open: false });

  onChangeAndHide = (label, val) => {
    this.props.onChange(label, val);
    this.hideDropdown();
  };

  render() {
    return (
      <div
        className="dropdown-container"
        tabIndex="1"
        onBlur={onBlurTree(this.hideDropdown)}
      >
        {this.renderInput()}
        {this.renderOptions()}
      </div>
    );
  }

  renderInput = () => {
    return (
      <div
        className={classNames({
          dropdown: true,
          disabled: this.props.disabled
        })}
        onClick={this.toggleDropdown}
        onBlur={onBlurTree(this.hideDropdown)}
      >
        <div className="text">{this.labelFor(this.props.selected)}</div>
        <div className="down">▼</div>
      </div>
    );
  };

  labelFor = (selected) => {
    const optionWithName = List(this.props.options)
      .filter((o) => o.value === selected)
      .first();

    return maybe(optionWithName).label;
  };

  renderOptions = () => {
    return (
      <div
        className={classNames({
          "dropdown-content": true,
          show: this.state.open,
          disabled: this.props.disabled
        })}
      >
        {this.props.options.map((o) => this.renderOption(o))}
        {this.props.showActionRow ? this.renderActionRow() : null}
      </div>
    );
  };

  renderOption = (option) => {
    return (
      <div
        onClick={this.onChangeAndHide.bind(null, option.label, option.value)}
        key={option.label}
        style={{
          display: option.show !== undefined && !option.show ? "none" : ""
        }}
      >
        {option.label}
      </div>
    );
  };

  renderActionRow = () => {
    return (
      <div onClick={this.props.onActionRowClick}>
        {this.props.actionRowText}
      </div>
    );
  };
}

Dropdown.propTypes = {
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  ),
  selected: PropTypes.string,
  showActionRow: PropTypes.bool,
  onActionRowClick: PropTypes.func,
  actionRowText: PropTypes.string,
  disabled: PropTypes.bool
};

Dropdown.defaultProps = {
  selected: "DEFAULT",
  actionRow: false,
  disabled: false
};

export default Dropdown;
