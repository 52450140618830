import React, { FC, useState } from "react";

import {
  getFiles,
  attachFileToEntity,
  deleteFile
} from "@api/users/manage-files";

import classNames from "classnames";
import Button from "@components/Button";
import WevatModal from "@components/modals/WevatModal";
import { useAppDispatch, useAppSelector } from "@src/hooks";
import {
  TableHeadCell,
  TableHeader,
  TableBodyRow,
  TableBodyCell
} from "@components/WeVatTableV2";
import Toast from "@components/Toast";

import "./UploadFileModal.scss";

type Props = {
  onClose?: () => void;
  refEntityId: string;
  fileType: string;
  title: string;
  subtitle: string;
};

const gridTemplateColumns = "0.1fr 8fr 5fr 2fr";

interface FileItem {
  fileId?: string;
  url?: string;
  refEntityId: string;
  fileName: string;
  createdAt?: string;
  toUpload?: boolean;
  file?;
}

const emptyFiles: FileItem[] = []

const FileUploader: FC<{ onFilesAdded: (file: File[]) => void; allowMultiple: boolean; }> = ({
  onFilesAdded,
  allowMultiple = true,
}) => {
  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef(null);

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = event => {
    hiddenFileInput.current.click();
  };
  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file 
  const handleChange = event => {
    onFilesAdded(Array.from(event.target.files))
  };
  return (
    <>
      <Button onClick={handleClick}>
        &nbsp;+ Add file&nbsp;
      </Button>
      <input
        type="file"
        multiple={allowMultiple}
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: 'none' }} /* Make the file input element invisible */
      />
    </>
  );
}

const FileRow: FC<{ file: FileItem; index: number; onDelete: (index) => void }> = ({
  file,
  index,
  onDelete
}) => (
  <TableBodyRow
    index={index}
    gridTemplateColumns={gridTemplateColumns}
  >

    <TableBodyCell>
    </TableBodyCell>
    <TableBodyCell>
        <p><a id={`${index}${file.fileId}`} href={file.url} target="_blank">{file.fileName}</a></p>
    </TableBodyCell>
    <TableBodyCell>
      <p>{file.createdAt}</p>
    </TableBodyCell>
    <TableBodyCell>
      <div
        onClick={() => onDelete(index)}
        className={classNames("receipt-footer-buttons__square bin-btn")}
      />
    </TableBodyCell>
  </TableBodyRow>
);

export const UploadFileModal: FC<Props> = ({ onClose, refEntityId, fileType, title, subtitle }) => {
  const [needsLoad, setNeedsLoad] = useState(true);
  const [show, setShow] = useState(true);


  const [files, setFiles] = useState(emptyFiles);

  if(needsLoad) {
    getFiles(refEntityId).then(fetchedFiles => {
      console.log(fetchedFiles)
      const filesForList: FileItem[] = fetchedFiles
        .map(f => {
          let fileItem: FileItem = {
            fileId: f.fileId,
            url: f.url,
            refEntityId: f.refEntityId,
            fileName: f.fileName,
            createdAt: f.createdAt,
            toUpload: false
          };
          return fileItem

        })

      setFiles(filesForList)
    })
    setNeedsLoad(false);
  }

  const addFileToUploadList = (newFiles: File[]) => {
    const filesForList: FileItem[] = newFiles
      .map(f => {
        let fileItem: FileItem = {
          refEntityId: refEntityId,
          fileName: f.name,
          createdAt: "Not yet uploaded",
          toUpload: true,
          file: f
        };
        return fileItem

      })

    setFiles([...files, ...filesForList])
  }

  const uploadFiles = () => {
    let toUpload =  files.filter(f => f.toUpload === true);

  
    Promise.allSettled(
      toUpload.map(f => {
        attachFileToEntity(f.file, f.refEntityId, fileType)
      })
    ).then((results) => {
      Toast({
        title: `Successfully uploaded files`,
        icon: "success"
      });
    }).finally(
      () =>  {
        setShow(false)
        onClose()
      }
    );
  }


  const doOnDelete = (index: number) => {

    let removedItem: FileItem = files.splice(index, 1)[0];
    if (!removedItem.toUpload) {
      console.log("Remove on backend");
      deleteFile(removedItem.fileId);
    }
    setFiles([...files])
  }


  return (
    <WevatModal
      type="medium-large"
      show={show}
      showCloseButton={true}
      onHide={onClose}
    >
      <div className="contact-modal-body">
        <h2>{title}</h2>
        <label>{subtitle}</label>
        <br />
        <br />
        <div className="files-table">
          <TableHeader gridTemplateColumns={gridTemplateColumns}>
            <TableHeadCell title="" />
            <TableHeadCell title="File Name" />
            <TableHeadCell title="Uploaded At" />
            <TableHeadCell title="" />
          </TableHeader>
          {files && files.length === 0 ? (
            <span></span>
          ) : (
            files.map((f, index) => (
              <FileRow
                index={index}
                key={f.fileId}
                file={f}
                onDelete={doOnDelete}
              />
            ))
          )}

        </div>
        <br />
        <br />

        <FileUploader onFilesAdded={addFileToUploadList} allowMultiple={true} />
        <br />
        <br />
        <br />
        <br />
        <div className="footer-buttons">
          <Button onClick={uploadFiles} disabled={files.filter(f => f.toUpload === true).length < 1} >
            &nbsp;Upload&nbsp;
          </Button>
        </div>
      </div>


    </WevatModal>
  );
};
