import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import "./css/date-time-input.css";

const DateTimeInput = (props) => {
  const [date, setDate] = useState(props.date);
  const [time, setTime] = useState(props.time || "00:01");

  useEffect(() => {
    setDate(props.date);
    setTime(props.time);
  }, [props.date, props.time]);

  const handleDateChange = (e) => setDate(e.target.value);
  const handleTimeChange = (e) => setTime(e.target.value);

  return (
    <div className="date-time-input">
      <input
        type="date"
        value={date}
        onInput={handleDateChange}
        onBlur={(e) => props.onDateChange(e.target.value)}
        className="date-input"
        style={!date ? {color: 'white'} : {}} // ugly hack
      />
      <input
        type="time"
        value={time}
        onInput={handleTimeChange}
        onBlur={(e) => props.onTimeChange(e.target.value)}
        className="time-input"
        style={!time ? {color: 'white'} : {}} // ugly hack
      />
    </div>
  );
};

DateTimeInput.propTypes = {
  date: PropTypes.string,
  time: PropTypes.string,

  onDateChange: PropTypes.func,
  onTimeChange: PropTypes.func
};

export default DateTimeInput;
