import React from "react";
import PropTypes from "prop-types";

import "./css/buttons.css";

const Button = (props) => (
  <button
    {...props}
    className={`button ${props.type} ${props.size} ${props.className || ""}`}
    disabled={props.disabled}
    type={props.nativetype}
  >
    {props.text || props.children}
  </button>
);

Button.propTypes = {
  id: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.oneOf([
    "primary",
    "danger",
    "blue",
    "gray",
    "primary-outline"
  ]),
  size: PropTypes.oneOf(["micro", "small", "big", "x-big"]),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
  nativetype: PropTypes.oneOf(["button", "submit", "reset"])
};

Button.defaultProps = {
  type: "primary",
  size: "big",
  disabled: false,
  className: "",
  nativetype: "button"
};

export default Button;
