import React from "react";
import PropTypes from "prop-types";
import "./css/order-by.css";

const OrderBy = props => (
  <div className={`order-by`}>
    <div
      className={`top ${props.direction === "ASC" ? "on" : "off"}`}
      onClick={() => props.onClick("ASC")}
    />
    <div
      className={`bottom ${props.direction === "DESC" ? "on" : "off"}`}
      onClick={() => props.onClick("DESC")}
    />
  </div>
);

OrderBy.propTypes = {
  direction: PropTypes.oneOf(["DESC", "ASC"]),
  onClick: PropTypes.func
};

OrderBy.defaultProps = {
  direction: "none"
};

export default OrderBy;
