import { wevat } from "../http-client";
import { withEmptyMock } from "../../utils/prod-test";

import { ReceiptStatus } from "../../typings";
import {
  RejectionReason,
  ItemCategories,
  Receipts,
  TripResponse,
  Reviewer
} from "./types";
import Toast from "@components/Toast";

export const fetchReceiptsTableData = ({
  limit = 20,
  page = 0,
  sortBy,
  sortDirection,
  status,
  retailerId,
  rejectionReason,
  lastVerifiedBy
}: {
  limit?: number;
  page?: number;
  sortBy?: string | null;
  sortDirection?: string | null;
  status?: string | null;
  retailerId?: string | null;
  rejectionReason?: string | null;
  lastVerifiedBy?: string | null;
}): Promise<Receipts> => {
  let queryParams = "";

  if (sortBy) queryParams += "&sort.by=" + sortBy;
  if (sortDirection) queryParams += "&sort.direction=" + sortDirection;
  if (status) queryParams += "&status=" + status;
  if (rejectionReason) queryParams += "&rejectionReason=" + rejectionReason;
  if (lastVerifiedBy) queryParams += "&lastVerifiedBy=" + lastVerifiedBy;
  if (retailerId) queryParams += "&retailerId=" + retailerId;

  return wevat().get(
    `/receipts?pagination.itemsLimit=${limit}&pagination.current=${page}${queryParams}`
  );
};

export const fetchQAReceiptsTableData = () => {
  return fetchReceiptsTableData({
    status: ReceiptStatus.REQUIRES_QUALITY_ASSURANCE
  });
};

export const getRejectionReasons = (): Promise<RejectionReason[]> => {
  return wevat().get(`/rejectionreasons`);
};

export const getItemCategories = (): Promise<ItemCategories[]> => {
  return wevat().get(`/receipts/items/categories`);
};

export const updateReceipt = ({
  receiptId,
  receiptNumber,
  retailerId,
  purchaseTime,
  rejectionReasons,
  items,
  total,
  taxTotal,
  status,
  rejectionReasonNotes,
  isDigitalReceipt,
}): Promise<void> => {
  return withEmptyMock(() => {
    return wevat()
      .patch(`/receipts/${receiptId}`, {
        receiptNumber,
        retailerId: retailerId || null,
        purchaseTime,
        rejectionReasons,
        items,
        total,
        taxTotal,
        status,
        rejectionReasonNotes,
        isDigitalReceipt,
      })
      .then((returnedReceipt) => {
        let didBackendChangeStatus = status === returnedReceipt.status
        Toast({
          title: didBackendChangeStatus ? `Great Job! Receipt ${receiptId} is marked as ${returnedReceipt.status}` : `Oops!! Receipt ${receiptId} is marked as ${returnedReceipt.status} instead of ${status}`,
          icon: didBackendChangeStatus ? "success" : "error",
          timer: 7000
        })
        return {isError: false, response: returnedReceipt}
      })
      .catch((error) => ({ isError: true, error: error}));
  });
};

export const deleteReceipt = (receiptId: string) => {
  return withEmptyMock(() => {
    return wevat().delete(`/receipts/${receiptId}`);
  });
};

export const markReceiptAsOpen = (receiptId: string) => {
  //Note: Discard 400 errors here to silence the errors when opening a receipt on a closed trip
  return wevat({ discardError: (response) => response.status === 400 })
    .put(`/receipts/${receiptId}/open`)
    .catch(() => ({ error: false }));
};

export const fetchTripReceipts = ({
  tripId,
  sortBy,
  sortDirection
}: {
  tripId: string;
  sortBy?: string | null;
  sortDirection?: string | null;
}) => {
  let queryParams = "";
  if (sortBy) queryParams += "&sort.by=" + sortBy;
  if (sortDirection) queryParams += "&sort.direction=" + sortDirection;

  return wevat().get(`/receipts/?tripId=${tripId}${queryParams}`);
};

export const fetchReceiptData = (receiptId: string) => {
  return wevat()
    .get(`/receipts/${receiptId}`)
    .catch(() => ({ error: true }));
};

export const fetchTrip = (tripId: string): Promise<TripResponse> => {
  return wevat()
    .get(`/trips/${tripId}`)
    .catch(() => ({ error: true }));
};

export const getReviewers = (): Promise<Reviewer[]> => {
  return wevat().get(`/users/reviewers`);
};

export const deleteTrip = (tripId: string): Promise<TripResponse> => {
  return wevat()
    .delete(`/trips/${tripId}`)
    .catch(() => ({ error: true }));
};