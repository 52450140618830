import { isStub } from "../config";

export const withMock = function(prodFunc, mockFunc) {
  if (isStub) {
    return mockFunc;
  }
  return prodFunc;
};

export const withEmptyMock = function(prodFunc) {
  if (isStub) {
    return Promise.resolve();
  }

  return prodFunc();
};
