export const hasJsonContentType = function(response) {
  const contentType = response.headers.get("Content-Type");

  return contentType && contentType.indexOf("application/json") !== -1;
};

export const isErrorResponse = function(response) {
  return response.status < 200 || response.status > 300;
};

export const jsonHeaders = function() {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Accept", "application/json");
  return headers;
};

export const headersFrom = function(object) {
  const headers = new Headers();

  Object.keys(object).forEach(key => {
    headers.append(key, object[key]);
  });

  return headers;
};

export const mergeHeaders = function(h1, h2) {
  h2.forEach((value, key) => {
    h1.append(key, value);
  });

  return h1;
};
