import React, { FC } from "react";

import { selectNonAutomatedRejectionReasons } from "@store/selectors";
import { updateReceipt, updateState } from "@store/actions";
import Checkbox from "@components/Checkbox";
import { useAppSelector, useAppDispatch } from "@src/hooks";
import ReceiptFooter from "../receipt-footer";

import "./ApproveReject.scss";

export type SelectedReason = {
  id: string;
  name: string;
};

type Props = {
  userId: string;
  rejectionReasons?: string[];
  moveToNextReceipt: () => void;
  onContact: () => void;
};

const ApproveReject: FC<Props> = ({
  moveToNextReceipt,
  rejectionReasons,
  onContact,
  userId
}) => {
  const dispatch = useAppDispatch();
  const reasons = useAppSelector(selectNonAutomatedRejectionReasons);
  const hasReasons =
    !!rejectionReasons.length &&
    (rejectionReasons.includes("CONTACT_SUPPORT") ||
      reasons.some((r) => rejectionReasons.includes(r.id)));

  const toggleCheckList = (option: SelectedReason) => {
    let updatedList = [...rejectionReasons];
    if (updatedList.some((r) => r === option.id)) {
      updatedList = updatedList.filter((r) => r !== option.id);
    } else {
      updatedList.push(option.id);
    }
    dispatch(updateReceipt({ rejectionReasons: updatedList }));
  };

  const handleConfirm = async () => {
    if (!hasReasons) {
      dispatch(updateState({ isFirstStep: false }));
    } else {
      moveToNextReceipt();
    }
  };

  return (
    <div className="approve-reject">
      <div className="approve-reject-reasons">
        <div className="rejection-reasons">
          {reasons.map(({ id, name }) => (
            <Checkbox
              isCheckboxPrefixed={true}
              key={id}
              label={name}
              checked={rejectionReasons.some((r) => r === id)}
              onChange={() => toggleCheckList({ id, name })}
            />
          ))}
        </div>
      </div>

      <ReceiptFooter
        userId={userId}
        onConfirm={handleConfirm}
        onContact={onContact}
        confirmLabel={hasReasons ? "Confirm reject" : "Continue"}
      />
    </div>
  );
};

export default ApproveReject;
