import React, { FC, Fragment } from "react";
import { getRole, ROLES } from "../api/roles";

interface RoleProps {
  roles: ROLES[];
}

const Roles: FC<RoleProps> = ({ roles, children }) => {
  const singleRole = getRole();

  if (!roles.includes(singleRole)) return null;
  return <Fragment>{children}</Fragment>;
};

const OnlyFor = {
  Admin: (props) => (
    <Roles {...props} roles={[ROLES.ADMIN, ROLES.READ_ONLY_ADMIN]}>
      {props.children}
    </Roles>
  )
};

export default OnlyFor;
