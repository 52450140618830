import React, { FC, useState, Fragment, useEffect } from "react";
import { useLocation } from "react-router-dom";

import SearchTextbox from "@components/SearchTextbox";
import ReceiptStatus from "@components/ReceiptStatus";
import { TableHeadCell, TableHeader } from "@components/WeVatTableV2";
import DropdownV2 from "@components/DropdownV2";
import PagingArrows from "@components/PagingArrows";
import { useAppDispatch, useAppSelector } from "@src/hooks";
import { setQueries, stopPooling } from "@store/actions";
import {
  selectReceiptsPagination,
  selectQueries,
  selectRetailers,
  selectReviewers,
  selectRejectionReasons
} from "@store/selectors";
import { SortBy, SortDirection } from "@store/types";

import "./ReceiptsTableBar.scss";

type Props = {
  onSearchSubmit: (keyword: string) => void;
};

const updateBrowserUrl = (pageNumber: number) => {
  window.history.replaceState(null, null, `/invoices?page=${pageNumber}`);
};

const ReceiptsTableBar: FC<Props> = ({ onSearchSubmit }) => {
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const queryParamPage = urlSearchParams.get("page");

  const [searchText, setSearchText] = useState("");
  const {
    sortBy,
    sortDirection,
    status,
    retailerId,
    rejectionReason,
    lastVerifiedBy
  } = useAppSelector(selectQueries);
  const rejectionReasons = useAppSelector(selectRejectionReasons);
  const retailers = useAppSelector(selectRetailers);
  const reviewers = useAppSelector(selectReviewers);
  const pagination = useAppSelector(selectReceiptsPagination);

  const changeOrder = (sortKey: SortBy, direction: SortDirection) => {
    dispatch(stopPooling());

    dispatch(
      setQueries({
        sortBy: sortKey,
        sortDirection: direction,
        isPooling: true,
        page: 0
      })
    );

    updateBrowserUrl(0);
  };

  const updateQuery = (key: string, value: string) => {
    dispatch(stopPooling());
    const data = { [key]: value };

    if (key === "status") {
      if (value !== "REJECTED") data.rejectionReason = null;
      if (value !== "PENDING") changeOrder("VERIFIED_TIME", "DESC");
      if (value === "PENDING") changeOrder("DEPARTURE_TIME", "ASC");
    }

    dispatch(setQueries({ isPooling: true, ...data, page: 0 }));
    updateBrowserUrl(0);
  };

  const updatePagination = (pageNumber: number) => {
    let validNumber = pageNumber;

    if (pageNumber < 0) validNumber = 0;
    if (pageNumber > pagination.total) validNumber = pagination.total;

    updateBrowserUrl(validNumber);
    dispatch(setQueries({ isPooling: true, page: validNumber }));
  };

  useEffect(() => {
    if (queryParamPage) {
      dispatch(
        setQueries({ isPooling: true, page: parseInt(queryParamPage, 10) })
      );
    }
  }, [queryParamPage, dispatch]);

  return (
    <Fragment>
      <div className="receipts-table-filters">
        <div className="receipts-table-filters__col-left">
          <DropdownV2
            width={260}
            isSearchable={true}
            placeholder="Select retailer"
            value={retailerId}
            onChange={(value) => updateQuery("retailerId", value)}
            options={retailers.map((r) => ({
              value: r.retailerId,
              label: r.retailerName
            }))}
          />

          <DropdownV2
            width={200}
            placeholder="Select user"
            value={lastVerifiedBy}
            onChange={(value) => updateQuery("lastVerifiedBy", value)}
            options={reviewers.map((r) => ({
              value: r.id,
              label: r.email
            }))}
          />

          <DropdownV2
            width={130}
            hideClear={true}
            options={generateStatusOptions()}
            value={status}
            placeholder="Status"
            onChange={(value) => updateQuery("status", value)}
          />

          {status === "REJECTED" && (
            <DropdownV2
              width={260}
              placeholder="Select reject reason"
              value={rejectionReason}
              onChange={(value) => updateQuery("rejectionReason", value)}
              options={[...rejectionReasons]
                .sort((a, b) => {
                  if (a.name < b.name) return -1;
                  if (a.name > b.name) return 1;
                  return 0;
                })
                .map((r) => ({
                  value: r.id,
                  label: r.name
                }))}
            />
          )}
        </div>

        <div className="receipts-table-filters__col-right">
          <SearchTextbox
            hint="Open receipts using ID"
            onSubmit={() => onSearchSubmit(searchText)}
            onChange={(text) => setSearchText(text)}
            value={searchText}
          />

          <PagingArrows
            page={pagination.current + 1}
            numberOfPages={pagination.total}
            onNext={() => updatePagination(pagination.current + 1)}
            onPrevious={() => updatePagination(pagination.current - 1)}
          />
        </div>
      </div>
      <TableHeader gridTemplateColumns="30px 85px 2fr 1fr 1fr 1fr 80px 1fr">
        <TableHeadCell title="" />
        <TableHeadCell title="Status" />
        <TableHeadCell title="Retailer" />
        <TableHeadCell
          title="Departing"
          index="DEPARTURE_TIME"
          sortBy={sortBy}
          sortDirection={sortDirection}
          onSortChange={changeOrder}
        />
        <TableHeadCell
          title="Uploaded"
          index="UPLOAD_TIME"
          sortBy={sortBy}
          sortDirection={sortDirection}
          onSortChange={changeOrder}
        />
        <TableHeadCell
          title="Verified"
          sortBy={sortBy}
          index="VERIFIED_TIME"
          sortDirection={sortDirection}
          onSortChange={changeOrder}
        />
        <TableHeadCell
          title="Items"
          subTitle="accepted"
          index="ITEMS"
          sortBy={sortBy}
          sortDirection={sortDirection}
          onSortChange={changeOrder}
        />
        <TableHeadCell
          title="Total"
          subTitle="accepted"
          index="TOTAL"
          sortBy={sortBy}
          sortDirection={sortDirection}
          onSortChange={changeOrder}
          alignRight={true}
          className="pad-right-30"
        />
      </TableHeader>
    </Fragment>
  );
};

export default ReceiptsTableBar;

const STATUS_OPTIONS: {
  label: string;
  value: string;
  theme: "gray" | "green" | "red" | "blue";
}[] = [
  {
    label: "Pending",
    value: "PENDING",
    theme: "gray"
  },
  {
    label: "Accepted",
    value: "REVIEWED",
    theme: "green"
  },
  {
    label: "Rejected",
    value: "REJECTED",
    theme: "red"
  },
  {
    label: "Select all",
    value: "PENDING,REVIEWED,REJECTED",
    theme: "blue"
  }
];

const generateStatusOptions = () => {
  return STATUS_OPTIONS.map((o) => ({
    label: <ReceiptStatus label={o.label} theme={o.theme} key={o.label} />,
    value: o.value
  }));
};
