import React from "react";
import PropTypes from "prop-types";
import Button from "./Button";

import "./css/toggleable-buttons.css";

const ToggleableButton = props => {
  const [toggled, changeToggle] = React.useState(props.checked);

  const onChange = () => {
    changeToggle(!toggled);
    props.onChange(!toggled);
  };

  return (
    <Button
      {...props}
      className={`${props.className} ${toggled ? "toggled" : ""}`.trim()}
      onClick={onChange}
    />
  );
};

ToggleableButton.propTypes = {
  ...Button.PropTypes,
  checked: PropTypes.bool,
  onChange: PropTypes.func
};

ToggleableButton.defaultProps = {
  ...Button.defaultProps,
  checked: false
};

export default ToggleableButton;
