import React from "react";
import { LocalDate, ChronoUnit } from "js-joda";

import { ENGLISH_DATE_FORMATTER, validDate } from "@utils/dates";
import { Red } from "./ColoredText";

const LeavingDateView = ({ date }) => {
  if (!validDate(date)) {
    return <p>{date}</p>;
  }

  return <DateToWords date={date} />;
};

const DateToWords = ({ date }) => {
  const today = LocalDate.now();
  const wordDate = LocalDate.parse(date);

  if (wordDate.equals(today)) {
    return <Red>Today</Red>;
  } else if (today.plusDays(1).isEqual(wordDate)) {
    return <Red>1 day</Red>;
  } else if (wordDate.isAfter(today)) {
    if (today.until(wordDate, ChronoUnit.DAYS) > 10) {
      return <p>{wordDate.format(ENGLISH_DATE_FORMATTER)}</p>;
    }
    return <p>{`${today.until(wordDate, ChronoUnit.DAYS)} days`}</p>;
  }

  return <p>Left on {date}</p>;
};

export default LeavingDateView;
