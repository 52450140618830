import React from "react";
import PropTypes from "prop-types";

import "./css/currency-input.css";
import { formatCurrencyMoney, formatMoney } from "../utils/currency";
import TextInput from "./TextInput";

class CurrencyInput extends React.Component {
  state = {
    isEditing: false
  };

  isEditing = (isEditing) => {
    return this.setState({ isEditing });
  };

  getInputValue = () => {
    const { value = 0, overrideValue } = this.props;
    const { isEditing } = this.state;

    if (overrideValue) {
      return overrideValue;
    }

    return { value: isEditing ? value : formatMoney(value) };
  };

  discardSpecialCharacters = (e) => {
    const { charCode } = e;
    // only allow 0-9 and .
    if (!(charCode === 46 || (charCode >= 48 && charCode <= 57))) {
      e.preventDefault();
    } else if (charCode === 46 && this.props.value.includes(".")) {
      e.preventDefault();
    }
  };

  render() {
    const {
      onChange,
      currency,
      disabled,
      onBlur,
      style,
      hideCurrency,
      ...rests
    } = this.props;

    const symbol = getCurrencySymbolFromCode(currency);

    const inputValue = this.getInputValue();

    return (
      <TextInput
        {...rests}
        alignment="center"
        startAdornment={!hideCurrency ? <span>{symbol}</span> : undefined}
        value={inputValue.value}
        disabled={disabled}
        onFocus={async (event) => {
          event.persist();
          await this.isEditing(true);
          event.target.select();
        }}
        onBlur={() => {
          this.isEditing(false);
          onBlur && onBlur();
        }}
        onKeyPress={this.discardSpecialCharacters}
        onChange={onChange}
        style={{ ...style, color: inputValue.color }}
      />
    );
  }
}

CurrencyInput.propTypes = {
  currency: PropTypes.oneOf(["USD", "GBP", "CNY", "EUR"]),
  value: PropTypes.string,
  overrideValue: PropTypes.shape({
    value: PropTypes.string,
    color: PropTypes.string
  }),
  disabled: PropTypes.bool,
  hideCurrency: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  style: PropTypes.object
};

CurrencyInput.defaultProps = {
  currency: "GBP",
  disabled: false,
  value: 0
};

const getCurrencySymbolFromCode = (code) => {
  if (code) return formatCurrencyMoney(0, code).split("0")[0].trim();

  return "";
};

export default CurrencyInput;
