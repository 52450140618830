import React from "react";

import ReceiptsTable from "./receipts-table";
import ReceiptsTableBar from "./receipts-table-bar";

import { useAppDispatch } from "../../hooks";
import { stopPooling } from "./store";

export const Receipts = ({ history }) => {
  const dispatch = useAppDispatch();

  const fetchReceipt = (receiptId: string) => {
    dispatch(stopPooling());
    history.push(`/invoices/${receiptId}`, {
      fromView: `/invoices${history.location.search}`,
      stayOnSameView: true,
      goBack: true
    });
  };

  return (
    <div className="receipts">
      <ReceiptsTableBar onSearchSubmit={fetchReceipt} />
      <ReceiptsTable />
    </div>
  );
};
