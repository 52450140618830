import { wevat } from "../http-client";
import { withMock } from "../../utils/prod-test";
import { withAuthHeaderIfSet } from "../tokens";
import { WEVAT_GATEWAY } from "../../config";

export interface AttachedFile {
  fileId: string;
  url: string;
  fileType: string;
  refEntityId: string;
  fileName: string;
  createdAt: string;
}

export function attachFileToEntity(image, refEntityId: string, fileType: string) : Promise<string> {
  return withMock(function () {
    const config = {
      method: "POST",
      headers: withAuthHeaderIfSet(new Headers()),
      body: imageFormData(image, refEntityId, fileType)
    };

    return fetch(WEVAT_GATEWAY + "/api/v1/files/", config)
      .then((res) => res.json())
      .then((res) => {
        if (!res.url) {
          throw new Error();
        }
        return res.url;
      });
  }, mockUpload)();
}

function mockUpload() {
  return Promise.resolve("http://some-url.com");
}

function imageFormData(image, refEntityId, fileType) {
  const fd = new FormData();
  fd.append("file", image);
  fd.append("type", fileType);
  fd.append("refEntityId", refEntityId);

  return fd;
}

export const deleteFile = (fileId:string): Promise<void> => {
  return wevat({oldApi: true})
    .delete(`/api/v1/files/${fileId}`);
};

export const getFiles = (refEntityId: string
  ): Promise<AttachedFile[]> => {
  return wevat({oldApi: true})
    .get(`/api/v1/files/search?refEntityId=${refEntityId}`)
    .catch(() => ({ error: true }));
};
