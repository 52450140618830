import React from "react";
import PropTypes from "prop-types";

import Button from "../../../components/Button";
import DropdownV2 from "@components/DropdownV2";
import {
  SUPPORTED_COUNTRIES_MAP,
  SUPPORTED_DEPARTURE_POINTS
} from "../../../utils/trips";

import "./edit-trip-dates-view.css";

const generateDepartureOptions = () => {
  return Object.entries(SUPPORTED_DEPARTURE_POINTS).map(([value, label]) => ({
    label,
    value
  }));
};

const generateCountryOptions = () => {
  return Object.entries(SUPPORTED_COUNTRIES_MAP).map(([value, label]) => ({
    label,
    value
  }));
};

class EditTripDatesView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      countryCode: props.trip?.countryCode || "FR",
      arrivalDate: props.arrivalDate || "",
      departureDate: props.departureDate || "",
      airport: props.trip?.airport || ""
    };
  }

  componentDidCatch(error) {
    alert(`There was an error: ${error.message}`);
  }

  onCountryChange = (countryCode) => {
    this.setState({ countryCode });
  };

  onArrivalDateChange = (event) => {
    this.setState({ arrivalDate: event.target.value });
  };

  onDepartureDateChange = (event) => {
    this.setState({ departureDate: event.target.value });
  };

  onDeparturPointChange = (airport) => {
    this.setState({ airport });
  };

  updateTripDates = () => {
    this.props.updateTripDetails(this.props.tripId, { ...this.state });
  };

  render() {
    const { countryCode, arrivalDate, departureDate, airport } = this.state;

    return (
      <div className={"edit-trip-dates-view"}>
        <h2>Update trip</h2>

        <div className="field-container">
            <label>Trip country</label>
            <DropdownV2
              id="countryCode"
              hideClear={true}
              isSearchable={true}
              value={countryCode}
              options={generateCountryOptions()}
              placeholder="Select a country"
              onSelect={this.onCountryChange}
              />
        </div>
        <div className="field-container">
          <label>Arrival date</label>
          <input
            type={"date"}
            defaultValue={arrivalDate}
            onChange={this.onArrivalDateChange}
          />
        </div>
        <div className="field-container">
          <label>Departure date</label>
          <input
            type={"date"}
            defaultValue={departureDate}
            onChange={this.onDepartureDateChange}
          />
        </div>
        <div className="field-container">
          <label>Departure point</label>
          <DropdownV2
            hideClear={true}
            isSearchable={true}
            value={airport}
            options={generateDepartureOptions()}
            placeholder="Departure point"
            onChange={this.onDeparturPointChange}
          />
        </div>

        <div className="update-button-container">
          <Button text={"Update trip"} onClick={this.updateTripDates} />
        </div>
      </div>
    );
  }
}

EditTripDatesView.propTypes = {
  updateTripDetails: PropTypes.func.isRequired,
  trip: PropTypes.shape({
    countryCode: PropTypes.string,
    airport: PropTypes.string
  }),
  tripId: PropTypes.string.isRequired
};

export default EditTripDatesView;
