import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { reduxStore } from "./store/store";
import AppContainer from "./App";

import "./assets/css/base.css";

ReactDOM.render(
  <Provider store={reduxStore}>
    <AppContainer />
  </Provider>,
  document.getElementById("root")
);
