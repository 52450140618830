import { List } from "immutable";
import { listify, merge } from "../../utils/functions";
import { UserDetailsAction } from "../actions/user-details-actions";
import { TripActions } from "./selected-trip-reducer";
import { Instant, LocalDate, LocalDateTime } from "js-joda";
import { WEVAT_DATE_FORMATTER } from "../../utils/dates";
import { getRemittanceProvider, isBackendPayable } from "../../utils/payments";
import {
  BACKEND_PAYABLE_METHODS,
  FORM_PAYMENT_STATUSES
} from "../../constants";

export const PaymentFormActions = {
  PAYMENT_METHOD_CHANGED: "PAYMENT_METHOD_CHANGED",
  PAYMENT_DATE_CHANGED: "PAYMENT_DATE_CHANGED",
  ORIGINAL_AMOUNT_CHANGED: "ORIGINAL_AMOUNT_CHANGED",
  FINAL_AMOUNT_CHANGED: "FINAL_AMOUNT_CHANGED",
  REFUND_AMOUNT_CHANGED: "REFUND_AMOUNT_CHANGED",
  FEE_AMOUNT_CHANGED: "FEE_AMOUNT_CHANGED",
  TRANSACTION_ID_CHANGED: "TRANSACTION_ID_CHANGED",
  PAYMENT_FORMS_CHANGED: "PAYMENT_FORMS_CHANGED",
  PAYMENT_COMMENTS_CHANGED: "PAYMENT_COMMENTS_CHANGED",
  PAYMENT_LOG_START: "PAYMENT_LOG_START",
  PAYMENT_LOG_INVALID_OR_MISSING_INPUT: "PAYMENT_LOG_INVALID_OR_MISSING_INPUT",
  PAYMENT_LOG_SUCCESS: "PAYMENT_LOG_SUCCESS",
  PAYMENT_LOG_ERROR: "PAYMENT_LOG_ERROR",
  PAYMENT_LOG_ERROR_RESOLVED: "PAYMENT_LOG_ERROR_RESOLVED",
  CARD_NUMBER_REVEAL: "CARD_NUMBER_REVEAL",
  // ------
  PAYMENT_INFORMATION_NEEDS_RELOAD: "PAYMENT_INFORMATION_NEEDS_RELOAD",
  PAYMENT_INFORMATION_START_LOADING: "PAYMENT_INFORMATION_START_LOADING",
  PAYMENT_INFORMATION_FINISHED_LOADING: "PAYMENT_INFORMATION_FINISHED_LOADING",
  PAYMENT_INFORMATION_ERROR_LOADING: "PAYMENT_INFORMATION_ERROR_LOADING",
  // ------
  EXCHANGE_NEEDS_RELOAD: "EXCHANGE_NEEDS_RELOAD",
  EXCHANGE_START_LOADING: "EXCHANGE_START_LOADING",
  EXCHANGE_FINISHED_LOADING: "EXCHANGE_FINISHED_LOADING",
  EXCHANGE_ERROR_LOADING: "EXCHANGE_ERROR_LOADING",
  // ------
  PAYMENT_LOGS_NEEDS_RELOAD: "PAYMENT_LOGS_NEEDS_RELOAD",
  PAYMENT_LOGS_START_LOADING: "PAYMENT_LOGS_START_LOADING",
  PAYMENT_LOGS_FINISHED_LOADING: "PAYMENT_LOGS_FINISHED_LOADING",
  PAYMENT_LOGS_ERROR_LOADING: "PAYMENT_LOGS_ERROR_LOADING"
};

export const onPaymentDateChange = (dispatch) => (completionDate) =>
  dispatch({ type: PaymentFormActions.PAYMENT_DATE_CHANGED, completionDate });

export const onPaymentMethodChange = (dispatch) => (_, remittanceProvider) =>
  dispatch({
    type: PaymentFormActions.PAYMENT_METHOD_CHANGED,
    remittanceProvider
  });

export const onOriginalAmountChange = (dispatch) => (originalAmount) =>
  dispatch({
    type: PaymentFormActions.ORIGINAL_AMOUNT_CHANGED,
    originalAmount
  });

export const onFinalAmountChange = (dispatch) => (finalAmount) =>
  dispatch({ type: PaymentFormActions.FINAL_AMOUNT_CHANGED, finalAmount });

export const onRefundAmountChange = (dispatch) => (refundAmount) =>
  dispatch({ type: PaymentFormActions.REFUND_AMOUNT_CHANGED, refundAmount });

export const onFeeAmountChange = (dispatch) => (feeAmount) =>
  dispatch({ type: PaymentFormActions.FEE_AMOUNT_CHANGED, feeAmount });

export const onTransactionIdChange = (dispatch) => (transactionId) =>
  dispatch({ type: PaymentFormActions.TRANSACTION_ID_CHANGED, transactionId });

export const onCommentsChange = (dispatch) => (comments) =>
  dispatch({ type: PaymentFormActions.PAYMENT_COMMENTS_CHANGED, comments });

export const onPaymentFormsChanged = (dispatch) => (formIds) =>
  dispatch({ type: PaymentFormActions.PAYMENT_FORMS_CHANGED, formIds });

export const onErrorResolved = (dispatch) => () =>
  dispatch({ type: PaymentFormActions.PAYMENT_LOG_ERROR_RESOLVED });

export const changeRefundAmountAndTriggerExchangeReload =
  (dispatch) => (refundAmount, feeAmount) => {
    dispatch({ type: PaymentFormActions.REFUND_AMOUNT_CHANGED, refundAmount });
    dispatch({ type: PaymentFormActions.FEE_AMOUNT_CHANGED, feeAmount });
    dispatch({
      type: PaymentFormActions.EXCHANGE_NEEDS_RELOAD
    });
  };

export const resetRefundAndFinalAmount = (dispatch) => (amount) => {
  dispatch({
    type: PaymentFormActions.REFUND_AMOUNT_CHANGED,
    refundAmount: amount
  });
  dispatch({
    type: PaymentFormActions.FINAL_AMOUNT_CHANGED,
    finalAmount: amount
  });
};

// -----

export const onPaymentInformationNeedReload = (dispatch) => () =>
  dispatch({
    type: PaymentFormActions.PAYMENT_INFORMATION_NEEDS_RELOAD
  });

export const onExchangeNeedsReload = (dispatch) => () =>
  dispatch({
    type: PaymentFormActions.EXCHANGE_NEEDS_RELOAD
  });

const initialState = {
  needsReload: true,
  isLoading: false,

  exchangeNeedsReload: false,
  exchangeIsLoading: false,
  exchangeError: false,

  paymentInfoNeedsReload: false,
  paymentLogsNeedsReload: false,

  paymentLogs: List(),
  forms: List(),

  refundFormCurrencyCode: "",
  toCurrency: "",
  refundMethod: "",
  methodSpecificId: "",
  remittanceProvider: "",
  status: "",

  name: "",

  maskedCardNumber: "",
  bankName: "",
  bankBranchName: "",
  bankCity: "",
  bankProvince: "",
  accountNumber: "",
  sortCode: "",
  iban: "",
  bic: "",

  tripId: "",
  paymentId: "",
  completionDateInput: LocalDateTime.now()
    .toLocalDate()
    .format(WEVAT_DATE_FORMATTER),
  completionDate: LocalDateTime.now()
    .toLocalDate()
    .format(WEVAT_DATE_FORMATTER),
  refundFormAmount: 0,
  originalAmount: 0,
  finalAmount: 0,
  feeAmount: 0,
  refundAmount: 0,
  transactionId: "",
  comments: "",
  formIds: [],
  error: "",
  refundOption: {
  },
  totalPaid: 0,
  totalProcessing: 0
};

export const paymentForm = (state = initialState, action) => {
  switch (action.type) {
    case PaymentFormActions.PAYMENT_LOG_SUCCESS:
      return merge(state, { isLoading: false });

    case PaymentFormActions.PAYMENT_LOG_START:
      return merge(state, { isLoading: true });

    case UserDetailsAction.START_LOADING:
      return initialState;

    case TripActions.SELECT_TRIP:
      return onTripSelected(state, action.tripId);

    case PaymentFormActions.PAYMENT_DATE_CHANGED:
      return merge(state, { completionDateInput: action.completionDate });

    case PaymentFormActions.PAYMENT_METHOD_CHANGED:
      const methodBackendPayable = isBackendPayable(action.remittanceProvider);
      const newState = {
        remittanceProvider: action.remittanceProvider,
        finalAmount: methodBackendPayable ? state.finalAmount : 0,
        exchangeNeedsReload: methodBackendPayable
      };

      return merge(state, newState);

    case PaymentFormActions.ORIGINAL_AMOUNT_CHANGED:
      return merge(state, { originalAmount: action.originalAmount });

    case PaymentFormActions.FINAL_AMOUNT_CHANGED:
      return merge(state, { finalAmount: action.finalAmount });

    case PaymentFormActions.REFUND_AMOUNT_CHANGED:
      return merge(state, {
        refundAmount: action.refundAmount,
        formIds: initialState.formIds
      });

    case PaymentFormActions.FEE_AMOUNT_CHANGED:
      return merge(state, { feeAmount: action.feeAmount });

    case PaymentFormActions.TRANSACTION_ID_CHANGED:
      return merge(state, { transactionId: action.transactionId });

    case PaymentFormActions.PAYMENT_COMMENTS_CHANGED:
      return merge(state, { comments: action.comments });

    case PaymentFormActions.PAYMENT_FORMS_CHANGED:
      return merge(state, { formIds: action.formIds });

    case PaymentFormActions.PAYMENT_LOG_ERROR:
      return merge(state, { isLoading: false, error: action.errorMsg });

    case PaymentFormActions.PAYMENT_LOG_INVALID_OR_MISSING_INPUT:
      return merge(state, { isLoading: false, error: action.errorMsg });

    case PaymentFormActions.PAYMENT_LOG_ERROR_RESOLVED:
      return merge(state, { error: "" });

    case PaymentFormActions.CARD_NUMBER_REVEAL:
      return merge(state, { maskedCardNumber: action.cardNumber });

    // -----
    case PaymentFormActions.PAYMENT_INFORMATION_NEEDS_RELOAD:
      return merge(state, { paymentInfoNeedsReload: true });
    case PaymentFormActions.PAYMENT_INFORMATION_START_LOADING:
      return merge(state, {
        paymentInfoNeedsReload: false,
        paymentInfoIsLoading: true
      });
    case PaymentFormActions.PAYMENT_INFORMATION_ERROR_LOADING:
      return merge(state, {
        paymentInfoNeedsReload: false,
        paymentInfoIsLoading: false,
        paymentInfoError: action.error
      });
    case PaymentFormActions.PAYMENT_INFORMATION_FINISHED_LOADING:
      return onUserPaymentInformationLoaded(state, action.information);

    // ----
    case PaymentFormActions.PAYMENT_LOGS_NEEDS_RELOAD:
      return merge(state, { paymentLogsNeedsReload: true });
    case PaymentFormActions.PAYMENT_LOGS_START_LOADING:
      return merge(state, {
        paymentLogsNeedsReload: false,
        paymentLogsIsLoading: true
      });
    case PaymentFormActions.PAYMENT_LOGS_ERROR_LOADING:
      return merge(state, {
        paymentLogsNeedsReload: false,
        paymentLogsIsLoading: false,
        paymentInfoError: action.error
      });
    case PaymentFormActions.PAYMENT_LOGS_FINISHED_LOADING:
      return onTripPaymentLogsLoaded(state, action.information);

    // ---- exchange related
    case PaymentFormActions.EXCHANGE_NEEDS_RELOAD:
      return merge(state, { exchangeNeedsReload: true });
    case PaymentFormActions.EXCHANGE_START_LOADING:
      return merge(state, {
        exchangeNeedsReload: false,
        exchangeIsLoading: true
      });
    case PaymentFormActions.EXCHANGE_ERROR_LOADING:
      return merge(state, { exchangeError: true, exchangeIsLoading: false });
    case PaymentFormActions.EXCHANGE_FINISHED_LOADING:
      return merge(state, {
        exchangeIsLoading: false,
        finalAmount: BACKEND_PAYABLE_METHODS.includes(state.remittanceProvider)
          ? action.targetAmount
          : 0
      });
    default:
      return state;
  }
};

const onUserPaymentInformationLoaded = (state, information) => {
  const { paymentInfo, refundFormCurrencyCode, refundFormAmount, hasAppointment, appointment, refundOption } = information;
  if (paymentInfo) {
    let refundMethod = paymentInfo.method;
    if (refundMethod === "UNIONPAY") {
      if (paymentInfo.bankName && paymentInfo.bankName !== "") {
        refundMethod = "WYRE";
      } else {
        refundMethod = "TRANSFERWISE";
      }
    }

    const remittanceProvider = getRemittanceProvider(paymentInfo);

    state = merge(state, {
      paymentInfoNeedsReload: false,
      paymentInfoIsLoading: false,
      exchangeNeedsReload: true,
      hasTravelexAppointment: hasAppointment,
      travelexAppointment: appointment,
      refundFormCurrencyCode: refundFormCurrencyCode,
      refundFormAmount: refundFormAmount,
      toCurrency: paymentInfo.currency,
      refundMethod: refundMethod,
      remittanceProvider: remittanceProvider,
      name: paymentInfo.name,
      methodSpecificId: paymentInfo.accountId,
      maskedCardNumber: paymentInfo.maskedCardNumber,
      bankName: paymentInfo.bankName,
      bankBranchName: paymentInfo.bankBranchName,
      bankCity: paymentInfo.bankCity,
      bankProvince: paymentInfo.bankProvince,
      accountNumber: paymentInfo.accountNumber,
      sortCode: paymentInfo.sortCode,
      iban: paymentInfo.iban,
      bic: paymentInfo.bic,
      refundOption: refundOption,
    });
  }

  return merge(
    initialState,
    merge(state, {
      paymentInfoIsLoading: false
    })
  );
};

const onTripPaymentLogsLoaded = (state, payments) => {
  const changedState = {};

  if (state.paymentLogs.length > 0) {
    const payment = state.paymentLogs[0];
    let paymentCompletedDate = payment.paidAt;
    if (paymentCompletedDate && paymentCompletedDate !== "") {
      paymentCompletedDate = LocalDate.ofInstant(
        Instant.ofEpochMilli(paymentCompletedDate)
      );
    } else {
      paymentCompletedDate = LocalDateTime.now().toLocalDate();
    }
    paymentCompletedDate = paymentCompletedDate.format(WEVAT_DATE_FORMATTER);

    // changedState.tripId = payment.id;
    changedState.completionDateInput = paymentCompletedDate;
    changedState.completionDate = paymentCompletedDate;
    changedState.transactionId = payment.transactionId || "";
  }

  return {
    ...state,
    ...changedState,

    paymentLogsIsLoading: false,
    paymentLogsNeedsReload: false,

    paymentLogs: listify(payments.payments),
    totalPaid: payments.totalPaid,
    totalProcessing: payments.totalProcessing,

    status:
      payments.totalPaid > 0 && payments.totalProcessing === 0
        ? FORM_PAYMENT_STATUSES.PAID
        : payments.totalPaid === 0 &&
          payments.totalProcessing === 0 &&
          payments.payments.length > 0
        ? FORM_PAYMENT_STATUSES.ERROR
        : payments.payments.length === 0
        ? FORM_PAYMENT_STATUSES.PENDING
        : FORM_PAYMENT_STATUSES.PROCESSING
  };
};

const onTripSelected = (state, tripId) => {
  state = merge(state, {
    paymentInfoIsLoading: false,
    paymentInfoNeedsReload: true,
    paymentLogsIsLoading: false,
    paymentLogsNeedsReload: true,
    tripId: tripId
  });

  return state;
};
