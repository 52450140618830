import React, { FC } from "react";

import IsOpenIcon from "@assets/images/isOpen.svg";
import { Grey } from "@components/ColoredText";
import Money from "@components/Money";
import {
  TableBodyRow,
  TableBodyCell
} from "@components/WeVatTableV2";
import ReceiptStatus from "@components/ReceiptStatus";
import LeavingDateView from "@components/LeavingDateView";
import AddedDate from "@components/AddedDate";

import { ReceiptCell } from "@store/types";
import { selectRejectionReasons } from "@store/selectors";
import { useAppSelector } from "@src/hooks";
import { getStatusLabel, getStatusTheme } from "@utils/receipts";

type Props = {
  index: number;
  onClick: (receiptId: string, tripId: string) => void;
  receipt: ReceiptCell;
};

const ReceiptRow: FC<Props> = ({ receipt, onClick, index }) => {
  const reasons = useAppSelector(selectRejectionReasons);
  const { rejectionReasons = [] } = receipt;

  return (
    <TableBodyRow
      index={index}
      gridTemplateColumns="30px 85px 2fr 1fr 1fr 1fr 80px 1fr"
      onClick={() => onClick(receipt.receiptId, receipt.trip.id)}
    >
      <TableBodyCell alignCenter={true}>
        {receipt.isOpen && <img src={IsOpenIcon} alt="isOpen" width="20px" />}
      </TableBodyCell>
      <TableBodyCell>
        <ReceiptStatus
          theme={getStatusTheme(receipt.status)}
          label={getStatusLabel(receipt.status, rejectionReasons, reasons)}
        />
      </TableBodyCell>
      <TableBodyCell>
        {receipt?.retailer && receipt.retailer?.id ? (
          receipt.retailer.name || <Grey>&lt;empty retailer name&gt;</Grey>
        ) : (
          <Grey>pending...</Grey>
        )}
      </TableBodyCell>
      <TableBodyCell>
        <LeavingDateView
          date={(() => {
            const date = receipt.trip.departureDate || "";
            return date.split(date.includes("T") ? "T" : " ")[0];
          })()}
        />
      </TableBodyCell>
      <TableBodyCell>
        <AddedDate
          defaultFormat="yyyy-MM-dd hh:mm"
          date={receipt.uploaded.replace(/T/, " ")}
        />
      </TableBodyCell>
      <TableBodyCell>
        {receipt?.lastVerifiedAt && (
          <AddedDate
            defaultFormat="yyyy-MM-dd"
            date={receipt.lastVerifiedAt.replace(/T/, " ")}
          />
        )}
      </TableBodyCell>
      <TableBodyCell alignCenter={true}>{receipt.itemsCount}</TableBodyCell>
      <TableBodyCell alignRight={true} className="pad-right-30">
        <Money amount={receipt.total} currency="EUR" />
      </TableBodyCell>
    </TableBodyRow>
  );
};

export default ReceiptRow;
