import { wevat } from "../http-client";
import { withEmptyMock, withMock } from "../../utils/prod-test";
import { withAuthHeaderIfSet } from "../tokens";
import { WEVAT_GATEWAY } from "../../config";

export function addReceiptToTrip(tripId, url) {
  return withMock(
    function () {
      const body = {
        tripId,
        imageUrls: [url]
      };
      return wevat({ adminApi: false }).post(
        "/adminCreateReceiptForUser",
        body
      );
    },
    () => Promise.resolve()
  )();
}
export function uploadReceipt(image) {
  return withMock(function () {
    const config = {
      method: "POST",
      headers: withAuthHeaderIfSet(new Headers()),
      body: imageFormData(image)
    };

    return fetch(WEVAT_GATEWAY + "/api/v1/files/", config)
      .then((res) => res.json())
      .then((res) => {
        if (!res.url) {
          throw new Error();
        }
        return res.url;
      });
  }, mockUpload)();
}

function mockUpload() {
  return Promise.resolve("http://some-url.com");
}

function imageFormData(image) {
  const fd = new FormData();
  fd.append("file", image);
  fd.append("type", "receipts");
  return fd;
}

export function deleteReceipt() {
  return withEmptyMock(function () {
    return Promise.resolve();
  });
}
