import React, { FC } from "react";
import ArrowButton from "../ArrowButton";

import "./paging-arrows.scss";

type Props = {
  onNext: () => void;
  onPrevious: () => void;
  numberOfPages: number;
  page: number;
};

const PagingArrows: FC<Props> = ({
  page,
  onPrevious,
  numberOfPages,
  onNext
}) => (
  <div className="paging-arrow-container">
    <ArrowButton direction="left" enabled={page > 1} onClick={onPrevious} />
    <p>
      Page {page} of {numberOfPages}
    </p>
    <ArrowButton
      direction="right"
      enabled={page < numberOfPages}
      onClick={onNext}
    />
  </div>
);

export default PagingArrows;
