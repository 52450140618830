import React, { FC } from "react";

import Button from "@components/Button";
import { updateState, updateReceipt } from "@store/actions";
import {
  selectIsFirstStep,
  selectIsReverify,
  selectIndexData,
  selectIsReceiptLoading,
  selectIsReceiptVerification,
  selectIsMandatoryFieldsValid
} from "@store/selectors";
import { useAppSelector, useAppDispatch } from "@src/hooks";

interface ReceiptHeaderProps {
  className?: string;
  receiptSize: number;
  onClickSkip?: () => void;
  onClickDone: () => void;
  onClickPrevious?: () => void;
}

const ReceiptHeader: FC<ReceiptHeaderProps> = ({
  className,
  receiptSize,
  onClickSkip,
  onClickDone,
  onClickPrevious
}) => {
  const dispatch = useAppDispatch();
  const isFirstStep = useAppSelector(selectIsFirstStep);
  const isReverify = useAppSelector(selectIsReverify);
  const isLoading = useAppSelector(selectIsReceiptLoading);
  const { currentIndex, isLastIndex } = useAppSelector(selectIndexData);
  const isReceiptVerification = useAppSelector(selectIsReceiptVerification);
  const isMandatoryFieldsValid = useAppSelector(selectIsMandatoryFieldsValid);

  const disableButtons = isReverify && isFirstStep;
  const isFirstStepAndNotVerified = isFirstStep && !isReceiptVerification;

  const handleHeaderButtonLeft = () => {
    if (isFirstStep) {
      onClickPrevious();
    } else {
      dispatch(updateState({ isFirstStep: true }));
    }
  };

  const shouldDisablePreviousBtn = () => {
    const hasPreviousReceipts =
      isFirstStepAndNotVerified && receiptSize > 0 && currentIndex === 0;

    return disableButtons || isLoading || hasPreviousReceipts ? true : false;
  };

  const shouldDisableNextBtn = () => {
    if (disableButtons || isLoading) {
      return true;
    }

    if (
      (!isReceiptVerification && isFirstStepAndNotVerified && isLastIndex) ||
      (!isFirstStep && !isMandatoryFieldsValid)
    ) {
      return true;
    }

    return false;
  };

  const handleDone = () => {
    if (!isReceiptVerification) {
      dispatch(updateReceipt({ rejectionReasons: [] }));
    }

    if (isReceiptVerification || (!isFirstStep && isMandatoryFieldsValid)) {
      onClickDone();
    }
  };

  const handleSkip = () => {
    onClickSkip?.();
  };

  return (
    <div className={className}>
      {!isReceiptVerification && onClickPrevious && (
        <Button
          type="primary-outline"
          text={isFirstStep ? "Previous" : "Back"}
          onClick={handleHeaderButtonLeft}
          disabled={shouldDisablePreviousBtn()}
          size="big"
        />
      )}
      <Button
        type="primary"
        text={isFirstStepAndNotVerified ? "Skip" : "Done"}
        onClick={isFirstStepAndNotVerified ? handleSkip : handleDone}
        disabled={shouldDisableNextBtn()}
        size="big"
      />
    </div>
  );
};

export default ReceiptHeader;
