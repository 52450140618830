import React from "react";
import { shallowEqual } from "react-redux";
import ReactFlagsSelect from "react-flags-select";

import WevatModal from "@components/modals/WevatModal";
import DropdownV2 from "@components/DropdownV2";
import Button from "@components/Button";
import { eventData } from "@utils/dom-functions";
import {
  onNameChanged,
  onCountryChanged,
  onAddressLineChanged,
  onAddressPostalCodeChanged,
  onAddressCityChanged,
  onVatnumberChanged,
  onSirenChanged,
  onSiretChanged,
  onDefaultCategoryChanged,
  onOcrItemExtractionChanged,
  onItemsWithVatChanged,
  stopEditRetailer,
  editRetailer,
  onIsBrandRepresentativeChanged,
} from "@store/reducers/edit-retailer-reducer";
import { useAppSelector, useAppDispatch } from "@src/hooks";
import { SUPPORTED_COUNTRIES } from "@src/utils/trips";

import "../css/edit-retailer-modal.scss";

export const SUPPORTED_CATEGORIES = {
  "55": "55 - Cultural and artisanal goods",
  "99": "99 - Other",
  "56": "56 - Sport and leisure",
  "57": "57 - Electrical appliances",
  "58": "58 - Home and decoration",
  "59": "59 - Digital and electronic goods",
  "60": "60 - Fashion and accessories",
  "61": "61 - Perfume",
  "62": "62 - Watches",
  "53": "53 - Food",
  "54": "54 - Alcohol and tabacco"
}

export const ITEMS_WITH_VAT_OPTIONS = {
  GROSS: "Gross Price (TTC)",
  NET: "Net Price",
}

export const ITEMS_EXTRACTION_OPTIONS = {
  YES: "Yes",
  NO: "No",
}

export const IS_BRAND_REPRESENTATIVE_OPTIONS = {
  YES: "Yes",
  NO: "No",
}


const EditRetailerModal = () => {
  const formRef = React.useRef(null);

  const dispatch = useAppDispatch();

  const {
    showRetailerEdit = false,
    retailerId,
    vatnumber,
    retailerName,
    country,
    addressLine,
    addressPostalCode,
    addressCity,
    siret,
    siren,
    itemsWithVat,
    defaultCategory,
    ocrItemExtraction,
    isBrandRepresentative,
  } = useAppSelector(mapStateToProps, shallowEqual);

  const onHide = eventData(stopEditRetailer(dispatch));
  const onNameChangedDispatch = eventData(onNameChanged(dispatch));
  const onCountryChangedDispatch = onCountryChanged(dispatch);
  const onAddressLineChangedDispatch = eventData(onAddressLineChanged(dispatch));
  const onAddressPostalCodeChangedDispatch = eventData(onAddressPostalCodeChanged(dispatch));
  const onAddressCityChangedDispatch = eventData(onAddressCityChanged(dispatch));
  const onVatnumberChangedDispatch = eventData(onVatnumberChanged(dispatch));
  const onSirenChangedDispatch = eventData(onSirenChanged(dispatch));
  const onSiretChangedDispatch = eventData(onSiretChanged(dispatch));
  const onDefaultCategoryChangedDispatch = onDefaultCategoryChanged(dispatch);
  const onOcrItemExtractionChangedDispatch = onOcrItemExtractionChanged(dispatch);
  const onItemsWithVatChangedDispatch = onItemsWithVatChanged(dispatch);
  const onIsBrandRepresentativeChangedDispatch = onIsBrandRepresentativeChanged(dispatch);


  const editRetailerDispatch = editRetailer(dispatch);
  const [disableAddRetailerButtons, setDisable] = React.useState(false);
  
  
  const generateCategoryOptions = () => {
    return Object.entries(SUPPORTED_CATEGORIES).map(([value, label]) => ({
      label,
      value
    }));
  };

  const generateItemsWithVatOptions = () => {
    return Object.entries(ITEMS_WITH_VAT_OPTIONS).map(([value, label]) => ({
      label,
      value
    }));
  };

  const generateItemsExtractionOptions = () => {
    return Object.entries(ITEMS_EXTRACTION_OPTIONS).map(([value, label]) => ({
      label,
      value
    }));
  };

  const generateIsBrandRepresentativeOptions = () => {
    return Object.entries(IS_BRAND_REPRESENTATIVE_OPTIONS).map(([value, label]) => ({
      label,
      value
    }));
  };

  return (
    <WevatModal
      type="medium"
      height="700"
      show={showRetailerEdit}
      onHide={onHide}
    >

          <form
            ref={formRef}
            onSubmit={(e) => {
              e.preventDefault();
              setDisable(true);

              handleEditRetailer({
                editRetailer: editRetailerDispatch,
                retailerId,
                retailerName,
                country,
                addressLine,
                addressPostalCode,
                addressCity,
                vatnumber,
                siren,
                siret,
                itemsWithVat,
                defaultCategory,
                ocrItemExtraction,
                isBrandRepresentative,
              });

              return false;
            }}
          >
            <div className="edit-retailer-view">
              <h2>Edit retailer</h2>

              <div className="field-container">
                <label>Representative Shop</label>
                <DropdownV2
                  id="isBrandRepresentative"
                  hideClear={true}
                  isSearchable={false}
                  value={isBrandRepresentative == null ? "NO" : isBrandRepresentative ? "YES" : "NO"}
                  options={generateIsBrandRepresentativeOptions()}
                  placeholder="Select yes or no"
                  onChange={onIsBrandRepresentativeChangedDispatch}
                />
              </div>

              <div className="field-container">
                <label>Retailer name</label>
                <input
                  type="text"
                  defaultValue={retailerName}
                  onChange={onNameChangedDispatch}
                  maxLength={45}
                  minLength={3}
                />
              </div>

              <div className="field-container">
                <label>Default Category</label>
                <DropdownV2
                  id="categoryCode"
                  hideClear={true}
                  isSearchable={true}
                  value={defaultCategory == null ? "60" : defaultCategory}
                  options={generateCategoryOptions()}
                  placeholder="Select Category"
                  onChange={onDefaultCategoryChangedDispatch}
                />
              </div>

              <div className="field-container">
                <label>Country</label>
                <ReactFlagsSelect
                  searchable={true}
                  selected={country}
                  onSelect={onCountryChangedDispatch}
                  countries={SUPPORTED_COUNTRIES}
                />
              </div>

              <div className="field-container">
                <label>Address line 1</label>
                <input
                  type="text"
                  defaultValue={addressLine}
                  onChange={onAddressLineChangedDispatch}
                  maxLength={100}
                  minLength={3}
                />
              </div>

              <div className="field-container">
                <label>Post code</label>
                <input
                  type="text"
                  defaultValue={addressPostalCode}
                  onChange={onAddressPostalCodeChangedDispatch}
                  maxLength={10}
                  minLength={3}
                />
              </div>

              <div className="field-container">
                <label>City</label>
                <input
                  type="text"
                  defaultValue={addressCity}
                  onChange={onAddressCityChangedDispatch}
                  maxLength={50}
                  minLength={3}
                />
              </div>

              <div className="field-container">
                <label>Default gross / net price</label>
                <DropdownV2
                  id="itemsWithVat"
                  hideClear={true}
                  isSearchable={true}
                  value={itemsWithVat == null ? "GROSS" : itemsWithVat ? "GROSS" : "NET"}
                  options={generateItemsWithVatOptions()}
                  placeholder="Select gross or net price"
                  onChange={onItemsWithVatChangedDispatch}
                />
              </div>

              <div className="field-container">
                <label>TVA no.</label>
                <input
                  type="text"
                  className="vat"
                  defaultValue={vatnumber}
                  onChange={onVatnumberChangedDispatch}
                  pattern="\d{3,11}"
                  maxLength={11}
                  minLength={3}
                />
                <div className="vatCountryCode">{country}</div>
              </div>

              <div className="field-container">
                <label>SIREN no.</label>
                <input
                  type="text"
                  defaultValue={siren}
                  onChange={onSirenChangedDispatch}
                  placeholder="(Optional) e.g. 542 019 237"
                />
              </div>

              <div className="field-container">
                <label>SIRET no.</label>
                <input
                  type="text"
                  defaultValue={siret}
                  onChange={onSiretChangedDispatch}
                  placeholder="(Optional) e.g. 542 019 237 00289"
                />
              </div>

              <div className="field-container">
                <label>Enable OCR for items</label>
                <DropdownV2
                  id="ocrItemExtraction"
                  hideClear={true}
                  isSearchable={false}
                  value={ocrItemExtraction == null ? "YES" : ocrItemExtraction ? "YES" : "NO"}
                  options={generateItemsExtractionOptions()}
                  placeholder="Select yes or no"
                  onChange={onOcrItemExtractionChangedDispatch}
                />
              </div>

              <div className="update-button-container">
                <Button
                  text={"Save"}
                  nativetype="submit"
                  disabled={!formRef.current?.checkValidity() && !disableAddRetailerButtons}
                />
              </div>
            </div>
          </form>
    </WevatModal>
  );


};

const handleEditRetailer = ({
  editRetailer,
  retailerId,
  retailerName,
  country,
  addressLine,
  addressPostalCode,
  addressCity,
  vatnumber,
  siren,
  siret,
  itemsWithVat,
  defaultCategory,
  ocrItemExtraction,
  isBrandRepresentative,
}) => {

  return editRetailer({
    retailerId,
    retailerName,
    country,
    addressLine,
    addressPostalCode,
    addressCity,
    vatnumber,
    siren,
    siret,
    itemsWithVat,
    defaultCategory,
    ocrItemExtraction,
    isBrandRepresentative,
  })
};



export default EditRetailerModal;

const mapStateToProps = (state) => {
  return {
    ...state.editRetailerModal,
  };
};