import React from "react";
import { connect } from "react-redux";
import RetailersTable from "./view/RetailersTable";
import RetailersTableBar from "./view/RetailersTableBar";
import { fetchRetailers } from "@store/actions/retailers-table-async-actions";
import AnimatedDivList from "@utils/AnimatedDivList";
import EditRetailer from "./view/EditRetailer";

const mapStateToProps = (state) => {
  return {
    ...state.retailersTable,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchRetailers: fetchRetailers(dispatch)
  };
};

class RetailersTableComponent extends React.Component {
  componentWillMount() {
    this.fetchRetailers(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.needsLoad && !this.props.isLoading) {
      this.fetchRetailers(nextProps);
    }
  }

  fetchRetailers = (props) => {

    const sort = {
      direction: props.sort.direction, 
      by: props.sort.by
    }
    
    const pagination = {
      itemsLimit: 50,
      current: props.page - 1
    }
    
    const query = {
      query: props.searchText,
      retrieveAll: false,
      sort: sort,
      pagination: pagination,
    }

    this.props.fetchRetailers({
      ...query
    });
  };

  render() {
    return (
      <AnimatedDivList>
        <EditRetailer/>
        <RetailersTableBar/>
        <RetailersTable/>
      </AnimatedDivList>
    );
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(RetailersTableComponent);
