import React, { FC } from "react";
import { selectAllOnFocus } from "../utils/dom-functions";

import "./css/numeric-input.css";

type Props = {
  value: number | string;
  min?: number;
  max?: number;
  disabled?: boolean;
  alignment?: "left" | "center" | "right";
  onChange: (value: string) => void;
  onBlur?: (value: string) => void;
  onFocus?: () => void;
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  style?: React.CSSProperties;
  className?: string;
};

const NumericInput: FC<Props> = ({
  value,
  disabled,
  onChange,
  alignment = "left",
  style,
  children,
  min,
  max,
  onFocus,
  onBlur,
  className,
  ...inputProps
}) => {
  return (
    <div className="numeric-input" style={style}>
      <input
        {...inputProps}
        type="number"
        value={value}
        className={`alignment-${alignment} ${className || ""}`}
        disabled={disabled}
        onChange={(e) => onChange(e.target.value)}
        onBlur={(e) => onBlur?.(e.target.value)}
        onFocus={(e) => {
          selectAllOnFocus(e);
          onFocus?.();
        }}
        min={min}
        max={max}
      />
      {children}
    </div>
  );
};

export default NumericInput;
