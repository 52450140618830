import { List } from "immutable";
import { FilterActions, UserAction } from "../actions/user-page-actions";
import { LoginProvider } from "../../components/model/login-provider";
import { TripStatus } from "../../constants";
import { compose, merge, mergeLeft } from "../../utils/functions";
import { ReceiptViewActions } from "../actions/receipt-view-actions";

import { setUserLoadError } from "../global";

const initialState = {
  users: List(),
  signupMethod: LoginProvider.ALL,
  tripStatus: TripStatus.STARTED,
  newOnly: true,
  applyButtonEnabled: false,
  minimumCount: 1,
  minimumCountEnabled: false,
  searchText: "",
  needsLoad: true,
  page: 1,
  maxPages: 1,
  showUserProfileModal: false,
  selectedUserProfile: undefined,
  isLoading: false
};

export const userSummary = (state = initialState, action) => {
  switch (action.type) {
    case UserAction.USERS_LOADED:
      return onUsersLoaded(state, action);

    case setUserLoadError.type:
      return merge(state, { isLoading: false });

    case UserAction.OPEN_USER_PROFILE:
      return merge(state, {
        showUserProfileModal: true,
        selectedUserProfile: action.userId
      });

    case UserAction.HIDE_USER_PROFILE:
      return merge(state, {
        showUserProfileModal: false,
        selectedUserProfile: undefined
      });

    case FilterActions.SIGNUP_METHOD_CHANGED:
      return compose(
        mergeLeft.bind(null, { signupMethod: action.method }),
        enableApplyButton
      )(state);

    case FilterActions.TRIP_STATUS_CHANGED:
      return compose(
        mergeLeft.bind(null, { tripStatus: action.tripStatus }),
        enableApplyButton
      )(state);

    case FilterActions.TOGGLE_NEW_ONLY:
      let st = { newOnly: !state.newOnly };
      if (st.newOnly) {
        st = merge(st, { tripStatus: TripStatus.ALL });
      }
      return compose(mergeLeft.bind(null, st), enableApplyButton)(state);

    case FilterActions.RESET_FILTER_OPTIONS:
      return initialState;

    case FilterActions.APPLY_FILTER:
      return reload(state);

    case FilterActions.MINIMUM_COUNT_CHANGED:
      return enableApplyButtonIf(
        state.minimumCountEnabled,
        merge(state, { minimumCount: action.minimumCount })
      );

    case FilterActions.MINIMUM_COUNT_TOGGLED:
      return compose(
        mergeLeft.bind(null, {
          minimumCountEnabled: !state.minimumCountEnabled
        }),
        enableApplyButton
      )(state);

    case FilterActions.SEARCH_INPUT_CHANGED:
      return onSearchInputChanged(state, action.searchText);

    case FilterActions.SUBMIT_SEARCH:
      return reload(state);

    case FilterActions.NEXT_PAGE:
      return compose(onNextPage, reload)(state);

    case FilterActions.PREVIOUS_PAGE:
      return compose(onPreviousPage, reload)(state);

    case UserAction.START_LOADING:
      return merge(state, { isLoading: true, needsLoad: false });

    case ReceiptViewActions.RECEIPT_UPDATE_SUCCESS:
      return reload(state);

    default:
      return state;
  }
};

function onUsersLoaded(state, action) {
  return merge(state, {
    users: List(action.body.trips),
    maxPages: action.body.max_pages || 1,
    needsLoad: false,
    isLoading: false,
    applyButtonEnabled: false
  });
}

function enableApplyButton(state) {
  return merge(state, { applyButtonEnabled: true, page: 1 });
}

function enableApplyButtonIf(condition, state) {
  if (condition) {
    return enableApplyButton(state);
  }
  return state;
}

function onSearchInputChanged(state, searchText) {
  const needsLoad = searchText === "";
  if (!needsLoad) {
    return merge(state, {
      searchText,
      signupMethod: LoginProvider.ALL,
      tripStatus: TripStatus.ALL,
      newOnly: false,
      applyButtonEnabled: true,
      needsLoad
    });
  }
  return initialState;
}

function reload(state) {
  return merge(state, { needsLoad: true });
}

function onNextPage(state) {
  if (state.page === state.maxPages) {
    return state;
  }

  return merge(state, { page: state.page + 1 });
}

function onPreviousPage(state) {
  if (state.page === 1) {
    return state;
  }

  return merge(state, { page: state.page - 1 });
}
