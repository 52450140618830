import { takeLatest, call, put } from "redux-saga/effects";

import * as api from "@src/api";
import { hasToken } from "@api/tokens";
import * as actions from "@store/actions";

export function* globalInit() {
  yield call(handleGetRetailers);
  yield call(handleGetRejectionReasons);

  yield takeLatest(actions.getRetailers.type, handleGetRetailers);
  yield takeLatest(actions.getRejectionReasons.type, handleGetRejectionReasons);
  yield takeLatest(actions.getReviewers.type, handleGetReviewers);
}

export function* handleGetRetailers(action?: any) {
  const isSignedIn = yield call(hasToken);

  if (isSignedIn) {
    const retailerId = action?.payload?.retailerId;

    const retailersList = yield call(api.getRetailers);

    yield put(
      actions.setRetailers(sortListAlphabetically(retailersList.retailers, "retailerName"))
    );

    if (retailerId) {
      yield put(actions.setRetailerId(retailerId));
    }
  }
}

export function* handleGetRejectionReasons() {
  const isSignedIn = yield call(hasToken);

  if (isSignedIn) {
    const rejectionReasons = yield call(api.getRejectionReasons);

    const filteredReasons = rejectionReasons
      .filter(({ countries }) => countries.includes("FR"))
      .sort((l, r) => l.priority - r.priority);

    yield put(actions.setRejectionReasons(filteredReasons));
  }
}

export function* handleGetReviewers() {
  const reviewers = yield call(api.getReviewers);
  yield put(actions.setReviewers(sortListAlphabetically(reviewers, "email")));
}

function sortListAlphabetically(list: any[], field: string) {
  return [...list].sort((a, b) => {
    const left = a[field].toLowerCase();
    const right = b[field].toLowerCase();
    if (left < right) return -1;
    if (left > right) return 1;
    return 0;
  });
}
