import { Tabs } from "../../constants";

export const MainPageActions = {
  CHANGE_TAB: "CHANGE_TAB"
};

const initialState = {
  currentTab: Tabs.NEW_RECEIPTS
};

export const changeTab = dispatch => tab =>
  dispatch({ type: MainPageActions.CHANGE_TAB, tab });

export const main = (state = initialState, action) => {
  switch (action.type) {
    case MainPageActions.CHANGE_TAB:
      return { currentTab: action.tab };

    default:
      return state;
  }
};
