import React, { useEffect, useState } from "react";
import { List } from "immutable";
import Toggle from "react-toggle";
import { useHistory } from "react-router-dom";

import ReceiptTable from "./ReceiptTable";
import AddReceipt from "./AddReceipt";
import SearchTextbox from "@components/SearchTextbox";
import TripAndTotal from "../../user-profile/TripAndTotal";
import { store, view } from "react-easy-state";
import { fetchTripReceipts } from "@api/receipts";
import { numberify } from "@utils/numbers";
import { setReceiptViewList } from "@store/actions";
import { useAppDispatch } from "@src/hooks";

import "../css/receipt-details.scss";
import "react-toggle/style.css";

import AnimatedUserSummaryContainer from "@utils/AnimatedUserSummaryContainer";

export const UserReceiptsStore = store({
  isLoading: false,
  receipts: [],
  nonDeletedReceipts: [],
  showDeleted: false,
  async refreshReceipts(tripId, sortBy, sortDirection) {
    UserReceiptsStore.isLoading = true;

    const receipts = await fetchTripReceipts({ tripId, sortBy, sortDirection });
    UserReceiptsStore.receipts = receipts.map((r, i, array) => {
      // no need to worry about indexOutBoundsException
      r.prev = array[i - 1];
      r.next = array[i + 1];

      if (!r.total) {
        r.total = r.items.reduce(
          (acc, item) => acc + numberify(item.cost || 0),
          0
        );
      }

      return r;
    });
    UserReceiptsStore.nonDeletedReceipts = UserReceiptsStore.receipts.filter(
      (r) => r.status !== "DELETED"
    );

    UserReceiptsStore.isLoading = false;
  },
  toggleShowDeleted() {
    UserReceiptsStore.showDeleted = !UserReceiptsStore.showDeleted;
  }
});

const ReceiptDetailsView = view((props) => {
  const dispatch = useAppDispatch();

  const history = useHistory();
  const [receiptId, setReceiptId] = useState("");
  const receipts = UserReceiptsStore.showDeleted
    ? UserReceiptsStore.receipts
    : UserReceiptsStore.nonDeletedReceipts;

  const renderToggle = () => (
    <div className="deleted-receipts-toggle-container">
      <label htmlFor="deleted-receipts-toggle">
        {UserReceiptsStore.showDeleted ? "Hide" : "Show"}
        {` ${
          UserReceiptsStore.receipts.length -
          UserReceiptsStore.nonDeletedReceipts.length
        } `}
        deleted receipts&nbsp;
      </label>
      <Toggle
        id="deleted-receipts-toggle"
        className="custom-wevat-classname"
        onChange={UserReceiptsStore.toggleShowDeleted}
        disabled={
          UserReceiptsStore.receipts.length ===
          UserReceiptsStore.nonDeletedReceipts.length
        }
      />
    </div>
  );

  useEffect(() => {
    document.body.className = "no-scroll";

    return () => {
      document.body.className = "";
    };
  }, []);

  return (
    <React.Fragment>
      <AddReceipt />

      <AnimatedUserSummaryContainer className="receipts-details-wrap">
        <div className="receipts-details">
          <div className="receipts-details__header">
            <div className="receipts-details__header-left">
              <TripAndTotal dropdownOnly={true} />
            </div>

            <div className="receipts-details__header-right">
              {renderToggle()}

              <SearchTextbox
                hint="Search receiptID"
                onSubmit={() => {
                  if (receiptId) {
                    const list = receipts
                      .concat()
                      .map(({ prev, next, ...rest }) => ({
                        ...rest
                      }));

                    dispatch(setReceiptViewList(list));
                    history.push(`/invoices/${receiptId}`, {
                      fromView: "/trips",
                      goBack: true
                    });
                  }
                }}
                onChange={setReceiptId}
                value={receiptId}
              />
            </div>
          </div>

          <ReceiptTable
            isLoading={UserReceiptsStore.isLoading}
            onAddReceipt={props.openAddReceipt}
            fetchReceipts={UserReceiptsStore.refreshReceipts}
            receipts={receipts}
            onTabRefreshed={props.onTabRefreshed}
            deleteReceipt={props.deleteReceipt}
          />
        </div>
      </AnimatedUserSummaryContainer>
    </React.Fragment>
  );
});

ReceiptDetailsView.defaultProps = {
  receipts: List()
};

export default view(ReceiptDetailsView);
