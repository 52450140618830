export const stringManipulation = str => {
  return (str || "").trim().toLowerCase();
};

export function capitaliseFirst(word) {
  if (!word) return "";
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(); 
  
}

export function stringify(val) {
  return "" + val;
}
