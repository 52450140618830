import { LocalDate } from "js-joda";
import { wevat } from "../http-client";
import { withEmptyMock } from "../../utils/prod-test";
import { validDate } from "../../utils/dates";

export function updateTripDetails(tripId, update) {
  validate(tripId, update);

  return withEmptyMock(() => wevat().patch(`/trips/${tripId}`, update));
}

export async function getTripComments(tripId) {
  const response = await wevat().get(`/trips/${tripId}`);

  return response.comments;
}

export async function saveTripComments(tripId, comments) {
  console.log({ comments });
  return wevat().patch(`/trips/${tripId}`, { comments });
}

function validate(tripId, update) {
  if (!tripId) {
    throw new Error("tripId is missing");
  }

  if (!validDate(update.arrivalDate) || !validDate(update.departureDate)) {
    throw new Error("arrivalDate and departureDate are both required");
  }

  if (
    LocalDate.parse(update.arrivalDate).isAfter(
      LocalDate.parse(update.departureDate)
    )
  ) {
    throw new Error("Arrival date cannot be after departureDate");
  }
}
