import React, { FC } from "react";

import MagnifyingGlass from "@assets/images/magnifying-glass.png";
import { keyPress } from "@utils/dom-functions";
import TextInput from "../TextInput";

import "./search-textbox.scss";

interface SearchTextboxProps {
  value: string;
  hint: string;
  onChange: (value: string) => void;
  onSubmit: () => void;
}

const SearchTextbox: FC<SearchTextboxProps> = ({
  onChange,
  onSubmit,
  value = "",
  hint = ""
}) => (
  <div className="search-box">
    <TextInput
      placeholder={hint}
      onChange={onChange}
      onKeyPress={keyPress(onSubmit, 13)}
      value={value}
    >
      <img
        src={MagnifyingGlass}
        onClick={onSubmit}
        alt="Search"
        className="search-box__icon"
      />
    </TextInput>
  </div>
);

export default SearchTextbox;
