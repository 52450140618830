import { merge } from "../../utils/functions";

export const AddTrip = {
  OPEN_ADD_TRIP: "OPEN_ADD_RECEIPT",
  CLOSE_ADD_TRIP: "CLOSE_ADD_RECEIPT",
  ARRIVAL_DATE_CHANGE: "USER_ADD_TRIP_ARRIVAL_DATE_CHANGE",
  DEPARTURE_DATE_CHANGE: "USER_ADD_TRIP_DEPARTURE_DATE_CHANGE",
  AIRPORT_CHANGE: "USER_ADD_TRIP_AIRPORT_CHANGE",
  COUNTRY_CODE_CHANGE: "USER_ADD_TRIP_COUNTRY_CODE_CHANGE",
  USER_ADD_TRIP_START: "USER_ADD_TRIP_START",
  USER_ADD_TRIP_SUCCESS: "USER_ADD_TRIP_SUCCESS",
  USER_ADD_TRIP_ERROR: "USER_ADD_TRIP_ERROR"
};

export const openAddTrip = (dispatch) => () =>
  dispatch({ type: AddTrip.OPEN_ADD_TRIP });

export const closeAddTrip = (dispatch) => () =>
  dispatch({ type: AddTrip.CLOSE_ADD_TRIP });

export const onArrivalDateChange = (dispatch) => (arrivalDate) =>
  dispatch({ type: AddTrip.ARRIVAL_DATE_CHANGE, arrivalDate });

export const onDepartureDateChange = (dispatch) => (departureDate) =>
  dispatch({ type: AddTrip.DEPARTURE_DATE_CHANGE, departureDate });

export const onAirportChange = (dispatch) => (airport) =>
  dispatch({ type: AddTrip.AIRPORT_CHANGE, airport });

export const onCountryCodeChange = (dispatch) => (countryCode) =>
  dispatch({ type: AddTrip.COUNTRY_CODE_CHANGE, countryCode });

const initialState = {
  error: false,
  show: false,
  isLoading: false,
  arrivalDate: "",
  departureDate: "",
  airport: "",
  countryCode: "FR"
};

export const addTripReducer = (state = initialState, action) => {
  switch (action.type) {
    case AddTrip.OPEN_ADD_TRIP:
      return merge(state, { show: true, error: false });

    case AddTrip.CLOSE_ADD_TRIP:
      return initialState;

    case AddTrip.ARRIVAL_DATE_CHANGE:
      return merge(state, { arrivalDate: action.arrivalDate });

    case AddTrip.DEPARTURE_DATE_CHANGE:
      return merge(state, { departureDate: action.departureDate });

    case AddTrip.AIRPORT_CHANGE:
      return merge(state, { airport: action.airport });

    case AddTrip.COUNTRY_CODE_CHANGE:
      return merge(state, { countryCode: action.countryCode });

    case AddTrip.USER_ADD_TRIP_START:
      return merge(state, { isLoading: true });

    case AddTrip.USER_ADD_TRIP_SUCCESS:
      return initialState;

    case AddTrip.USER_ADD_TRIP_ERROR:
      return merge(state, { isLoading: false, error: true });

    default:
      return state;
  }
};
