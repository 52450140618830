import {
  deleteReceipt,
  uploadReceipt,
  addReceiptToTrip
} from "../../api/users/add-receipt-service";
import { AddReceipt } from "../reducers/add-receipt-reducer";
import { UserDetailsAction } from "./user-details-actions";

export const uploadReceiptImgFile = (dispatch) => (image) => {
  dispatch({ type: AddReceipt.USER_ADD_RECEIPT_START_UPLOAD });

  return uploadReceipt(image)
    .then((url) =>
      dispatch({ type: AddReceipt.USER_ADD_RECEIPT_IMAGE_UPLOADED, url })
    )
    .catch((error) =>
      dispatch({ type: AddReceipt.USER_ADD_RECEIPT_IMAGE_UPLOAD_ERROR, error })
    );
};

export const deleteReceiptImgFile = (dispatch) => (receiptImgUrl) => {
  return deleteReceipt().then(() =>
    dispatch({ type: AddReceipt.USER_ADD_RECEIPT_IMAGE_DELETED })
  );
};

export const addReceipt = (dispatch) => (tripId, url) => {
  dispatch({ type: AddReceipt.USER_ADD_RECEIPT_START_UPLOAD });

  return addReceiptToTrip(tripId, url)
    .then(() => {
      dispatch({ type: AddReceipt.USER_ADD_RECEIPT_IMAGE_COMPLETE });
      dispatch({ type: UserDetailsAction.RESET_STATE });
    })
    .catch((error) =>
      dispatch({ type: AddReceipt.USER_ADD_RECEIPT_IMAGE_UPLOAD_ERROR, error })
    );
};

export const uploadAndAddReceipt = (file, tripId) => {
  return uploadReceipt(file).then((url) => addReceiptToTrip(tripId, url));
};
