import React from "react";
import PropTypes from "prop-types";
import { List } from "immutable";

import DateRangeString from "../../../../components/DateRangeString";
import Dropdown from "../../../../components/Dropdown";

const TripDatesDropdown = (props) => {
  const tripDropdownValues = props.trips.map((t) =>
    Object.assign(
      {},
      {
        value: t.tripId,
        label: DateRangeString({ start: t.arrivalDate, end: t.departureDate })
      }
    )
  );
  return (
    <Dropdown
      onChange={props.onChange}
      selected={props.selected}
      options={tripDropdownValues.toArray()}
    />
  );
};

TripDatesDropdown.propTypes = {
  trips: PropTypes.instanceOf(List),
  selected: PropTypes.string,
  onChange: PropTypes.func
};

TripDatesDropdown.defaultProps = {
  trips: List()
};

export default TripDatesDropdown;
