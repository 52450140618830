import decodeJwt from "jwt-decode";
import { clearToken, getToken, hasToken } from "./api/tokens";

export const isLocal = process.env.REACT_APP_ENV === "LOCAL";
export const isStub = process.env.REACT_APP_ENV === "STUB";
export const isTest = process.env.REACT_APP_ENV === "TEST";
export const isProd = process.env.REACT_APP_ENV === "PRODUCTION";

export const gitBranch = `${process.env.REACT_APP_GIT_BRANCH || "LOCAL"}:${
  process.env.REACT_APP_GIT_COMMIT || "<unknown commit>"
}`;

export const API_SUFFIX_NO_ADMIN = isLocal ? "" : "/api/v1";
export const API_SUFFIX = isLocal ? "" : "/api/v1/admin";

export const WEVAT_GATEWAY = (() => {
  if (isLocal) {
    return "http://localhost:8080";
  }

  return isProd ? "https://gateway.wevat.com" : "https://gateway.dev.wevat.com";
})();

export const SEGMENT_API_KEY = isProd
  ? "PhqkgmJzKSmBolQiT6vYUaQoueS9pZWF"
  : "JoYlFVRu6lncnllZbzwHnfJRfg92GEPP";

export const getUserInfo = () => {
  if (!hasToken()) {
    return {
      userId: null,
      username: null,
      role: null
    };
  }

  const decodedToken = decodeJwt(getToken());

  if (!localStorage.username) {
    clearToken();
    document.location.reload();
  }

  return {
    userId: localStorage.username,
    username: localStorage.username,
    role: decodedToken.role
  };
};
