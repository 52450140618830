import { takeLatest, call, put, select } from "redux-saga/effects";

import * as slice from "./receipts-slice";
import * as api from "@src/api";
import * as selector from "@store/selectors";

import {
  handleGetRetailers,
  handleGetRejectionReasons,
  handleGetReviewers
} from "@store/global/global-saga";

export function* receiptsInit() {
  yield takeLatest(slice.getReceipts.type, handleGetReceipts);
  yield takeLatest(slice.setQueries.type, handleGetReceipts);
}

export function* handleGetReceipts(action?): any {
  const { isPooling } = action?.payload ?? {};

  const retailers = selector.selectRetailers(yield select());
  const queries = selector.selectQueries(yield select());
  const rejectionReasons = selector.selectRejectionReasons(yield select());
  const reviewers = selector.selectReviewers(yield select());

  if (!retailers.length) yield call(handleGetRetailers);
  if (!rejectionReasons.length) yield call(handleGetRejectionReasons);
  if (!reviewers.length) yield call(handleGetReviewers);

  const response = yield call(api.fetchReceiptsTableData, queries);
  yield put(slice.setReceipts(response));

  if (isPooling) {
    yield put(slice.startPooling());
  }
}
