import React from "react";
import { shallowEqual } from "react-redux";
import ReactFlagsSelect from "react-flags-select";

import WevatModal from "../../components/modals/WevatModal";
import Button from "../../components/Button";
import { eventData, withConfirm } from "../../utils/dom-functions";
import { stringManipulation } from "../../utils/strings";
import * as selectors from "@store/selectors";
import {
  createRetailer,
  importRetailers,
  onNameChanged,
  onCountryChanged,
  onAddressLineChanged,
  onAddressPostalCodeChanged,
  onAddressCityChanged,
  onVatnumberChanged,
  onSirenChanged,
  onSiretChanged,
  stopAddingRetailer
} from "../../store/reducers/add-retailer-reducer";
import { ReceiptViewer } from "../receipt/receipt-viewer";
import { useAppSelector, useAppDispatch } from "../../hooks";
import { SUPPORTED_COUNTRIES } from "@src/utils/trips";
import ReceiptVerificationState from "@views/receipt/stores/ReceiptVerificationState";

import "./retailer-modal.scss";

const AddRetailerModal = () => {
  const formRef = React.useRef(null);
  const importFormRef = React.useRef(null);
  const receipt = useAppSelector(selectors.selectReceipt, shallowEqual);

  const dispatch = useAppDispatch();
  const {
    show = false,
    exists,
    vatnumber,
    retailerName,
    country,
    addressLine,
    addressPostalCode,
    addressCity,
    siret,
    siren,
  } = useAppSelector(mapStateToProps, shallowEqual);

  const onHide = eventData(stopAddingRetailer(dispatch));
  const onNameChangedDispatch = eventData(onNameChanged(dispatch));
  const onCountryChangedDispatch = onCountryChanged(dispatch);
  const onAddressLineChangedDispatch = eventData(onAddressLineChanged(dispatch));
  const onAddressPostalCodeChangedDispatch = eventData(onAddressPostalCodeChanged(dispatch));
  const onAddressCityChangedDispatch = eventData(onAddressCityChanged(dispatch));
  const onVatnumberChangedDispatch = eventData(onVatnumberChanged(dispatch));
  const onSirenChangedDispatch = eventData(onSirenChanged(dispatch));
  const onSiretChangedDispatch = eventData(onSiretChanged(dispatch));
  const createRetailerDispatch = createRetailer(dispatch);
  const importRetailersDispatch = importRetailers(dispatch);
  const [disableAddRetailerButtons, setDisable] = React.useState(false);

  const receiptImage =
    receipt.imageUrls.length > 0 ? receipt.imageUrls[0] : null;

  return (
    <WevatModal
      type={receiptImage ? "large" : "medium"}
      height="630"
      show={show}
      onHide={onHide}
    >
      <div className="two-columns">
        {show && receiptImage && (
          <div className="receipt-viewer">
            <ReceiptViewer receiptUrl={receiptImage} />
          </div>
        )}
        <div className="column">
        <form
            ref={importFormRef}
            onSubmit={(e) => {
              e.preventDefault();
              setDisable(true);

              handleImportRetailer({
                importRetailers: importRetailersDispatch,
                vatnumber
              });

              return false;
            }}
          >
            <div className="add-retailer-view">
              <h2>Import retailers</h2>

              <div className="field-container">
                <label>TVA no.</label>
                <input
                  type="text"
                  className="vat"
                  defaultValue={vatnumber}
                  onChange={onVatnumberChangedDispatch}
                  pattern="\d{3,11}"
                  maxLength={11}
                  minLength={3}
                />
                <div className="vatCountryCode">{country}</div>
              </div>

              <div className="update-button-container">
                <Button
                  text={"Import retailers"}
                  nativetype="submit"
                  id="Import"
                  disabled={!importFormRef.current?.checkValidity() && !disableAddRetailerButtons}
                />
              </div>
            </div>
          </form>

          <form
            ref={formRef}
            onSubmit={(e) => {
              e.preventDefault();
              setDisable(true);

              handleCreateRetailer({
                exists,
                createRetailer: createRetailerDispatch,
                retailerName,
                country,
                addressLine,
                addressPostalCode,
                addressCity,
                vatnumber,
                siren,
                siret
              });

              return false;
            }}
          >
            <div className="add-retailer-view">
              <h2>Add a new retailer</h2>

              <div className="field-container">
                <label>Retailer name</label>
                <input
                  type="text"
                  defaultValue={retailerName}
                  onChange={onNameChangedDispatch}
                  maxLength={45}
                  minLength={3}
                />
              </div>

              <div className="field-container">
                <label>Country</label>
                <ReactFlagsSelect
                  searchable={true}
                  selected={country}
                  onSelect={onCountryChangedDispatch}
                  countries={SUPPORTED_COUNTRIES}
                />
              </div>

              <div className="field-container">
                <label>Address line 1</label>
                <input
                  type="text"
                  defaultValue={addressLine}
                  onChange={onAddressLineChangedDispatch}
                  maxLength={100}
                  minLength={3}
                />
              </div>

              <div className="field-container">
                <label>Post code</label>
                <input
                  type="text"
                  defaultValue={addressPostalCode}
                  onChange={onAddressPostalCodeChangedDispatch}
                  maxLength={10}
                  minLength={3}
                />
              </div>

              <div className="field-container">
                <label>City</label>
                <input
                  type="text"
                  defaultValue={addressCity}
                  onChange={onAddressCityChangedDispatch}
                  maxLength={50}
                  minLength={3}
                />
              </div>

              <div className="field-container">
                <label>TVA no.</label>
                <input
                  type="text"
                  className="vat"
                  defaultValue={vatnumber}
                  onChange={onVatnumberChangedDispatch}
                  pattern="\d{3,11}"
                  maxLength={11}
                  minLength={3}
                />
                <div className="vatCountryCode">{country}</div>
              </div>

              <div className="field-container">
                <label>SIREN no.</label>
                <input
                  type="text"
                  defaultValue={siren}
                  onChange={onSirenChangedDispatch}
                  placeholder="(Optional) e.g. 542 019 237"
                />
              </div>

              <div className="field-container">
                <label>SIRET no.</label>
                <input
                  type="text"
                  defaultValue={siret}
                  onChange={onSiretChangedDispatch}
                  placeholder="(Optional) e.g. 542 019 237 00289"
                />
              </div>

              <div className="update-button-container">
                <Button
                  text={"Add retailer"}
                  nativetype="submit"
                  disabled={!formRef.current?.checkValidity() && !disableAddRetailerButtons}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </WevatModal>
  );
};

const handleCreateRetailer = ({
  exists,
  createRetailer,
  retailerName,
  country,
  addressLine,
  addressPostalCode,
  addressCity,
  vatnumber,
  siren,
  siret
}) => {
  if (exists) {
    return withConfirm(
      "A retailer with the same name and country exists? Are you sure?!"
    );
  }
  return createRetailer({
    retailerName,
    country,
    addressLine,
    addressPostalCode,
    addressCity,
    vatnumber,
    siren,
    siret
  }).then(ReceiptVerificationState.setRetailer);
};

const handleImportRetailer = ({
  importRetailers,
  vatnumber
}) => {
  return importRetailers({
    vatnumber,
  }).then(ReceiptVerificationState.setRetailer);
};

export default AddRetailerModal;

const mapStateToProps = (state) => {
  const currentRetailerName = stringManipulation(
    state.addRetailerModal.retailerName
  );
  const currentCountry = stringManipulation(state.addRetailerModal.country);
  const exists = state.addRetailerModal.retailers.some(
    (r) =>
      r !== null &&
      r !== undefined &&
      stringManipulation(r.retailerName) === currentRetailerName &&
      stringManipulation(r.country) === currentCountry
  );
  return {
    ...state.addRetailerModal,
    exists
  };
};
