import React from "react";
import "./css/transparent-button.css";

const TransparentButton = props => (
  <button className="transparent-button" {...props}>
    {props.children}
  </button>
);

export default TransparentButton;
