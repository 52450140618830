export const eventData = (fn) => (event) => {
  if (!event || !event.target) return;
  fn(event.target.value);
};
export const keyPress = (onSubmit, charCode) => (e) => {
  if (e.charCode === charCode) {
    e.preventDefault();
    onSubmit();
  }
};

export const withAlert = (text, fn) => {
  return function () {
    alert(text);
    return fn.apply(arguments);
  };
};

export const withConfirm = (text, fn) => {
  return function () {
    let answer = window.confirm(text);
    if (answer) {
      return fn.apply(arguments);
    } else {
      return;
    }
  };
};

//execute a function if the next focused component isn't a child of the blurred component
export const onBlurTree = (fn) => (e) => {
  const target = e.currentTarget;

  setTimeout(function () {
    if (
      !target.contains(document.activeElement) &&
      target !== document.activeElement
    ) {
      return fn();
    }
  });
};

export const selectAllOnFocus = (e) => {
  e.target.select();
};
