import { merge } from "../../utils/functions";
import UserDetailsStore from "../UserDetailsStore";

export const TripActions = {
  SELECT_TRIP: "SELECT_TRIP",
  PRE_SELECT_TRIP: "PRE_SELECT_TRIP"
};

export const onTripChanged = (dispatch) => (_, tripId) => {
  dispatch({ type: TripActions.SELECT_TRIP, tripId });
};

export const preSelectTrip = (dispatch) => (tripId) => {
  dispatch({ type: TripActions.PRE_SELECT_TRIP, tripId });
};

const initialState = {
  selectedTripId: undefined,
  preselectedTripId: undefined
};

export const selectedTripState = (state = initialState, action) => {
  switch (action.type) {
    case TripActions.SELECT_TRIP:
      UserDetailsStore.selectTrip(action.tripId);
      return merge(state, {
        selectedTripId: action.tripId,
        preselectedTripId: undefined
      });

    case TripActions.PRE_SELECT_TRIP:
      return merge(state, { preselectedTripId: action.tripId });

    default:
      return state;
  }
};
