import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import WevatModal from "./WevatModal";
import { hideModal } from "../../store/service/modal-bindings";

const mapStateToProps = (state) => {
  return {
    ...state.modalReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onHide: hideModal(dispatch)
  };
};

const ModalWrapper = (props) => {
  const Component = props.component;

  if (props.component == null) {
    return null;
  }
  return (
    <WevatModal onHide={props.onHide} {...props.modalProps}>
      <Component {...props.componentProps} />
    </WevatModal>
  );
};

ModalWrapper.propTypes = {
  modalProps: PropTypes.object,
  component: PropTypes.any,
  componentProps: PropTypes.object
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalWrapper);
