import React from "react";
import { AnimatePresence, motion } from "framer-motion";

const AnimatedUserSummaryContainer = ({ children, ...props }) => (
  <AnimatePresence>
    <motion.div
      initial={{ opacity: 0, y: -15, scale: 0.9 }}
      animate={{ opacity: 1, y: 0, scale: 1 }}
      exit={{ opacity: 0, y: -15, scale: 1.1 }}
      {...props}
    >
      {children}
    </motion.div>
  </AnimatePresence>
);

export default AnimatedUserSummaryContainer;
