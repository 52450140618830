import React, { useState, useEffect, useRef, useCallback } from "react";
import classNames from "classnames";

import { useAppSelector, useAppDispatch } from "../../hooks";

import TextInput from "../TextInput";
import { setRetailerId } from "@store/actions";
import { Retailer } from "@store/types";
import { selectRetailers, selectRetailerId } from "@store/selectors";
import {
  startAddingRetailer,
  onNameChanged
} from "@store/reducers/add-retailer-reducer";
import plusImg from "@assets/images/plus.png";

import "./RetailerDropdown.scss";
import { RetailerResponse } from "@src/api";

const RetailerDropdown = () => {
  const dispatch = useAppDispatch();
  const filterListElement = useRef<HTMLDivElement>();
  const retailers = useAppSelector(selectRetailers);
  const retailerId = useAppSelector(selectRetailerId);
  const startAddingRetailerDispatch = startAddingRetailer(dispatch);
  const setNewRetailerNameDispatch = onNameChanged(dispatch);

  const [filteredList, setFilteredList] = useState<Retailer[]>([]);
  const [keyword, setKeyword] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [selectedRetailer, setSelectedRetailer] = useState<Retailer>(empty_state);

  const options = keyword ? filteredList : retailers.slice(0, 50);

  const handleChange = (value: string) => {
    const words = value.trim().split(/\s+/) 
    const matchedResults = retailers.filter((r) =>
      words.map((word) => 
      r.retailerName.toLowerCase().includes(word.toLowerCase()) ||
      r.addressCity?.toLowerCase().includes(word.toLowerCase()) ||
      r.addressLine?.toLowerCase().includes(word.toLowerCase()) ||
      r.addressPostalColde?.toLowerCase().includes(word.toLowerCase()) ||
      r.siren?.toLowerCase().includes(word.toLowerCase()) ||
      r.siret?.toLowerCase().includes(word.toLowerCase()) ||
      r.vatNumber?.toLowerCase().includes(word.toLowerCase().replace("fr",""))
    ).every(element => element)).slice(0, 100);
    setFilteredList(matchedResults);
    setKeyword(value);
  };

  const handleInputFocus = () => {
    setKeyword("");
    setIsFocused(true);
  };

  const handleInputBlur = () => {
    setIsFocused(false);
    setFilteredList([]);
    setKeyword("");
  };

  const onSelectRetailer = (value: Retailer) => {
    setSelectedRetailer(value);
    handleInputBlur();
    dispatch(setRetailerId(value.retailerId));
  };

  const handleMouseEvent = useCallback((e: any) => {
    const { className } = e.target;
    if (
      !className.includes("retailer-dropdown") &&
      !className.includes("retailer-dropdown__add-btn")
    ) {
      handleInputBlur();
    }
  }, []);

  const handleAddRetailer = () => {
    setNewRetailerNameDispatch(keyword);
    startAddingRetailerDispatch();
  };

  useEffect(() => {
    window.addEventListener("mouseup", handleMouseEvent);

    return () => {
      window.removeEventListener("mouseup", handleMouseEvent);
    };
  }, [handleMouseEvent]);

  useEffect(() => {
    if (isFocused) filterListElement.current.scrollTo({ top: 0 });
  }, [isFocused]);

  useEffect(() => {
    if (
      retailers.length &&
      retailerId &&
      retailerId !== selectedRetailer?.retailerId
    ) {
      setSelectedRetailer(
        retailerId
          ? retailers.find((r) => r.retailerId === retailerId)
          : empty_state
      );
    }
  }, [retailerId, selectedRetailer?.retailerId, retailers]);

  return (
    <div
      className={classNames("retailer-dropdown", {
        "retailer-dropdown--active": isFocused
      })}
    >
      <TextInput
        placeholder="Enter SIRET no., VAT no. or retailer name"
        className="retailer-dropdown__input"
        value={isFocused ? keyword : selectedRetailer ? format_dropdown_value(selectedRetailer) : "" }
        onChange={(value) => handleChange(value)}
        onFocus={(e) => {
          e.stopPropagation();
          handleInputFocus();
        }}
      />
      <div
        ref={filterListElement}
        className={classNames("retailer-dropdown__list-wrapper", {
          "retailer-dropdown__list--active": isFocused
        })}
      >
        <ul className="retailer-dropdown__list">
          {options.filter(o  => !o.deprecated).map((o) => {
            return (
              <li
                className="retailer-dropdown__list-item"
                key={o.retailerId}
                onClick={(e) => {
                  e.stopPropagation();
                  onSelectRetailer(o);
                }}
              >
                {format_dropdown_value(o)}
              </li>
            );
          })}
        </ul>
        <button
          className="retailer-dropdown__add-btn"
          onClick={handleAddRetailer}
        >
          <img src={plusImg} alt="" />
          Add retailer
        </button>
      </div>
    </div>
  );
};

const empty_state = {
  retailerName: "",
  retailerId: "",
  deprecated: false,
  addressLine: "",
  addressPostalColde: "",
  addressCity: "",
  vatNumber: "",    
  siret: "",    
  siren: "",
  itemsWithVat: true
}

const format_dropdown_value = (retailer: RetailerResponse) => {
  var retailerDescription = retailer.deprecated ? `(DEPRECATED) ${retailer.retailerName}` : retailer.retailerName

  if (retailer.addressLine) retailerDescription += `, ${retailer.addressLine}, ${retailer.addressCity}`
  if (retailer.siret) retailerDescription += ` SIRET: ${retailer.siret}`
  if (retailer.vatNumber) retailerDescription += ` VAT: ${retailer.vatNumber}`
  return retailerDescription
}

export default RetailerDropdown;
