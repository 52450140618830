import decodeJwt from "jwt-decode";
import { ROLES } from "./roles";

export interface UserInfo {
  // v1
  ROLE: ROLES;
  // v2
  role: ROLES;

  username: string;
  email: string;
}

export function hasToken(): boolean {
  return (
    localStorage.token !== undefined &&
    localStorage.token !== "undefined" &&
    localStorage.token !== null
  );
}

export function getToken(): string {
  return localStorage.token;
}

export function setToken(token: string): void {
  localStorage.token = token;
}

export function clearToken(): void {
  //this will always be set to a string, because of how windows.sessionStorage works
  localStorage.token = undefined;
}

export function redirectToLogin(): void {
  window.location.replace("/login");
}

export function withAuthHeaderIfSet(headers: Headers): Headers {
  if (hasToken()) {
    headers.append("Authorization", `Bearer ${localStorage.token}`);
  }

  return headers;
}

export function getTokenInfo(): UserInfo {
  return decodeJwt(getToken()) as UserInfo;
}

export function throwIfNotAdmin(): void {
  const adminRoles = ["ADMIN", "READ_ONLY_ADMIN", "RECEIPT_REVIEWER_ADMIN"];
  const jwt = getTokenInfo();

  if (!adminRoles.includes(jwt.ROLE)) {
    throw new Error("You must be an admin to use the admin panel");
  }
}

export function stripBearer(header: string): string {
  if (!header) {
    throw new Error("Token returned was empty");
  }
  return header.replace("Bearer ", "");
}
