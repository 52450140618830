import React from "react";
import { attemptLogin } from "./LoginService";
import { eventData } from "../../utils/dom-functions";

const formDetails = function () {
  let username,
    password = "";
  return {
    setUser: (user) => (username = user),
    setPassword: (pass) => (password = pass),
    getUser: () => username,
    getPassword: () => password
  };
};

const onSubmit = function (event, details) {
  event.preventDefault();
   
	attemptLogin(details.getUser(), details.getPassword());
};

const Login = () => {
  const details = formDetails();
  return (
    <div>
      <form onSubmit={(event) => onSubmit(event, details)}>
        <input
          type="text"
          name="username"
          placeholder={"Username"}
          onChange={eventData(details.setUser)}
        />

        <input
          type="password"
          name="password"
          placeholder={"Password"}
          onChange={eventData(details.setPassword)}
        />

        <input type="submit" value={"Login"} />
      </form>
    </div>
  );
};

export default Login;
