import React from "react";
import { shallowEqual } from "react-redux";
import { eventData } from "@utils/dom-functions";
import { useAppSelector, useAppDispatch } from "@src/hooks";
import PagingArrows from "@components/PagingArrows";
import SearchTextbox from "@components/SearchTextbox";
import {
  onNextPage,
  onPreviousPage,
  onSearchInputChanged,
  onSearchSubmit,
} from "@store/reducers/retailers-table-reducer";

import "../css/retailers-table-bar.css";

const FilterBar = () => {

  const dispatch = useAppDispatch();

  const {
    searchText,
    page,
    maxPages,
  } = useAppSelector(mapStateToProps, shallowEqual);

  const onSearchInputChangedDispatch =  onSearchInputChanged(dispatch);
  const onSearchSubmitDispatch =  onSearchSubmit(dispatch);
  
  const onNextPageDispatch = eventData(onNextPage(dispatch));
  const onPreviousPageDispatch =  eventData(onPreviousPage(dispatch));

  return <div className="filter-container">
  <div className="button-container">
   <div style={{ float: "left" }}>
   </div>
 </div>
 <SearchTextbox 
   hint="Retailer name, SIREN, SIRET, VAT no."
   onSubmit={onSearchSubmitDispatch}
   onChange={onSearchInputChangedDispatch}
   value={searchText}
 />
 <PagingArrows
     numberOfPages={maxPages}
     page={page}
     onNext={onNextPageDispatch}
     onPrevious={onPreviousPageDispatch}
 />
</div>
}

const mapStateToProps = (state) => {
  return {
    ...state.retailersTable,
  };
};

export default FilterBar;
