import React, { FC, useState, useEffect, useRef } from "react";

import "./text-input.scss";

type InputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

type Props = Omit<InputProps, "onChange"> & {
  alignment?: "left" | "center" | "right";
  startAdornment?: React.ReactElement;
  onChange: (value: string) => void;
};

const TextInput: FC<Props> = ({
  value,
  disabled,
  onChange,
  startAdornment,
  alignment = "left",
  style,
  children,
  maxLength,
  ...inputProps
}) => {
  const [cursor, setCursor] = useState(null);
  const ref = useRef(null);

  useEffect(() => {
    const input = ref.current;
    if (input) input.setSelectionRange(cursor, cursor);
  }, [ref, cursor, value]);

  const handleChange = (e) => {
    setCursor(e.target.selectionStart);
    onChange?.(e.target.value);
  };

  return (
    <div className="text-input" style={style}>
      {startAdornment && <span>&nbsp;</span>}
      {startAdornment}
      {startAdornment && <span>&nbsp;</span>}
      <input
        ref={ref}
        {...inputProps}
        type="text"
        className={`alignment-${alignment} ${inputProps.className}`}
        value={value}
        disabled={disabled}
        maxLength={maxLength}
        onChange={handleChange}
      />
      {children}
    </div>
  );
};

export default TextInput;
