import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { applyMiddleware, createStore } from "redux";
import createSagaMiddleware from "redux-saga";

import reducers from "./reducers";
import sagas from "./sagas";

const sagaMiddleware = createSagaMiddleware();
const compose = composeWithDevTools({ trace: true, traceLimit: 25 });

export const reduxStore = createStore(
  reducers,
  compose(applyMiddleware(sagaMiddleware, thunk))
);

sagaMiddleware.run(sagas);

export type RootState = ReturnType<typeof reduxStore.getState>;
export type AppDispatch = typeof reduxStore.dispatch;
