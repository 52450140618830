import React from "react";
import { useHistory } from "react-router-dom";
import classNames from "classnames";

import { Tabs } from "@src/constants";
import Logo from "@assets/images/logo.svg";
import OnlyFor from "../Roles";
import Badge from "../Badge";
import { gitBranch, isProd } from "@src/config";
import { isQABeta } from "@utils/functions";
import Popup from "@utils/Popup";
import { logout } from "@views/login/LoginService";

import "./navbar.scss";
import "../css/shine.css";

const mapMenuLinks = () => [
  {
    isAdmin: true,
    id: `tab-${Tabs.READY_TO_PAY}`,
    path: "/payments",
    label: "Payments"
  },
  {
    id: `tab-${Tabs.NEW_RECEIPTS}`,
    path: "/invoices",
    label: "Invoices"
  },
  {
    id: isQABeta() ? `tab-${Tabs.QA_RECEIPTS}` : null,
    path: "/qa",
    label: "QA"
  },
  {
    isAdmin: true,
    id: `tab-${Tabs.USERS}`,
    path: "/trips",
    label: "Trips"
  },
  {
    isAdmin: true,
    id: `tab-${Tabs.RETAILERS}`,
    path: "/retailers",
    label: "Retailers"
  }
];

const NavBar = () => {
  const history = useHistory();

  const renderMenuLinks = () => {
    return mapMenuLinks().map((menu) => {
      if (!menu.id) {
        return null;
      }

      const menuClassName = classNames({
        "active-menu": history.location.pathname.includes(menu.path)
      });

      if (menu.id && menu.isAdmin) {
        return (
          <OnlyFor.Admin key={menu.id}>
            <button
              id={menu.id}
              onClick={() => history.push(menu.path)}
              className={menuClassName}
            >
              {menu.label}
            </button>
          </OnlyFor.Admin>
        );
      }

      return (
        <button
          id={menu.id}
          key={menu.id}
          onClick={() => history.push(menu.path)}
          className={menuClassName}
        >
          {menu.label}
        </button>
      );
    });
  };

  const handleLogout = () => {
    Popup.fire({
      title: "Are you sure?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes, log out!"
    }).then((result) => {
      if (result.value) {
        logout();
      }
    });
  };

  return (
    <div className="navbar">
      <div className="navbar__logo-wrap">
        <img
          src={Logo}
          alt="Logo"
          onClick={() => {
            window.location.href = "/";
          }}
        />
      </div>

      {!isProd && (
        <div className="git_env">
          <Badge size="big" type="orange">
            {gitBranch}
          </Badge>
        </div>
      )}

      <div className="navbar__menu">
        {renderMenuLinks()}

        <button
          className="navbar__logout"
          key="logout"
          onClick={handleLogout}
          value={Tabs.LOGOUT}
        >
          Log out
        </button>
      </div>
    </div>
  );
};

export default NavBar;
