import { LocalDate, DateTimeFormatter } from "js-joda";
import { capitaliseFirst } from "./strings";

export const WEVAT_DTF = DateTimeFormatter.ofPattern("yyyy-MM-dd HH:mm:ss");

export const WEVAT_DF = DateTimeFormatter.ofPattern("yyyy-MM-dd");

export const WEVAT_DATE_FORMATTER = DateTimeFormatter.ISO_LOCAL_DATE;

export const ENGLISH_DATE_FORMATTER = DateTimeFormatter.ofPattern("dd/MM/yyyy");

export const WEVAT_TIME_FORMATTER = DateTimeFormatter.ofPattern("HH:mm");

export const validDate = function(dateString) {
  if (!dateString) {
    return false;
  }

  try {
    LocalDate.parse(dateString);
    return true;
  } catch (_) {
    console.warn(`Failed to parse: "${dateString}"`);
    return false;
  }
};

export const afterToday = function(date) {
  if (!date) {
    return false;
  }
  return date.isAfter(LocalDate.now());
};

export const isToday = function(date) {
  if (!date) {
    return false;
  }

  return date.equals(LocalDate.now());
};

export const beforeToday = function(date) {
  if (!date) {
    return false;
  }
  return date.isBefore(LocalDate.now());
};

export const equals = function(date1, date2) {
  if (!date1 || !date2) {
    return false;
  }
  return date1.equals(date2);
};

export const before = function(date1, date2) {
  if (!date1 || !date2) {
    return false;
  }
  return date1.isBefore(date2);
};

export const beforeOrEquals = function(date1, date2) {
  return before(date1, date2) || equals(date1, date2);
};

export const dayMonthString = function(date) {
  return (
    date.dayOfMonth() +
    " " +
    capitaliseFirst(
      date
        .month()
        .toString()
        .substring(0, 3)
    )
  );
};

export const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
];

export const isDateTime = (datetime) => {
  const hasSpaceonDateTime = datetime.indexOf(" ") !== -1

  return hasSpaceonDateTime;
};