import React, { FC } from "react";
import classNames from "classnames";

import "./checkbox.scss";

type Props = {
  checked?: boolean;
  disabled?: boolean;
  onChange: (isChecked: boolean) => void;
  isCheckboxPrefixed?: boolean;
  label?: string;
};

const Checkbox: FC<Props> = ({
  label = "",
  checked = false,
  disabled = false,
  onChange,
  isCheckboxPrefixed
}) => {
  const handleToggle = () => {
    if (!disabled) onChange(!checked);
  };

  const renderCheckBoxButton = () => (
    <button
      className={checked ? "checkbox checked" : "checkbox unchecked"}
      onClick={handleToggle}
      disabled={disabled}
    />
  );

  return (
    <div
      className={classNames("wevat-checkbox", {
        disabled
      })}
    >
      {isCheckboxPrefixed && renderCheckBoxButton()}
      {label ? (
        <label
          className={classNames({ disabled })}
          htmlFor={label}
          onClick={handleToggle}
        >
          {label}
        </label>
      ) : null}
      {!isCheckboxPrefixed && renderCheckBoxButton()}
    </div>
  );
};

export default Checkbox;
