import { RootState } from "@store/store";
import {
  selectRetailerId,
  selectNonAutomatedRejectionReasons
} from "@store/selectors";

export const selectTripId = ({ receiptVerification }: RootState) => {
  return receiptVerification.tripId;
};

export const selectReceiptId = ({ receiptVerification }: RootState) => {
  return receiptVerification.receiptId;
};

export const selectItemCategories = ({ receiptVerification }: RootState) => {
  return receiptVerification.itemCategories;
};

export const selectReceipt = ({ receiptVerification }: RootState) => {
  return receiptVerification.receipt;
};

export const selectTrip = ({ receiptVerification }: RootState) => {
  return receiptVerification.trip;
};

export const selectIsReceiptLoading = ({ receiptVerification }: RootState) => {
  return receiptVerification.isLoading;
};

export const selectIsReceiptError = ({ receiptVerification }: RootState) => {
  return receiptVerification.error;
};

export const selectIsFirstStep = ({ receiptVerification }: RootState) => {
  return receiptVerification.isFirstStep;
};

export const selectIsReverify = ({ receiptVerification }: RootState) => {
  return receiptVerification.isReverify;
};

export const selectIsRejectOrAccepted = ({
  receiptVerification
}: RootState) => ({
  isRejected: receiptVerification.isRejected,
  isAccepted: receiptVerification.isAccepted,
  isDeleted: receiptVerification.isDeleted
});

export const selectIndexData = ({ receiptVerification }: RootState) => ({
  isLastIndex: receiptVerification.isLastIndex,
  currentIndex: receiptVerification.currentIndex,
  receiptIndex: receiptVerification.receiptIndex
});

export const selectRejectionReasonNotes = ({
  receiptVerification
}: RootState) => {
  return receiptVerification.receipt.rejectionReasonNotes;
};

export const selectSums = ({ receiptVerification }: RootState) => ({
  totalSum: receiptVerification.totalSum,
  vatSum: receiptVerification.vatSum
});

export const selectReceiptRejectionReasons = ({
  receiptVerification
}: RootState) => {
  return receiptVerification.receipt.rejectionReasons;
};

export const selectIsWithContactSupport = ({
  receiptVerification
}: RootState) => {
  return receiptVerification.receipt.rejectionReasons.includes(
    "CONTACT_SUPPORT"
  );
};

export const selectIsReceiptVerification = (state: RootState) => {
  const { isRejected, isAccepted, isDeleted } = selectIsRejectOrAccepted(state);
  const isReverify = selectIsReverify(state);

  return !isReverify && (isRejected || isAccepted || isDeleted);
};

export const selectIsSkipModalActive = ({ receiptVerification }: RootState) => {
  return receiptVerification.isSkipModalActive;
};

export const selectFetchNextReceipt = ({ receiptVerification }: RootState) => {
  return receiptVerification.fetchNextReceipt;
};

export const selectHasRejectionReasons = (state: RootState) => {
  const reasons = selectNonAutomatedRejectionReasons(state);

  const { rejectionReasons } = state.receiptVerification.receipt;
  const isWithContactSupport = selectIsWithContactSupport(state);

  return (
    rejectionReasons?.length &&
    (isWithContactSupport ||
      reasons.some((r) => rejectionReasons.includes(r.id)))
  );
};

export const selectIsMandatoryFieldsValid = (state: RootState) => {
  const retailerId = selectRetailerId(state);
  const { items, purchaseTime } = selectReceipt(state);

  const hasItems = items.length;

  if (!hasItems) return false;

  const itemHasMissingFields = items.some(
    (i) => !i.categoryCode || !i.description || !i.cost
  );

  return !itemHasMissingFields && !!purchaseTime && !!retailerId;
};

export const selectReceiptList = ({ receiptVerification }: RootState) => {
  return receiptVerification.receiptList;
};
