import React, { FC } from "react";
import classNames from "classnames";

import OrderBy from "../OrderBy";
import AnimatedDivList from "../../utils/AnimatedDivList";

import "./WeVatTableV2.scss";

type TableHeadCellProps = {
  title: string;
  subTitle?: string;
  sortBy?: string;
  index?: string;
  sortDirection?: string;
  alignCenter?: boolean;
  alignRight?: boolean;
  onSortChange?: (sortBy: string, sortDirection: "DESC" | "ASC") => void;
  className?: string;
};

export const TableHeadCell: FC<TableHeadCellProps> = ({
  title,
  subTitle,
  sortBy,
  sortDirection,
  onSortChange,
  index,
  children,
  alignCenter,
  alignRight,
  className
}) => {
  return (
    <div
      className={classNames(`table-head-cell`, className, {
        "justify-center": !!alignCenter,
        "justify-right": !!alignRight
      })}
    >
      {!!subTitle ? (
        <div className="table-head-cell--with-base">
          <span>{title}</span>
          <span className="base">{subTitle}</span>
        </div>
      ) : (
        title
      )}
      {!!onSortChange && (
        <OrderBy
          direction={sortBy === index ? sortDirection : null}
          onClick={(direction) => onSortChange(index, direction)}
        />
      )}
      {children}
    </div>
  );
};

type TableHeaderProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  gridTemplateColumns: string;
};

export const TableHeader: FC<TableHeaderProps> = ({
  children,
  gridTemplateColumns
}) => {
  return (
    <div className="table-header-container" style={{ gridTemplateColumns }}>
      {children}
    </div>
  );
};

type TableBodyProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & { canScroll?: boolean };

export const TableBody: FC<TableBodyProps> = ({ children, canScroll }) => {
  return (
    <div
      className={classNames("table-body-container", {
        "table-body-container__scroll": canScroll
      })}
    >
      {children}
    </div>
  );
};

type TableBodyRowProps = {
  index: number;
  gridTemplateColumns: string;
  onClick?: () => void;
  className?: string;
};

export const TableBodyRow: FC<TableBodyRowProps> = ({
  children,
  className,
  gridTemplateColumns,
  ...rest
}) => {
  return (
    <AnimatedDivList
      className={`table-body-container-row ${className || ""}`}
      style={{ gridTemplateColumns }}
      {...rest}
    >
      {children}
    </AnimatedDivList>
  );
};

type TableBodyCellProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & { alignCenter?: boolean; alignRight?: boolean };

export const TableBodyCell: FC<TableBodyCellProps> = ({
  children,
  className,
  alignCenter,
  alignRight,
  ...rest
}) => {
  return (
    <div
      className={classNames(`table-body-container__cell`, className, {
        "justify-center": !!alignCenter,
        "justify-right": !!alignRight
      })}
      {...rest}
    >
      {children}
    </div>
  );
};
