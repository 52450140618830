export const Currency = {
  GBP: "GBP",
  EUR: "EUR"
};

export const formatCurrencyMoney = function (amount, currency = "GBP") {
  return (isNaN(+amount) ? 0 : +amount).toLocaleString("en-US", {
    style: "currency",
    minimumFractionDigits: 2,
    currency
  });
};

export const formatMoney = function (amount) {
  return formatCurrencyMoney(amount).substring(1);
};
