import { store } from "react-easy-state";
import { v4 as uuidv4 } from "uuid";

import ReceiptVerificationUIState from "./ReceiptVerificationUIState";
import { markReceiptAsOpen, updateReceipt } from "../../../api/receipts";
import { ReceiptViewActions } from "../../../store/actions/receipt-view-actions";
import { numberify } from "../../../utils/numbers";

const getDefaultItem = () => ({
  itemId: uuidv4(),
  description: "",
  quantity: 1,
  cost: 0,
  vatRate: 20,
  airside: false
});

const DEFAULT_STATE = {
  items: [],
  receiptId: "",
  status: "",
  uploadedTime: "",
  purchaseTime: "",
  purchaseDate: "",
  lastUpdated: "",
  retailer: {
    retailerId: "",
    retailerName: "",
    vatnumber: ""
  },
  imageUrls: [],
  rejectionReasons: [],
  phoneNumber: "",
  address: "",
  establishment: "",
  totalConfidence: "",
  ocrTaxTotal: "",
  ocrTotal: "",
  total: "",
  taxTotal: "",
  isOpen: "",
  updatedBy: {
    id: "",
    name: ""
  },
  verifiedBy: {
    id: "",
    name: ""
  },
  tripId: "",
  // non-receipt related
  isSaving: false,
  receipts: [],
  dispatch: () => {}
};

export const ReceiptVerificationState = store({
  ...DEFAULT_STATE,
  isSaving: false,
  receipts: [],
  dispatch: () => {},
  //
  open(receipt, trip) {
    markReceiptAsOpen(receipt.receiptId);
    receipt.items = receipt.items.toArray();
    _this.retailer = DEFAULT_STATE.retailer;
    Object.assign(_this, receipt);

    if (receipt.purchaseTime) {
      const [date, time] = receipt.purchaseTime.split(" ");
      _this.purchaseDate = date;
      _this.purchaseTime = time
        .split(":")
        .slice(0, 2) // starting on the first, get two positions
        .join(":");
    } else {
      _this.purchaseDate = undefined;
      _this.purchaseTime = undefined;
    }

    _this.items.forEach((i) => {
      i.cost = numberify(i.cost);
      i.quantity = numberify(i.quantity);
      i.vatRate = numberify(i.vatRate);
    });

    _this.redoTotals();

    ReceiptVerificationUIState.open();
  },
  reset() {
    Object.assign(_this, DEFAULT_STATE);
  },
  setRejectionReason(reason) {
    _this.rejectionReasons = [reason];
  },
  changeItem(index, item) {
    _this.items[index] = item;

    _this.redoTotals();
  },
  addItem() {
    _this.items.push(getDefaultItem());
  },
  removeItem(index) {
    _this.items.splice(index, 1);
  },
  removeItems(indexes) {
    _this.items = _this.items.filter(
      (_, index) => indexes.indexOf(index) === -1
    );
    _this.redoTotals();
  },
  setPurchaseDate(purchaseDate) {
    _this.purchaseDate = purchaseDate;
  },
  setPurchaseTime(purchaseTime) {
    _this.purchaseTime = purchaseTime;
  },
  setRetailer(retailer) {
    _this.retailer = retailer;
  },
  redoTotals() {
    _this.total = _this.items
      .map((i) => +i.cost || 0)
      .reduce((v, acc) => acc + v, 0);
    _this.taxTotal = _this.items
      .map((i) => i.cost * i.vatRate * 0.01)
      .reduce((v, acc) => acc + v, 0);
  },
  getFirstRejectionReason() {
    return _this.rejectionReasons && _this.rejectionReasons.length > 0
      ? _this.rejectionReasons[0]
      : "";
  },
  //
  async save(approve) {
    _this.isSaving = true;
    await updateReceipt({
      ..._this,
      rejectionReasons: approve ? [] : _this.rejectionReasons,
      purchaseTime:
        (_this.purchaseDate &&
          _this.purchaseTime &&
          `${_this.purchaseDate} ${_this.purchaseTime}:00`.trim()) ||
        null,
      retailerId: _this.retailer.retailerId
    });
    _this.isSaving = false;

    _this.dispatch({ type: ReceiptViewActions.RECEIPT_UPDATED });
    ReceiptVerificationUIState.close();
  },
  setReceipts(receipts) {
    _this.receipts = receipts;
  },
  setDispatch(dispatch) {
    _this.dispatch = dispatch;
  },
  skipReceipt() {
    _this.dispatch({ type: ReceiptViewActions.RECEIPT_SKIP });
    ReceiptVerificationUIState.close();
  }
});
const _this = ReceiptVerificationState;

export default ReceiptVerificationState;