import { createSlice } from "@reduxjs/toolkit";

import { GlobalState } from "./types";

const initialState = (): GlobalState => ({
  retailerId: null,
  error: false,
  errorMessage: "",
  notificationMessage: "",
  notificationType: "",
  retailers: [],
  isRetailersLoading: false,
  reviewers: [],
  rejectionReasons: [],
  isLoadingReviewers: false,
  isLoadingRejectionReasons: false
});

const global = createSlice({
  name: "global",
  initialState: initialState(),
  reducers: {
    setUserLoadError: (state, action) => {
      return withError(state, "Error loading user details!");
    },
    setReceiptDeleteError: (state, action) => {
      return withError(
        state,
        `There was an error deleting the receipt: ${action.payload.error}`
      );
    },
    setNetworkError: (state, action) => {
      return withError(state, action.payload.errorMessage);
    },
    resetErrors: (state) => ({
      ...state,
      error: false,
      errorMessage: "",
      notificationMessage: "",
      notificationType: ""
    }),
    setNotification: (state, action) => ({
      ...state,
      notificationMessage: action.payload.message,
      notificationType: action.payload?.type ?? "error"
    }),
    clearNotification: (state) => ({
      ...state,
      notificationMessage: "",
      notificationType: ""
    }),
    getRetailers: (state) => ({
      ...state,
      isRetailersLoading: true
    }),
    setRetailers: (state, action) => ({
      ...state,
      isRetailersLoading: false,
      retailers: [...action.payload]
    }),
    getReviewers: (state) => ({
      ...state,
      isLoadingReviewers: true,
      reviewers: []
    }),
    setReviewers: (state, action) => ({
      ...state,
      isLoadingReviewers: false,
      reviewers: [...action.payload]
    }),
    setRetailerId: (state, action) => ({
      ...state,
      retailerId: action.payload
    }),
    getRejectionReasons: (state) => ({
      ...state,
      isLoadingRejectionReasons: true,
      rejectionReasons: []
    }),
    setRejectionReasons: (state, action) => ({
      ...state,
      isLoadingRejectionReasons: false,
      rejectionReasons: [...action.payload]
    })
  }
});

export default global.reducer;

export const {
  setUserLoadError,
  setReceiptDeleteError,
  setNetworkError,
  resetErrors,
  setNotification,
  clearNotification,
  setRetailers,
  getRetailers,
  setRetailerId,
  setReviewers,
  setRejectionReasons,
  getRejectionReasons,
  getReviewers
} = global.actions;

const withError = (state: GlobalState, errorMessage: string): GlobalState => ({
  ...state,
  error: true,
  errorMessage
});
