import React from "react";
import { store, view } from "react-easy-state";
import { saveAs } from "file-saver";

import recorder from "../utils/recorder";
import Button from "./Button";

import "./css/record-button.css";

const RecordState = store({
  isRecording: false,
  toggleRecording() {
    if (!RecordState.isRecording) RecordState.startRecording();
    else RecordState.stopRecording();
  },
  startRecording() {
    recorder.start();
    // console.startRecording();
    RecordState.isRecording = true;
  },
  stopRecording() {
    recorder.stop();
    // console.stopRecording();

    const data = {
      // logs: console.logArray(),
      events: recorder.getEvents()
    };
    RecordState.dumpToFile(data);
    // localStorage.setItem("session", JSON.stringify(data));

    RecordState.isRecording = false;
  },
  dumpToFile(data) {
    const blob = new Blob([JSON.stringify(data)], {
      type: "application/json;charset=utf-8"
    });
    saveAs(blob, `recording-${new Date().toISOString()}.json`);
  }
});

class RecordButton extends React.Component {
  render() {
    return (
      <Button
        size="small"
        type="danger"
        className={`record-button ${
          RecordState.isRecording ? "recording" : ""
        }`}
        onClick={RecordState.toggleRecording}
      >
        <span className={RecordState.isRecording ? "blink" : null}>
          &#11044;
        </span>
        &nbsp;
        {RecordState.isRecording ? "" : "Record"}
        &nbsp;
      </Button>
    );
  }
}

export default view(RecordButton);
