import { LocalDate } from "js-joda";
import { pipe } from "./functions";
import { numberify, round } from "./numbers";
import { beforeToday } from "./dates";

const DEFAULT_VAT_PERCENTAGE = 20;

export const SUPPORTED_COUNTRIES = ["GB", "FR"];

export const SUPPORTED_COUNTRIES_MAP = {
  GB: "🇬🇧 United Kingdom", 
  FR: "🇫🇷 France"
};

export const SUPPORTED_DEPARTURE_POINTS = {
  BOD: "🛫 Bordeaux-Mérignac (BOD)",
  CALAIS: "🚇 Calais Eurotunnel (CALAIS)",
  CALAISPORT: "🛳 Calais Ferry Port (CALAISPORT)",
  XDB: "🚄 Lille-Europe (XDB)",
  LYS: "🛫 Lyon Saint-Exupéry (LYS)",
  CDG: "🛫 Paris Charles de Gaulle (CDG)",
  XPG: "🚄 Paris Gare du Nord (XPG)",
  ORY: "🛫 Paris Orly (ORY)",
  NCE: "🛫 Nice Côte d'Azur (NCE)",
  TNF: "🚁 Toussus-le-Noble (TNF)",
  MRS: "🛫 Marseille-Provence (MRS)",
  XED: "🚄 Chessy Marne-la-Vallée (XED)",
  CCF: "🛫 Carcassonne (CCF)",
  FNI: "🛫 Nîmes-Garons (FNI）",
  BZR: "🛫 Béziers / Cap d’Agde (BZR)",
  MPL: "🛫 Montpellier Méditerranée (MPL)",
  CFR: "🛫 Caen-Carpiquet (CFR)",
  EGC: "🛫 Bergerac (EGC)",
  BIQ: "🛫 Biarritz (BIQ)",
  LIG: "🛫 Limoges Bellegarde (LIG)",
  NTE: "🛫 Nantes Atlantique (NTE)",
  FDF: "🛫 Lamentin (FDF)",
  CMF: "🛫 Chambéry-Savoie (CMF)",
  GNB: "🛫 Grenoble-Isère (GNB)",
  BSL: "🛫 Basel Mulhouse Freiburg (BSL)",
  SXB: "🛫️ Strasbourg Entzheim (SXB)",
  PMI: "🛫 Palma Majorca (PMI)",
  AMS: "🛫 Amsterdam Schiphol (AMS)",
  LIS: "🛫 Lisbon (LIS)",
  MXP: "🛫 Milan Malpensa (MXP)",
  MAD: "🛫 Madrid Barajas (MAD)",
  FCO: "🛫 Rome Fiumicino (FCO)",
  OTHER: "Other"
};

export const total = (receipts = []) =>
  round(
    receipts
      .filter((r) => r.status === "REVIEWED")
      .reduce((acc, r) => acc + (r.total || 0), 0)
  );

export const vat = (trip) => pipe(() => toItems(trip), vatItems)();

function toItems(trip) {
  return trip.receipts
    .filter((r) => r.status === "REVIEWED")
    .flatMap((r) => r.items);
}

export function totalItems(items = []) {
  items = Array.isArray(items) ? items : items.toArray();
  return round(
    items
      .map((i) => numberify(i.cost || 0))
      .reduce((acc, value) => acc + value, 0)
  );
}

export function vatItems(items = []) {
  items = Array.isArray(items) ? items : items.toArray();
  return round(items.map(toVatAmount).reduce((acc, value) => acc + value, 0));
}

export function tripComplete(trip) {
  if (trip.status === "COMPLETED" || trip.status === "COMPLETED" || trip.status === "ENDED") {
    return true;
  } else {
    return false;
  }
}

function toVatAmount(item) {
  const grossAmount = getCost(item);
  const vatMultiplier = 1 + rateToMultiplier(item.vatRate);
  const amountWithoutVat = grossAmount / vatMultiplier;
  return grossAmount - amountWithoutVat;
}

function getCost(item) {
  return numberify(item.cost || 0);
}

function rateToMultiplier(vatRate) {
  return numberify(vatRate || DEFAULT_VAT_PERCENTAGE) / 100;
}
