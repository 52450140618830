export const round = function (value, precision = 2) {
  return numberify(value || 0).toFixed(precision);
};

export const numberify = (val) => {
  if (isNaN(val)) {
    return 0;
  }
  return +val;
};

export const stringAdding = (strVal1, strVal2) => {
  return numberify(strVal1) + numberify(strVal2);
};
