export const DEFAULT_ITEM_VAT_RATE = 20;

export const REQUIRES_B2B_INVOICE_FLOOR = 250;

export const Tabs = {
  READY_TO_PAY: "READY_TO_PAY",
  NEW_RECEIPTS: "NEW_RECEIPTS",
  QA_RECEIPTS: "QA_RECEIPTS",
  USERS: "USERS",
  RETAILERS: "RETAILERS",
  REFERRALS: "REFERRALS",
  EXPORT: "EXPORT",
  LOGOUT: "LOGOUT"
};

export const TripStatus = {
  STARTED: "STARTED",
  COMPLETED: "COMPLETED",
  REJECTED: "REJECTED",
  NA: "NA",
  ALL: "ALL",
  ENDED: "ENDED",
  PLANNED: "PLANNED"
};

export const LogisticalStatusLabels = {
  GENERATED: "Generated",
  RECEIVED: "Received",
  MISSING: "Missing"
};

export const PaymentStatusLabels = {
  PAID: "Paid",
  READY_TO_PAY: "Ready do pay",
  NOT_PAID: "Not paid",
  LAPSED: "Lapsed"
};

export const ApprovalStatusLabels = {
  PENDING: "Pending",
  PRE_STAMPED: "Pre-stamped",
  STAMPED: "Stamped",
  REJECTED: "rejected"
};

export const PAYMENT_STATUSES = {
  PENDING: "PENDING",
  PROCESSING: "PROCESSING",
  PAID: "PAID",
  ERROR: "ERROR"
};

export enum FORM_PAYMENT_STATUSES {
  PAID = "PAID",
  READY_TO_PAY = "READY_TO_PAY",
  NOT_PAID = "NOT_PAID",
  PROCESSING_PAY = "PROCESSING_PAY", // Payment in progress
  LAPSED = "LAPSED"
}

export const LABELLED_PAYMENT_STATUSES = [
  {
    value: "PAID",
    label: "Paid"
  },
  {
    value: "READY_TO_PAY",
    label: "Ready to pay"
  },
  {
    value: "NOT_PAID",
    label: "Not paid"
  },
  {
    value: "PROCESSING_PAY",
    label: "Processing pay"
  },
  {
    value: "LAPSED",
    label: "Lapsed"
  }
];

export enum PAYMENT_METHODS {
  WECHAT = "WECHAT",
  ALIPAY = "ALIPAY",
  CHECKOUT = "CARD_PAYOUT",
  TRANSFERWISE = "BANK_TRANSFER",
  PAYPAL = "PAYPAL",
  UNIONPAY = "UNIONPAY"
}

export const LABELLED_PAYMENT_METHODS = [
  {
    value: "WECHAT",
    label: "WeChat Pay"
  },
  {
    value: "ALIPAY",
    label: "Alipay"
  },
  {
    value: "CARD_PAYOUT",
    label: "Checkout"
  },
  {
    value: "BANK_TRANSFER",
    label: "Wise"
  },
  {
    value: "PAYPAL",
    label: "PayPal"
  },
  {
    value: "UNIONPAY",
    label: "UnionPay"
  }
];

export const LABELLED_REMITTANCE_PROVIDERS = [
  {
    value: "WECHAT",
    label: "WeChat Pay"
  },
  {
    value: "ALIPAY",
    label: "Alipay"
  },
  {
    value: "CHECKOUT",
    label: "Checkout"
  },
  {
    value: "TRANSFERWISE",
    label: "Wise"
  },
  {
    value: "PAYPAL",
    label: "PayPal"
  },
  {
    value: "WYRE",
    label: "Wyre"
  },
  {
    value: "BARCLAYS",
    label: "Barclays"
  }
];

export const BACKEND_PAYABLE_METHODS = [
  "WECHAT",
  "ALIPAY",
  "CHECKOUT",
  "TRANSFERWISE",
  "PAYPAL"
];
