import React, { FC } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

import Button from "../../../components/Button";
import Tooltip from "../../../components/Tooltip";
import { selectIsWithContactSupport } from "../store";
import { useAppSelector } from "../../../hooks";
import "./ReceiptFooter.scss";

type Props = {
  isRejectBtnDisabled?: boolean;
  isRemoveBtnDisabled?: boolean;
  isConfirmDisabled?: boolean;
  isAttachBtnDisabled?: boolean;
  userId?: string;
  confirmLabel?: string;
  onContact?: () => void;
  onConfirm: () => void;
  onReject?: () => void;
  onRemove?: () => void;
  onAttach?: () => void;
};

const ReceiptFooter: FC<Props> = ({
  userId,
  onConfirm,
  onContact,
  onReject,
  onRemove,
  onAttach,
  confirmLabel = "Confirm",
  isConfirmDisabled = false,
  isRejectBtnDisabled = false,
  isRemoveBtnDisabled = false,
  isAttachBtnDisabled = false,
}) => {
  const isWithContactSupport = useAppSelector(selectIsWithContactSupport);

  return (
    <div className="receipt-footer-buttons">

      {onAttach && (
        <div
          onClick={onAttach}
          className={classNames("receipt-footer-buttons__square attach-btn", {
            "btn--disabled": isAttachBtnDisabled
          })}
        />
      )}


      <Button
        disabled={isConfirmDisabled}
        type="primary"
        className="receipt-footer-buttons-yes-button"
        onClick={onConfirm}
      >
        {confirmLabel}
      </Button>

      {userId && (
        <Tooltip tooltipText="See trip details" className="square_tooltip">
          <Link
            to={`/users/${userId}`}
            rel="noopener noreferrer"
            className="receipt-footer-buttons__square user-profile-btn"
          />
        </Tooltip>
      )}

      {onRemove && (
        <div
          onClick={onRemove}
          className={classNames("receipt-footer-buttons__square bin-btn", {
            "btn--disabled": isRemoveBtnDisabled
          })}
        />
      )}

      {onReject && (
        <div
          onClick={onReject}
          className={classNames("receipt-footer-buttons__square reject-btn", {
            "btn--disabled": isRejectBtnDisabled
          })}
        />
      )}

      {onContact && (
        <div
          className={classNames("receipt-footer-buttons__square help-btn", {
            "with-support": isWithContactSupport
          })}
          onClick={onContact}
        />
      )}
    </div>
  );
};

export default ReceiptFooter;
