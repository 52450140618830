import { merge } from "../../utils/functions";
import { ModalActions } from "../actions/modal-actions";

const initialState = {
  component: null,
  componentProps: {},
  modalProps: {
    show: false,
    type: "small"
  }
  //history: {}
};

export const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case ModalActions.OPEN_MODAL:
      return merge(state, {
        component: action.component,
        componentProps: action.componentProps,
        modalProps: merge(state.modalProps, { show: true, type: action.modalProps?.type || initialState.modalProps.type })
      });

    case ModalActions.CLOSE_MODAL:
      return initialState;

    default:
      return state;
  }
};
