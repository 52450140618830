import { formatCurrencyMoney } from "../../utils/currency";
import { Receipt, Trip } from "./store";

export const getDefaultReceiptState = (): Receipt => ({
  receiptId: "",
  receiptNumber: "",
  status: "CONFIRM_REJECTION",
  uploadedTime: "",
  purchaseTime: "",
  lastUpdated: "",
  imageUrls: [],
  rejectionReasons: [],
  items: [],
  b2bInvoiceImageUrl: "",
  phoneNumber: "",
  address: "",
  establishment: "",
  isOpen: false,
  updatedBy: { id: "", name: "" },
  verifiedBy: { id: "", name: "" },
  tripId: "",
  totalConfidence: 0,
  ocrTaxTotal: 0,
  ocrTotal: 0,
  total: 0,
  taxTotal: 0,
  currency: ""
});

export const getDefaultTripState = (): Trip => ({
  airport: "",
  arrivalDate: "",
  countryCode: "",
  departureDate: "",
  userId: "",
  status: "",
  tripId: ""
});

export const getCurrencySymbolFromCode = (code: string) => {
  return code ? formatCurrencyMoney(0, code).split("0")[0].trim() : "";
};
