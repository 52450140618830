import { RootState } from "../store";

export const selectRetailers = ({ global }: RootState) => global.retailers;
export const selectReviewers = ({ global }: RootState) => global.reviewers;

export const selectError = ({ global }: RootState) => global.error;
export const selectErrorMessage = ({ global }: RootState) =>
  global.errorMessage;

export const selectRetailerId = ({ global }: RootState) => global.retailerId;

export const selectNonAutomatedRejectionReasons = ({ global }: RootState) => {
  return global.rejectionReasons.filter((r) => !r.isAutomated);
};

export const selectRejectionReasons = ({ global }: RootState) => {
  return global.rejectionReasons;
};
