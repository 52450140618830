import { store } from "react-easy-state";
import ReceiptVerificationState from "./ReceiptVerificationState";
import { numberify } from "../../../utils/numbers";

export const ReceiptVerificationUIState = store({
  show: false,
  selectedRowsIndexes: [],
  isOpenModalIsDismissed: false,
  //
  open() {
    _this.show = true;
  },
  close() {
    _this.show = false;

    _this.reset();
    ReceiptVerificationState.reset();
  },
  reset() {
    _this.selectedRowsIndexes = [];
    _this.isOpenModalIsDismissed = false;
    _this.step = 1;
  },
  dismissIsOpenModal() {
    _this.isOpenModalIsDismissed = true;
  },
  isEveryRowSelected() {
    return (
      _this.selectedRowsIndexes.length !== 0 &&
      _this.selectedRowsIndexes.length === ReceiptVerificationState.items.length
    );
  },
  isAnyRowSelected() {
    return _this.selectedRowsIndexes.length === 0;
  },
  isItemSelected(index) {
    return _this.selectedRowsIndexes.indexOf(index) !== -1;
  },
  toggleSelectAllItems() {
    if (!_this.isEveryRowSelected()) {
      _this.selectedRowsIndexes = Object.keys(
        ReceiptVerificationState.items
      ).map(numberify);
    } else {
      _this.selectedRowsIndexes = [];
    }
  },
  toggleItemSelection(index) {
    if (_this.selectedRowsIndexes.indexOf(index) !== -1)
      _this.selectedRowsIndexes = _this.selectedRowsIndexes.filter(
        (i) => i !== index
      );
    else _this.selectedRowsIndexes.push(index);
  },
  changeItem(index, change) {
    const item = ReceiptVerificationState.items[index];

    ReceiptVerificationState.changeItem(index, { ...item, ...change });
  },
  removeSelectedItems() {
    ReceiptVerificationState.removeItems(_this.selectedRowsIndexes);
    _this.selectedRowsIndexes = [];
  },
  setVaxRateOnSelectedItems(vatRate) {
    _this.selectedRowsIndexes.forEach((index) => {
      const item = ReceiptVerificationState.items[index];

      ReceiptVerificationState.changeItem(index, { ...item, vatRate });
    });
  },
  toggleAirsideOnSelectedItems() {
    _this.selectedRowsIndexes.forEach((index) => {
      const item = ReceiptVerificationState.items[index];

      ReceiptVerificationState.changeItem(index, {
        ...item,
        airside: !item.airside
      });
    });
  },
  //
  step: 1,
  forward() {
    _this.step = 2;
  },
  back() {
    _this.step = 1;
  }
});
const _this = ReceiptVerificationUIState;

export default ReceiptVerificationUIState;