import { List } from "immutable";

export function compose(f1, f2) {
  const len = arguments.length;
  const funcs = Array(len);

  for (let i = 0; i < len; i++) {
    funcs[i] = arguments[i];
  }

  if (funcs.length === 0) {
    return function (arg) {
      return arg;
    };
  }

  if (funcs.length === 1) {
    return funcs[0];
  }

  return funcs.reduce(function (a, b) {
    return function () {
      return a(b.apply(undefined, arguments));
    };
  });
}

export function pipe(f1, f2) {
  const len = arguments.length;
  const funcs = Array(len);

  for (let i = 0; i < len; i++) {
    funcs[i] = arguments[i];
  }

  if (funcs.length === 0) {
    return function (arg) {
      return arg;
    };
  }

  if (funcs.length === 1) {
    return funcs[0];
  }

  return funcs.reduce(function (a, b) {
    return function () {
      return b(a.apply(undefined, arguments));
    };
  });
}

export function merge(state, obj) {
  return Object.assign({}, { ...state }, { ...obj });
}

export function mergeLeft(obj, state) {
  return merge(state, obj);
}

export function either(obj, other) {
  if (obj === undefined || obj === "undefined" || obj === null) {
    return other;
  }
  return obj;
}

export function maybe(obj) {
  if (obj === undefined || obj === "undefined" || obj === null) {
    return {};
  }
  return obj;
}

export function log(val) {
  console.log(val);
  return val;
}

export function sliceProp(obj, key) {
  const newObj = {};
  Object.keys(obj)
    .filter((k) => k !== key)
    .forEach((k) => (newObj[k] = obj[k]));
  return newObj;
}

export function listify(val) {
  if (Array.isArray(val)) {
    return List(val).map(listify);
  }

  if (typeof val === "object" && val !== null) {
    const listifyAndMergeKey = function (acc, k) {
      return Object.assign({}, { ...acc, [k]: listify(val[k]) });
    };

    return Object.keys(val).reduce(listifyAndMergeKey, {});
  }

  return val;
}

export function isBeta() {
  return document.location.pathname.includes("/beta");
}

export function isQABeta() {
  return document.location.pathname.includes("/beta-qa");
}
