import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import "./css/validator.scss";
import Badge from "./Badge";

export const VALIDATORS = {
  NUMBER: {
    BETWEEN: (min, max) => (value) =>
      (value >= min && value <= max) || `Must be between ${min} and ${max}`,
    MORE_THAN: (min) => (value) =>
      value >= min || `Must be equal or more than ${min}`,
    LESS_THAN: (max) => (value) =>
      value <= max || `Must be equal or less than ${max}`,
    INCLUDES:
      (values = []) =>
      (value) => {
        return values.includes(value) || `Must be within the allowed values`;
      }
  },
  VAT_RATE: {
    FR: () => (value) =>
      (value == 20 || value == 5.5 || value == 10 || value == 2.1) || `Is that right? Options: 20%, 5.5%, 10% or 2.1%`,
    GENERIC: () => (value) => 
      (value <= 30 && value>0 ) || `Vat rate must between 0% and 30%`,
  },
  DATE: {
    BETWEEN: (min, max) => (value) => value >= min && value <= max,
    MORE_THAN: (min) => (value) => value >= min,
    LESS_THAN: (max) => (value) => value <= max
  },
  TEXT: {
    NOT_EMPTY: (value) => value !== "" || `Must not be empty`
  }
};


const ErrorTooltip = ({ text, position = "left" }) => (
  <div
    className={classNames("tooltip", {
      tooltip__right: position === "right"
    })}
  >
    <Badge type="red">
      !<span className="tooltiptext">{text}</span>
    </Badge>
  </div>
);

class Validator extends React.Component {
  state = { valid: true };

  componentDidUpdate() {
    if (this.props.reset === true && this.state.valid !== true) {
      this.setState({ valid: true });
    }
  }

  onChangeValidator = (_onChange) => (value) => {
    const { validator } = this.props;

    this.setState({ valid: validator(value) });
    _onChange(value);
  };

  render() {
    const { valid } = this.state;

    return React.Children.toArray(this.props.children).map((child, i) => (
      <React.Fragment key={`validator-item-${i}`}>
        {React.cloneElement(child, {
          onChange: this.onChangeValidator(child.props.onChange),
          children: (
            <React.Fragment>
              {child.props.children}
              {valid !== true ? (
                <div className="wevat-validator-error-message">
                  <ErrorTooltip
                    position={this.props.tipPosition}
                    text={valid}
                  />
                </div>
              ) : null}
            </React.Fragment>
          ),
          style: valid === true ? null : { border: "1px solid red" }
        })}
      </React.Fragment>
    ));
  }
}

Validator.propTypes = {
  tipPosition: PropTypes.oneOf(["left", "right"]),
  validator: PropTypes.func.isRequired,
  reset: PropTypes.bool
};

export default Validator;
