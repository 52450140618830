import { merge } from "@utils/functions";
import { updateRetailerRequest } from "@api/add-retailer/add-retailer-service";
import { setNetworkError } from "../global";
import { RetailersTableAction } from "@store/reducers/retailers-table-reducer"
import Toast from "@components/Toast";
import Popup from "@utils/Popup";

export const EditRetailerActions = {
  SET_RETAILERS_LIST: "EDIT_RETAILER_SET_RETAILERS_LIST",
  OPEN_EDIT_RETAILER: "EDIT_RETAILER_OPEN",
  CLOSE_EDIT_RETAILER: "EDIT_RETAILER_CLOSE",
  NAME_CHANGED: "EDIT_RETAILER_NAME_CHANGED",
  COUNTRY_CHANGED: "EDIT_RETAILER_COUNTRY_CHANGED",
  VATNUMBER_CHANGED: "EDIT_RETAILER_VATNUMBER_CHANGED",
  IMPORT_VATNUMBER_CHANGED: "EDIT_RETAILER_IMPORT_VATNUMBER_CHANGED",
  SIREN_CHANGED: "EDIT_RETAILER_SIREN_CHANGED",
  SIRET_CHANGED: "EDIT_RETAILER_SIRET_CHANGED",
  ADDRESS_LINE_CHANGED: "ADDRESS_LINE_CHANGED",
  ADDRESS_POSTAL_CODE_CHANGED: "EDIT_RETAILER_ADDRESS_POSTAL_CODE_CHANGED",
  ADDRESS_CITY_CHANGED: "EDIT_RETAILER_ADDRESS_CITY_CHANGED",
  ITEMS_WITH_VAT_CHANGED: "EDIT_RETAILER_ITEMS_WITH_VAT_CHANGED",
  DEFAULT_CATEGORY_CHANGED: "EDIT_RETAILER_DEFAULT_CATEGORY_CHANGED",
  ITEM_EXTRACTION_CHANGED: "EDIT_RETAILER_ITEM_EXTRACTION_CHANGED",
  IS_BRAND_RESPRESENTATIVE_CHANGED: "EDIT_RETAILER_IS_BRAND_RESPRESENTATIVE_CHANGED",
  RETAILER_EDIT_STARTED: "EDIT_RETAILER_STARTED",
  RETAILER_EDIT_SUCCESS: "EDIT_RETAILER_SUCCESS",
};

export const startEditRetailer = (dispatch) => (retailer) =>{
  dispatch({ 
    type: EditRetailerActions.OPEN_EDIT_RETAILER,
    retailer: retailer
  });
}
export const stopEditRetailer = (dispatch) => () =>
  dispatch({ type: EditRetailerActions.CLOSE_EDIT_RETAILER });
export const onNameChanged = (dispatch) => (retailerName) =>
  dispatch({ type: EditRetailerActions.NAME_CHANGED, retailerName });
export const onCountryChanged = (dispatch) => (country) =>
  dispatch({ type: EditRetailerActions.COUNTRY_CHANGED, country });
export const onVatnumberChanged = (dispatch) => (vatnumber) =>
  dispatch({ type: EditRetailerActions.VATNUMBER_CHANGED, vatnumber });
export const onSiretChanged = (dispatch) => (siret) =>
  dispatch({ type: EditRetailerActions.SIRET_CHANGED, siret });
export const onSirenChanged = (dispatch) => (siren) =>
  dispatch({ type: EditRetailerActions.SIREN_CHANGED, siren });
export const onAddressLineChanged = (dispatch) => (addressLine) =>
  dispatch({ type: EditRetailerActions.ADDRESS_LINE_CHANGED, addressLine });
export const onAddressPostalCodeChanged = (dispatch) => (addressPostalCode) =>
  dispatch({ type: EditRetailerActions.ADDRESS_POSTAL_CODE_CHANGED, addressPostalCode });
export const onAddressCityChanged = (dispatch) => (addressCity) =>
  dispatch({ type: EditRetailerActions.ADDRESS_CITY_CHANGED, addressCity });
export const onItemsWithVatChanged = (dispatch) => (itemsWithVat) => 
  dispatch({ type: EditRetailerActions.ITEMS_WITH_VAT_CHANGED, itemsWithVat: itemsWithVat === "GROSS" })
export const onDefaultCategoryChanged = (dispatch) => (defaultCategory) => 
  dispatch({ type: EditRetailerActions.DEFAULT_CATEGORY_CHANGED, defaultCategory });
export const onOcrItemExtractionChanged = (dispatch) => (ocrItemExtraction) =>
  dispatch({ type: EditRetailerActions.ITEM_EXTRACTION_CHANGED, ocrItemExtraction: ocrItemExtraction === "YES" });
export const   onIsBrandRepresentativeChanged = (dispatch) => (isBrandRepresentative) => {
  const isBrandRepresentativeAsBool = isBrandRepresentative === "YES"
  Popup.fire({
    title:
      "Are you sure?",
    text: isBrandRepresentativeAsBool ? "We may have another representative shop, if you select this one you may be showing two representatives for the same brand. Please be sure there is no other representative." : "By disabling this brand representative you may be removing all representatives for this brand. Please be sure you have at least one representative otherwise we will not be able to show it in the app.",
    icon: "warning",
    showCancelButton: false,
    confirmButtonText: isBrandRepresentativeAsBool ? "Ok I understand that I will make it a representative!" : "Ok I understand that this shop is no longer be a representative!"
  }).then((result) => {
    if (result.value) {
      dispatch({ type: EditRetailerActions.IS_BRAND_RESPRESENTATIVE_CHANGED,   isBrandRepresentative: isBrandRepresentativeAsBool });
    }
  })
  
  
}
  


export const editRetailer = function (dispatch) {
  return function ({
    retailerId,
    retailerName,
    country,
    addressLine,
    addressPostalCode,
    addressCity,
    vatnumber,
    siret,
    siren,
    itemsWithVat,
    defaultCategory,
    ocrItemExtraction,
    isBrandRepresentative,
  }) {
    dispatch({ type: EditRetailerActions.RETAILER_EDIT_STARTED });

    const newRetailer = {
      retailerName,
      country,
      addressLine,
      addressPostalCode,
      addressCity,
      vatnumber,
      siret,
      siren,
      itemsWithVat,
      defaultCategory,
      ocrItemExtraction,
      isBrandRepresentative,
    };

    return updateRetailerRequest(retailerId, newRetailer)
      .then(() => {
        dispatch({
          type: EditRetailerActions.RETAILER_EDIT_SUCCESS
        });
        dispatch({
          type: RetailersTableAction.RETAILERS_TABLE_RELOAD
        });
        dispatch({ type: EditRetailerActions.CLOSE_EDIT_RETAILER });

        // TODO this should updarte a variable to show toast instead of using component here
        Toast({
          title: `Successfully updated retailer ${newRetailer.retailerName}`,
          icon: "success"
        });
      })
      .catch((error) => {
        console.log("Error while editing retailer " + error)
        dispatch(setNetworkError(error))
      });
  };
};


const initialState = {
  retailerId: null,
  showRetailerEdit: false,
  retailerName: "",
  country: "FR",
  vatnumber: "",
  siret: null,
  siren: null,
  addressLine: null,
  addressPostalCode: null,
  addressCity: null,
  isLoading: false,
  disableEditRetailerButtons: false,
  itemsWithVat: true,
  defaultCategory: "60",
  ocrItemExtraction: false,
  isBrandRepresentative: false,
};

export const editRetailerModal = (state = initialState, action) => {

  switch (action.type) {
    case EditRetailerActions.OPEN_EDIT_RETAILER:
      return merge(state, { 
        showRetailerEdit: true,
        retailerName: action.retailer?.retailerName,
        retailerId: action.retailer?.retailerId,
        country: action.retailer?.country || "FR",
        addressLine: action.retailer?.addressLine,
        addressPostalCode:  action.retailer?.addressPostalCode,
        addressCity: action.retailer?.addressCity,
        vatnumber: action.retailer?.vatNumber,
        siret: action.retailer?.siret,
        siren: action.retailer?.siren,
        itemsWithVat: action.retailer?.itemsWithVat,
        defaultCategory: action.retailer?.defaultCategory,
        ocrItemExtraction: action.retailer?.ocrItemExtraction,
        isBrandRepresentative: action.retailer?.isBrandRepresentative,
      });

    case EditRetailerActions.CLOSE_EDIT_RETAILER:
      return initialState;

    case EditRetailerActions.NAME_CHANGED:
      return merge(state, { retailerName: action.retailerName });

    case EditRetailerActions.COUNTRY_CHANGED:
      return merge(state, { country: action.country });

    case EditRetailerActions.VATNUMBER_CHANGED:
      return merge(state, { vatnumber: action.vatnumber });

    case EditRetailerActions.SIREN_CHANGED:
      return merge(state, { siren: action.siren });

    case EditRetailerActions.SIRET_CHANGED:
      return merge(state, { siret: action.siret });

    case EditRetailerActions.ADDRESS_LINE_CHANGED:
      return merge(state, { addressLine: action.addressLine });

    case EditRetailerActions.ADDRESS_POSTAL_CODE_CHANGED:
      return merge(state, { addressPostalCode: action.addressPostalCode });

    case EditRetailerActions.ADDRESS_CITY_CHANGED:
      return merge(state, { addressCity: action.addressCity });

    case EditRetailerActions.DEFAULT_CATEGORY_CHANGED:
      return merge(state, { defaultCategory: action.defaultCategory });

    case EditRetailerActions.ITEMS_WITH_VAT_CHANGED:
      return merge(state, { itemsWithVat: action.itemsWithVat });

    case EditRetailerActions.ITEM_EXTRACTION_CHANGED:
      return merge(state, { ocrItemExtraction: action.ocrItemExtraction });

    case EditRetailerActions.IS_BRAND_RESPRESENTATIVE_CHANGED:
      return merge(state, { isBrandRepresentative: action.isBrandRepresentative });

    case EditRetailerActions.SET_RETAILERS_LIST:
      return merge(state, { retailers: action.retailers });

    case EditRetailerActions.RETAILER_EDIT_STARTED:
      return merge(state, { disableEditRetailerButtons: true })

    case EditRetailerActions.RETAILER_EDIT_SUCCESS:
     return merge(initialState, { needsLoad: true })

    default:
      return state;
  }
};
