import { combineReducers } from "redux";

import { global } from "./global";
import { main } from "./reducers/main-reducer";
import { userSummary } from "./reducers/user-summary-reducer";
import { userProfileState } from "./reducers/user-profile-state-reducer";
import { selectedTripState } from "./reducers/selected-trip-reducer";
import { retailerDropdown } from "./reducers/retailer-dropdown-reducer";
import { retailerState } from "./reducers/retailer-state-reducer";
import { addRetailerModal } from "./reducers/add-retailer-reducer";
import { editRetailerModal } from "./reducers/edit-retailer-reducer";
import { formLoggingModal } from "./reducers/form-logging-reducer";
import { paymentForm } from "./reducers/payment-form-reducer";
import { userProfileTabView } from "./reducers/user-profile-tab-view-reducer";
import { retailersTable } from "./reducers/retailers-table-reducer";
import { modalReducer } from "./reducers/modal-reducer";
import { addReceiptReducer } from "./reducers/add-receipt-reducer";
import { addTripReducer } from "./reducers/add-trip-reducer";

import { receiptVerification } from "../views/receipt";
import { receiptsTable } from "../views/receipts";

const reducers = combineReducers({
  global,
  main,
  userSummary,
  userProfileState,
  userProfileTabView,
  selectedTripState,
  formLoggingModal,
  paymentForm,
  retailerDropdown,
  retailerState,
  addRetailerModal,
  editRetailerModal,
  retailersTable,
  modalReducer,
  addReceiptReducer,
  addTripReducer,
  receiptVerification,
  receiptsTable
});

export default reducers;
