import { merge } from "../../utils/functions";
import { AddRetailerActions } from "./add-retailer-reducer";
import { ReceiptViewActions } from "../actions/receipt-view-actions";
import ReceiptVerificationState from "../../views/receipt/stores/ReceiptVerificationState";

export const RetailerDropdownActions = {
  TEXT_CHANGED: "TEXT_CHANGED"
};

export const onRetailerChanged = (dispatch) => (retailerName, retailerId) => {
  dispatch({ type: AddRetailerActions.NAME_CHANGED, retailerName });
  dispatch({
    type: RetailerDropdownActions.TEXT_CHANGED,
    retailerId,
    retailerName
  });
  if (retailerId)
    ReceiptVerificationState.setRetailer({ retailerId, retailerName });
};

const initialState = {
  retailerName: "",
  retailerId: ""
};

export const retailerDropdown = (state = initialState, action) => {
  switch (action.type) {
    case RetailerDropdownActions.TEXT_CHANGED:
      return merge(state, {
        retailerName: action.retailerName,
        retailerId: action.retailerId
      });

    case AddRetailerActions.CREATE_RETAILER_SUCCESS:
      return merge(state, {
        retailerName: action.retailer.retailerName,
        retailerId: action.retailer.retailerId
      });

    case ReceiptViewActions.OPEN_RECEIPT_VIEW:
    case ReceiptViewActions.OPEN_RECEIPT_VIEW_V2:
      return onReceiptOpened(state, action.receipt);

    default:
      return state;
  }
};

function onReceiptOpened(state, receipt) {
  var st = {
    retailerName: "",
    retailerId: ""
  };
  if (receipt.retailer) {
    st["retailerName"] = receipt.retailer.retailerName;
    st["retailerId"] = receipt.retailer.retailerId;
  }
  return st;
}
