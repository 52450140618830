import { takeLatest, call, put, select } from "redux-saga/effects";

import * as api from "@src/api";
import * as slice from "./receipt-slice";
import * as selector from "@store/selectors";
import { setNotification } from "@store/actions";
import { selectReceipts, getReceipts } from "../../receipts";

export function* receiptVerificationInit() {
  yield takeLatest(slice.openReceipt.type, handleOpenReceipt);
  yield takeLatest(slice.saveReceiptChanges.type, handleSaveReceipt);
}

function* handleOpenReceipt() {
  const receipts = selectReceipts(yield select());
  const itemCategories = selector.selectItemCategories(yield select());

  if (!receipts.length) yield put(getReceipts({ isPooling: false }));
  if (!itemCategories.length) yield call(handleItemCategories);

  yield call(getReceiptData);
}

function* getReceiptData() {
  const receiptId = selector.selectReceiptId(yield select());
  const tripId = selector.selectTripId(yield select());
  const receipts = selectReceipts(yield select());

  const receiptData = yield call(api.fetchReceiptData, receiptId);
  const errorState = {
    error: true,
    isLoading: false,
    fetchNextReceipt: false
  };

  if (receiptData.error) {
    return yield put(slice.updateState(errorState));
  }

  const { error, ...tripData } = yield call(
    api.fetchTrip,
    tripId || receiptData.tripId
  );

  if (error) {
    return yield put(slice.updateState(errorState));
  }

  const currentIndex = receipts.findIndex((r) => r.receiptId === receiptId);
  const isLastIndex = currentIndex + 1 >= receipts.length;
  const receiptIndex = isLastIndex ? receipts.length : currentIndex;

  yield put(
    slice.updateState({
      fetchNextReceipt: false,
      currentIndex,
      isLastIndex,
      receiptIndex,
      isSkipModalActive: receiptData.isOpen,
      isRejected: receiptData.status === "REJECTED",
      isAccepted: receiptData.status === "REVIEWED",
      isDeleted: receiptData.status === "DELETED"
    })
  );
  yield put(
    slice.setReceipt({
      receipt: receiptData,
      trip: tripData,
      items: receiptData.items
    })
  );

  yield call(api.markReceiptAsOpen, receiptId);
}

function* handleItemCategories() {
  const response = yield call(api.getItemCategories);
  const orderListByCode = [...response].sort((a, b) => a.code - b.code);
  yield put(slice.setItemCategories(orderListByCode));
}

function* handleSaveReceipt(action?) {
  const {
    status: actionStatus = "",
    isReverify,
    isFirstStep
  } = action?.payload ?? {};

  const goToNextReceipt = action?.payload?.nextReceipt ?? false;

  const reasons = selector.selectRejectionReasons(yield select());
  const receiptId = selector.selectReceiptId(yield select());
  const retailerId = selector.selectRetailerId(yield select());
  const { totalSum, vatSum } = selector.selectSums(yield select());
  const hasRejectionReasons = selector.selectHasRejectionReasons(
    yield select()
  );

  const {
    receiptNumber,
    status,
    purchaseTime,
    rejectionReasons,
    items,
    rejectionReasonNotes = "",
    withoutTax,
    isDigitalReceipt,
  } = selector.selectReceipt(yield select());

  const generateStatus = () => {
    if (hasRejectionReasons) return "REJECTED";

    const fallbackStatus = status === "REJECTED" ? "PENDING" : status;
    return actionStatus || fallbackStatus;
  };

  const requestBody = {
    retailerId,
    receiptNumber,
    receiptId,
    purchaseTime,
    rejectionReasons,
    isDigitalReceipt,
    items: items.map((item) => ({
      ...item,
      cost: withoutTax ? null : item?.cost ?? null,
      costWithoutVat: withoutTax ? item?.cost ?? null : null,
      vatRate: parseFloat(`${item.vatRate}`),
      categoryCode: item?.categoryCode ?? null
    })),
    total: totalSum,
    taxTotal: vatSum,
    status: generateStatus(),
    rejectionReasonNotes
  };

  const { isError} = yield call(api.updateReceipt, requestBody);

  if (isError) {
    return yield put(
      slice.updateState({ isLoading: false, fetchNextReceipt: false })
    );
  } else {
    if (hasRejectionReasons) {
      const listRejected = rejectionReasons
        .map((id) => reasons.find((r) => r.id === id)?.name ?? "")
        .join(", ");

      yield put(setNotification({ message: `Rejected: ${listRejected}` }));
    }
  }

  if (goToNextReceipt) {
    yield put(slice.updateState({ fetchNextReceipt: true }));
  } else {
    const data: any = { isLoading: false };
    if (isFirstStep !== undefined) data.isFirstStep = isFirstStep;
    if (isReverify !== undefined) data.isReverify = isReverify;
    yield put(slice.updateState(data));
  }
}
