import { store } from "react-easy-state";
import { UserReceiptsStore } from "../views/trips/receipt-details/view/ReceiptDetailsView";

const UserDetailsStore = store({
  tripId: undefined,
  selectTrip(tripId) {
    UserDetailsStore.tripId = tripId;
    UserReceiptsStore.refreshReceipts(tripId);
  }
});

export default UserDetailsStore;
