import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ReceiptState } from "./types";
import { getDefaultReceiptState, getDefaultTripState } from "../helpers";

const initialState = (): ReceiptState => ({
  totalSum: 0,
  vatSum: 0,
  currentIndex: 0,
  isLastIndex: false,
  receiptIndex: 0,
  isRejected: false,
  isAccepted: false,
  isReverify: false,
  isLoading: true,
  isFirstStep: true,
  isDeleted: false,
  receiptId: "",
  tripId: "",
  itemCategories: [],
  receipt: getDefaultReceiptState(),
  purchaseDateTime: "",
  isSkipModalActive: false,
  error: false,
  fetchNextReceipt: false,
  trip: getDefaultTripState(),
  receiptList: []
});

const receiptVerification = createSlice({
  name: "receiptVerification",
  initialState: initialState(),
  reducers: {
    openReceipt: (state, action) => ({
      ...state,
      isLoading: true,
      receiptId: action.payload
    }),
    resetReceipt: (state) => {
      const { receiptList, ...rest } = initialState();

      return {
        ...rest,
        receiptList: state.receiptList,
        fetchNextReceipt: false,
        itemCategories: state.itemCategories
      };
    },
    setTripId: (state, action) => ({
      ...state,
      tripId: action.payload
    }),

    setItemCategories: (state, action) => ({
      ...state,
      itemCategories: [...action.payload]
    }),
    setReceipt: (state, action) => ({
      ...state,
      isLoading: false,
      error: false,
      receipt: { ...action.payload.receipt },
      trip: { ...action.payload.trip }
    }),
    updateReceipt: (state, action) => ({
      ...state,
      receipt: { ...state.receipt, ...action.payload }
    }),
    addNewReceiptItem: (state, action) => ({
      ...state,
      receipt: {
        ...state.receipt,
        items: [...state.receipt.items, action.payload]
      }
    }),
    updateState: (state, action: PayloadAction<Partial<ReceiptState>>) => ({
      ...state,
      ...action.payload
    }),
    saveReceiptChanges: (
      state,
      action?: PayloadAction<{ status?: string; isLoading?: false }>
    ) => {
      const { isLoading } = action?.payload;
      return {
        ...state,
        isLoading: isLoading !== undefined ? isLoading : true
      };
    },
    updateSums: (state, action) => ({
      ...state,
      totalSum: action.payload.totalSum,
      vatSum: action.payload.vatSum
    }),
    setIsSkipModalActive: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isSkipModalActive: action.payload
    }),
    setReceiptViewList: (state, action) => ({
      ...state,
      receiptList: action.payload
    }),
    clearReceiptViewList: (state) => ({
      ...state,
      receiptList: []
    })
  }
});

export default receiptVerification.reducer;

export const {
  setTripId,
  openReceipt,
  setItemCategories,
  setReceipt,
  resetReceipt,
  updateReceipt,
  updateState,
  addNewReceiptItem,
  saveReceiptChanges,
  updateSums,
  setIsSkipModalActive,
  setReceiptViewList,
  clearReceiptViewList
} = receiptVerification.actions;
