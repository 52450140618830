import React, { FC, useEffect } from "react";
import {
  RViewer,
  RViewerTrigger
} from "../../../components/react-viewerjs/src/index.js";

import "./ReceiptViewer.scss";

type Props = {
  totalConfidence?: number;
  receiptUrl: string;
};

const OPTIONS = {
  inline: true,
  backdrop: false,
  fullscreen: false,
  button: false,
  title: false,
  navbar: false,
  toolbar: {
    prev: 0,
    play: 0,
    next: 0,
    flipHorizontal: 0,
    flipVertical: 0,
    rotateLeft: 1,
    rotateRight: 0,
    reset: 1
  },
  get container() {
    return document.getElementById("rvm-receipt-viewer");
  }
};

export const ReceiptViewer: FC<Props> = ({
  receiptUrl,
  totalConfidence = 0
}) => {
  const activateButtonRef = React.useRef(null);

  useEffect(() => {
    activateButtonRef.current?.click();
  }, []);

  return (
    <div id="rvm-receipt-viewer">
      <RViewer options={OPTIONS} imageUrls={receiptUrl} index={1}>
        <RViewerTrigger>
          <button ref={activateButtonRef} style={{display: 'none'}}>one image preview</button>
        </RViewerTrigger>
      </RViewer>
      {!!totalConfidence && (
        <div className="receipt-ocr">OCR: {totalConfidence * 100}%</div>
      )}
    </div>
  );
};
