export interface Receipt {
  receiptId: string;
  status: ReceiptStatus;
  uploadedTime: string;
  purchaseTime: string;
  lastUpdated: string;
  retailer: Retailer;
  imageUrls?: string[];
  rejectionReasons?: ReceiptRejectionReasons[];
  items?: Item[];
  isOpen: boolean;
  updatedBy: UpdatedByOrVerifiedBy;
  verifiedBy: UpdatedByOrVerifiedBy;
  qaBy: UpdatedByOrVerifiedBy;
  qaAt: string;
  tripId: string;
  total: number;
  taxTotal: number;
}

export interface Retailer {
  retailerId: string;
  retailerName: string;
  vatnumber: string;
}

export interface Item {
  itemId: string;
  description: string;
  quantity: number;
  cost: string;
  vatRate: number;
  airside: boolean;
}

export interface UpdatedByOrVerifiedBy {
  id: string;
  name: string;
}

export enum ReceiptStatus {
  PENDING = "PENDING",
  NEEDS_REVIEW = "NEEDS_REVIEW",
  CONFIRM_REJECTION = "CONFIRM_REJECTION",
  REJECTED = "REJECTED",
  REVIEWED = "REVIEWED",
  DELETED = "DELETED",
  REQUIRES_QUALITY_ASSURANCE = "REQUIRES_QUALITY_ASSURANCE",
}

export enum ReceiptRejectionReasons {
  DEFAULT = "DEFAULT",
  REFUND_FORM_ALREADY = "REFUND_FORM_ALREADY",
  RECEIPT_IMAGE_INCOMPLETE = "RECEIPT_IMAGE_INCOMPLETE",
  RECEIPT_TOO_OLD = "RECEIPT_TOO_OLD",
  RECEIPT_BEFORE_ARRIVAL_DATE = "RECEIPT_BEFORE_ARRIVAL_DATE",
  GREATER_THAN_250 = "GREATER_THAN_250",
  NO_VAT_CHARGED = "NO_VAT_CHARGED",
  NOT_ELIGIBLE_ONLINE = "NOT_ELIGIBLE_ONLINE",
  NOT_ELIGIBLE_SERVICE = "NOT_ELIGIBLE_SERVICE",
  SEND_VIA_EMAIL = "SEND_VIA_EMAIL",
  CONTACT_SUPPORT = "CONTACT_SUPPORT",
  UNCLEAR_PHOTO = "UNCLEAR_PHOTO",
  NOT_VALID_RECEIPT = "NOT_VALID_RECEIPT",
  NON_UK_RECEIPT = "NON_UK_RECEIPT",
  DUPLICATE_RECEIPT = "DUPLICATE_RECEIPT",
  NOT_VALID_VAT_RECEIPT = "NOT_VALID_VAT_RECEIPT",
  RECEIPT_BEFORE_TERMS_AND_CONDITIONS = "RECEIPT_BEFORE_TERMS_AND_CONDITIONS",
}

export interface ReceiptListItem {
  receiptId: string;
  uploaded: string;
  status: ReceiptStatus;
  itemsCount: number;
  total: number;
  isOpen: boolean;
  retailer: Retailer;
  trip: Trip;
}

export interface Trip {
  id: string;
  departureDate: string;
}

export enum QAActionType {
  APPROVED = "APPROVED",
  MODIFIED = "MODIFIED",
}

export enum LoginProvider {
  WECHAT = "WECHAT",
  FACEBOOK = "FACEBOOK",
  GOOGLE = "GOOGLE",
  EMAIL = "EMAIL",
}
