import React from "react";
import { connect } from "react-redux";
import WevatModal from "../../../../components/modals/WevatModal";
import { closeAddReceipt } from "../../../../store/reducers/add-receipt-reducer";
import Toast from "../../../../components/Toast";
import { UserReceiptsStore } from "./ReceiptDetailsView";
import { uploadAndAddReceipt } from "../../../../store/actions/add-receipt-async-actions";

const mapStateToProps = (state) => ({
  ...state.addReceiptReducer,
  tripId: state.selectedTripState.selectedTripId
});

const mapDispatchToProps = (dispatch) => ({
  closeAddReceipt: closeAddReceipt(dispatch)
});

const AddReceiptModal = (props) => (
  <WevatModal
    type="small"
    show={props.show}
    showCloseButton={true}
    onHide={props.closeAddReceipt}
  >
    <UploadReceipt {...props} />
  </WevatModal>
);

const UploadReceipt = ({ tripId, closeAddReceipt }) => (
  <div className="upload-form">
    <input
      type="file"
      accept="image/*,application/pdf"
      multiple={true}
      onChange={(event) => {
        Promise.all(
          Array.from(event.target.files).map((file) =>
            uploadAndAddReceipt(file, tripId)
          )
        ).then((returnValues) => {
          Toast({
            title: `Successfully uploaded ${returnValues.length} receipt(s)`,
            icon: "success"
          });
          closeAddReceipt();
          UserReceiptsStore.refreshReceipts(tripId);
        });
      }}
    />
  </div>
);

export default connect(mapStateToProps, mapDispatchToProps)(AddReceiptModal);
