import PropTypes from "prop-types";
import { capitaliseFirst } from "../utils/strings";
import { LocalDate } from "js-joda/dist/js-joda";

export const DateRangeString = ({ start, end }) => {
  try {
    const startLD = LocalDate.parse(start);
    const endLD = LocalDate.parse(end);

    if (startLD.year() === endLD.year()) {
      if (startLD.monthValue() === endLD.monthValue()) {
        return `${day(startLD)} - ${day(endLD)} ${month(startLD)} ${year(
          startLD
        )}`;
      } else {
        return `${dayAndMonth(startLD)} - ${dayAndMonth(endLD)} ${year(
          startLD
        )}`;
      }
    } else {
      return dayMonthYear(startLD) + " - " + dayMonthYear(endLD);
    }
  } catch (err) {
    return "n/a";
  }
};

const day = (date) => date.dayOfMonth();
const month = (date) =>
  capitaliseFirst(date.month().toString().substring(0, 3));
const year = (date) => date.year().toString();

const dayAndMonth = (date) => day(date) + " " + month(date);
const dayMonthYear = (date) => day(date) + " " + month(date) + " " + year(date);

DateRangeString.propTypes = {
  start: PropTypes.string,
  end: PropTypes.string
};

export default DateRangeString;
