import { useState, useCallback } from "react";

type State = boolean;
type Callback = () => void;

export const useToggle = (defaultValue?: boolean): [State, Callback] => {
  const [state, setState] = useState(defaultValue || false);

  const toggleState = useCallback(() => setState((preValue) => !preValue), []);

  return [state, toggleState];
};
