import React, { FC, useState, useEffect } from "react";

import Button from "@components/Button";
import WevatModal from "@components/modals/WevatModal";
import Checkbox from "@components/Checkbox";
import { useAppDispatch, useAppSelector } from "@src/hooks";
import { setNotification } from "@store/global";
import {
  selectIsRejectOrAccepted,
  selectRejectionReasonNotes,
  selectReceiptRejectionReasons,
  selectIsReverify,
  selectIsWithContactSupport
} from "@store/selectors";
import { saveReceiptChanges, updateReceipt } from "@store/actions";

import "./ContactModal.scss";

type Props = {
  onClose?: () => void;
  onSubmit?: () => void;
};

export const ContactModal: FC<Props> = ({ onClose, onSubmit }) => {
  const dispatch = useAppDispatch();
  const { isRejected, isAccepted } = useAppSelector(selectIsRejectOrAccepted);
  const isReverify = useAppSelector(selectIsReverify);
  const isWithContactSupport = useAppSelector(selectIsWithContactSupport);
  const isVerified = isAccepted || isRejected;

  const rejectionReasons = useAppSelector(selectReceiptRejectionReasons);
  const rejectionReasonNotes = useAppSelector(selectRejectionReasonNotes);
  const [comment, setComment] = useState("");
  const [isChecked, setIsChecked] = useState(isWithContactSupport);

  useEffect(() => {
    if (rejectionReasonNotes) setComment(rejectionReasonNotes);
  }, [rejectionReasonNotes]);

  const handleSubmit = async () => {
    let updatedReasons = [...rejectionReasons];

    if (updatedReasons.includes("CONTACT_SUPPORT")) {
      updatedReasons = updatedReasons.filter((r) => r !== "CONTACT_SUPPORT");
    } else {
      updatedReasons.push("CONTACT_SUPPORT");
    }

    dispatch(
      updateReceipt({
        rejectionReasonNotes: comment,
        rejectionReasons: updatedReasons
      })
    );
    dispatch(saveReceiptChanges({ isLoading: false }));
    onClose?.();

    if (isChecked) {
      dispatch(setNotification({ message: "Rejected: Contact Support" }));
      onSubmit?.();
    }
  };

  const handleTextChange = (value: string) => {
    if (value.length <= 500) setComment(value);
  };

  const toggleWithContactSupport = () => {
    setIsChecked((prevValue) => !prevValue);
  };

  const shouldDisable = () => {
    if (isReverify) return false;
    return isVerified;
  };

  return (
    <WevatModal
      type="small"
      show={true}
      showCloseButton={true}
      onHide={onClose}
      width="640px"
      height="580px"
    >
      <div className="contact-modal-body">
        <h2>Contact Support</h2>
        <textarea
          disabled={shouldDisable()}
          rows={5}
          defaultValue={comment}
          onChange={(e) => handleTextChange(e.target.value)}
          placeholder="Describe why you are rejecting this receipt with ‘Contact support’."
        />
        {(!isVerified || isReverify) && (
          <p className="char-count">
            Max 500 characters: ({500 - comment.length} characters left)
          </p>
        )}

        <Checkbox
          disabled={shouldDisable()}
          isCheckboxPrefixed={true}
          label="Reject with “Contact Support”"
          checked={isChecked}
          onChange={toggleWithContactSupport}
        />

        {!shouldDisable() && (
          <Button disabled={!comment} size="big" onClick={handleSubmit}>
            {isChecked ? "Confirm reject" : "Save"}
          </Button>
        )}
      </div>
    </WevatModal>
  );
};
