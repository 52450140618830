import * as rrweb from "rrweb";

let events = [];
let stopRecorder;

const recorder = {
  start() {
    events = [];

    stopRecorder = rrweb.record({
      emit(event) {
        events.push(event);
      }
    });
  },
  stop() {
    stopRecorder();
  },
  getEvents() {
    return events;
  }
};

export default recorder;
