import ReceiptVerificationState from "../../views/receipt/stores/ReceiptVerificationState";

export const ReceiptViewActions = {
  OPEN_RECEIPT_VIEW: "OPEN_RECEIPT_VIEW",
  OPEN_RECEIPT_VIEW_V2: "OPEN_RECEIPT_VIEW_V2",
  CLOSE_RECEIPT_VIEW: "CLOSE_RECEIPT_VIEW",
  REJECTION_REASON_CHANGED: "REJECTION_REASON_CHANGED",
  ON_DATE_CHANGED: "ON_DATE_CHANGED",
  ON_TIME_CHANGED: "ON_TIME_CHANGED",
  ADD_BLANK_ITEM: "ADD_BLANK_ITEM",
  ITEM_CHANGED: "RECEIPT_ITEM_CHANGED",
  ITEM_DELETED: "RECEIPT_ITEM_DELETED",
  RECEIPT_UPDATED: "RECEIPT_UPDATED",
  RECEIPT_SKIP: "RECEIPT_SKIP",
  INVALID_OR_MISSING_INPUT: "INVALID_OR_MISSING_INPUT",
  DISMISS_IS_OPEN_WARNING: "DISMISS_IS_OPEN_WARNING",
  ERROR_RESOLVED: "RECEIPT_ERROR_RESOLVED",
  FORCE_UPDATE: "FORCE_UPDATE",
  RECEIPT_QA_MODIFY: "RECEIPT_QA_MODIFY"
};

export const onRejectionReasonUpdated = (dispatch) => (_, reason) =>
  dispatch({ type: ReceiptViewActions.REJECTION_REASON_CHANGED, reason });

export const addBlankItem = (dispatch) => () =>
  dispatch({ type: ReceiptViewActions.ADD_BLANK_ITEM });

export const onItemChanged = (dispatch) => (item) =>
  dispatch({ type: ReceiptViewActions.ITEM_CHANGED, item });

export const onItemDeleted = (dispatch) => (itemId) =>
  dispatch({ type: ReceiptViewActions.ITEM_DELETED, itemId });

export const onDateChanged = (dispatch) => (date) => {
  dispatch({ type: ReceiptViewActions.ON_DATE_CHANGED, date });
  ReceiptVerificationState.setPurchaseDate(date);
};

export const onTimeChanged = (dispatch) => (time) => {
  dispatch({ type: ReceiptViewActions.ON_TIME_CHANGED, time });
  ReceiptVerificationState.setPurchaseTime(time);
};

export const onReceiptInputErrorResolved = (dispatch) => () =>
  dispatch({ type: ReceiptViewActions.ERROR_RESOLVED });

export const skipReceipt = (dispatch) => () =>
  dispatch({ type: ReceiptViewActions.RECEIPT_SKIP });

export const dismissIsOpenWarning = (dispatch) => () =>
  dispatch({ type: ReceiptViewActions.DISMISS_IS_OPEN_WARNING });
