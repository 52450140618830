export function orderByDateTime(receipts) {
  return receipts.sortBy(
    (receipt) => receipt.uploadedTime || receipt.purchaseTime
  );
}

export function getUnrejectedReceipts(receipts) {
  return receipts.filter((receipt) => receipt.status !== "REJECTED");
}

export function getRejectedReceipts(receipts) {
  return receipts.filter((receipt) => receipt.status === "REJECTED");
}

export function getReceiptsByStatus(receipts, status) {
  return receipts.filter((receipt) => receipt.status === status);
}

// ASSUMES ALL RECEIPTS HAVE A STATUS OF NEEDS_REVIEW, REVIEWED, REJECTED, CONFIRM_REJECTION
export function orderReceipts(receipts) {
  let ordered = [];
  let pending_general, pending_items, reviewed, rejected;
  pending_general = pending_items = reviewed = rejected = 0;
  receipts.forEach((receipt) => {
    let i, j;
    switch (receipt.status) {
      case "NEEDS_REVIEW":
        i = 0;
        j = pending_general;
        pending_general++;
        pending_items++;
        reviewed++;
        rejected++;
        break;

      case "REVIEWED":
        i = reviewed;
        j = rejected;
        rejected++;
        break;

      case "REJECTED":
        i = rejected;
        j = ordered.length;
        break;

      case "CONFIRM_REJECTION":
        i = pending_general;
        j = pending_items;
        reviewed++;
        rejected++;
        break;

      default:
        break;
    }
    if (ordered.size === 0) {
      ordered.push(receipt);
    } else {
      while (
        ordered[i] &&
        i < j &&
        receipt.uploadedTime < ordered[i].uploadedTime
      ) {
        i++;
      }
      ordered.splice(i, 0, receipt);
    }
  });
  return ordered;
}

export const getRejectionById = (id = "", reasons = []) =>
  reasons.find((r) => r.id === id);

export const getStatusTheme = (status = "") => {
  if (status === "REJECTED") return "red";
  if (status === "REVIEWED") return "green";
  if (status === "DELETED") return "dark-gray";
  return "gray";
};

export const getStatusLabel = (
  status = "",
  rejectionReasons = [],
  reasons = []
) => {
  if (status === "REVIEWED") return "Accepted";
  if (status === "DELETED") return "Deleted";

  if (status === "REJECTED") {
    if (rejectionReasons.includes("PURCHASE_VALUE_OVER_LIMIT")) {
      const selectedReason = getRejectionById(
        "PURCHASE_VALUE_OVER_LIMIT",
        reasons
      );
      return selectedReason?.name;
    }

    if (rejectionReasons.includes("CONTACT_SUPPORT")) {
      const selectedReason = getRejectionById("CONTACT_SUPPORT", reasons);
      return selectedReason?.name;
    }

    return "Rejected";
  }

  return "Pending";
};
