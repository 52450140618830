import { List } from "immutable";
import { merge } from "../../utils/functions";
import { AddRetailerActions } from "./add-retailer-reducer";

export const RetailerActions = {
  RETAILERS_LOADED: "RETAILERS_LOADED",
  INVALIDATE: "RETAILERS_INVALIDATE",
  VALIDATE: "RETAILERS_VALIDATE"
};

export const retailersLoaded = (dispatch) => (retailers) =>
  dispatch({ type: RetailerActions.RETAILERS_LOADED, retailers });

const initialState = {
  needsLoad: true,
  retailers: List()
};

export const retailerState = (state = initialState, action) => {
  switch (action.type) {
    case RetailerActions.RETAILERS_LOADED:
      return merge(state, { needsLoad: false, retailers: action.retailers });

    case RetailerActions.INVALIDATE:
      return merge(state, { needsLoad: true });

    case AddRetailerActions.CREATE_RETAILER_SUCCESS:
      return merge(state, { needsLoad: true });

    default:
      return state;
  }
};
