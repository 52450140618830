import React, { FC, useState, useEffect, useCallback } from "react";
import classNames from "classnames";

import NumericInput from "../../../components/NumericInput";
import Validator, { VALIDATORS } from "../../../components/Validator";
import { numberify } from "../../../utils/numbers";

import { selectItemCategories, ItemCategory } from "../store";
import { useAppSelector } from "../../../hooks";

import "./CategoryDropDown.scss";

type Props = {
  itemCode: string;
  onChange: (item: ItemCategory) => void;
};

export const CategoryDropDown: FC<Props> = ({ itemCode, onChange }) => {
  const itemCategories = useAppSelector(selectItemCategories);
  const categoryCodes = itemCategories.map((i) => i.code);
  const checkCodeIsValid = VALIDATORS.NUMBER.INCLUDES(categoryCodes);

  const [code, setCode] = useState(0);
  const [isVisible, setIsVisible] = useState(false);

  const hideDropdown = () => setIsVisible(false);

  const handleOnChange = useCallback(
    (param?: any) => {
      const matchCode = param || code;
      const itemMatch = itemCategories.find((i) => i.code === `${matchCode}`);

      if (itemMatch) onChange(itemMatch);
    },
    [code, itemCategories, onChange]
  );

  const handleFilter = (value: string) => {
    setCode(numberify(value));
    setIsVisible(true);
  };

  const handleSelectItem = (value: string) => {
    setCode(numberify(value));
    handleOnChange(value);
    hideDropdown();
  };

  const handleKeyEvent = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        handleOnChange();
        hideDropdown();
      }
    },
    [handleOnChange]
  );

  const handleMouseEvent = useCallback(
    (e: any) => {
      if (isVisible && !e.target.className.includes("item-category-dropdown")) {
        handleOnChange();
        hideDropdown();
      }
    },
    [handleOnChange, isVisible]
  );

  useEffect(() => {
    if (itemCode) setCode(parseInt(itemCode));
  }, [itemCode]);

  useEffect(() => {
    window.addEventListener("keyup", handleKeyEvent);
    window.addEventListener("mouseup", handleMouseEvent);

    return () => {
      window.removeEventListener("keyup", handleKeyEvent);
      window.removeEventListener("mouseup", handleMouseEvent);
    };
  }, [handleKeyEvent, handleMouseEvent]);

  return (
    <div className="item-category-dropdown">
      <Validator
        reset={checkCodeIsValid(`${code}`) === true}
        tipPosition="right"
        validator={checkCodeIsValid}
      >
        <NumericInput
          value={code || numberify(itemCode)}
          onChange={handleFilter}
          min={1}
          max={99}
          alignment="center"
          onFocus={() => setIsVisible(true)}
          className="item-category-dropdown__input"
        />
      </Validator>
      <div
        className={classNames("item-category-dropdown__list", {
          "item-category-dropdown__list--active": isVisible
        })}
      >
        {itemCategories.map((i) => (
          <div
            className={classNames("item-category-dropdown__list--item", {
              "item-category-dropdown__list--selected": `${code}` === i.code
            })}
            key={i.code}
            onClick={() => handleSelectItem(i.code)}
          >
            {i.code} {i.description}
          </div>
        ))}
      </div>
    </div>
  );
};
