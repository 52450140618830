import React, { useState, useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import Money from "@components/Money";
import AddedDate from "@components/AddedDate";
import ReceiptStatus from "@components/ReceiptStatus";
import Button from "@components/Button";
import {
  TableBody,
  TableBodyRow,
  TableBodyCell,
  TableHeadCell,
  TableHeader
} from "@components/WeVatTableV2";
import Checkbox from "@components/Checkbox";
import ButtonIcon from "@components/ButtonIcon";

import { getStatusLabel, getStatusTheme } from "@utils/receipts";
import Loader from "@components/Loader";
import { selectRejectionReasons } from "@store/selectors";
import { setReceiptViewList } from "@store/actions";
import { useAppSelector, useAppDispatch } from "@src/hooks";

const ReceiptTable = ({
  fetchReceipts,
  receipts,
  isLoading,
  onAddReceipt,
  onTabRefreshed,
  deleteReceipt
}) => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const reasons = useAppSelector(selectRejectionReasons);
  const gridTemplateColumns = "30px 1fr 2fr 1fr 1fr 1fr 1fr 1fr 30px auto";
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [sortBy, setSortBy] = useState("");
  const [sortDirection, setSortDirection] = useState("");
  const [itemsChecked, setItemsChecked] = useState([]);

  const vatTotal = useMemo(() => {
    return receipts.reduce((acc, next) => {
      const value = next.status === "REVIEWED" ? next?.taxTotal : 0;
      return acc + value;
    }, 0);
  }, [receipts]);

  const receiptTotal = useMemo(() => {
    return receipts.reduce((acc, next) => {
      const value = next.status === "REVIEWED" ? next?.total : 0;
      return acc + value;
    }, 0);
  }, [receipts]);

  const itemsCount = useMemo(() => {
    return receipts.reduce((acc, next) => {
      const value = next?.items?.length || 0;
      return acc + value;
    }, 0);
  }, [receipts]);

  const currency = receipts.length ? receipts[0].currency : "GBP";

  const handleRowCheckbox = (receiptId) => {
    setItemsChecked((prevList) => {
      const clonedList = [...prevList];

      if (clonedList.includes(receiptId)) {
        return clonedList.filter((id) => id !== receiptId);
      } else {
        clonedList.push(receiptId);
      }

      return clonedList;
    });
  };

  const changeOrder = (sortKey, direction) => {
    setSortBy(sortKey);
    setSortDirection(direction);

    if (receipts.length) {
      fetchReceipts(receipts[0].tripId, sortKey, direction);
    }
  };

  const checkAllReceipts = useCallback(
    (isChecked) => {
      const isAllCheckedUpdated = !isChecked;

      setIsAllChecked(isAllCheckedUpdated);

      if (isAllCheckedUpdated) {
        const checkedList = receipts
          .filter((r) => r.status !== "DELETED")
          .map((r) => r.receiptId);
        setItemsChecked(checkedList);
      } else {
        setItemsChecked([]);
      }
    },
    [receipts]
  );

  const handleDeleteReceipts = () => {
    itemsChecked.forEach((id) => deleteReceipt(id));
  };

  const handleOpenReceipt = useCallback(
    (e, receipt) => {
      if (e.target.tagName !== "BUTTON") {
        const list = receipts.concat().map(({ prev, next, ...rest }) => ({
          ...rest
        }));

        dispatch(setReceiptViewList(list));

        history.push(`/invoices/${receipt.receiptId}`, {
          fromView: "/trips",
          stayOnSameView: receipt.status !== "PENDING",
          goBack: true
        });
      }
    },
    [receipts, dispatch]
  );

  return (
    <React.Fragment>
      <TableHeader gridTemplateColumns={gridTemplateColumns}>
        <TableHeadCell title="" />
        <TableHeadCell title="Status" />
        <TableHeadCell title="Retailer" />
        <TableHeadCell title="Purchased" />
        <TableHeadCell
          title="Uploaded"
          index="UPLOAD_TIME"
          sortBy={sortBy}
          sortDirection={sortDirection}
          onSortChange={changeOrder}
        />
        <TableHeadCell
          title="Items"
          subTitle="accepted"
          index="ITEMS"
          sortBy={sortBy}
          sortDirection={sortDirection}
          onSortChange={changeOrder}
        />
        <TableHeadCell
          title="Total"
          subTitle="accepted"
          index="TOTAL"
          sortBy={sortBy}
          sortDirection={sortDirection}
          onSortChange={changeOrder}
        />
        <TableHeadCell title="VAT" subTitle="accepted" />
        <TableHeadCell title="">
          <Checkbox
            label=""
            checked={isAllChecked}
            onChange={() => checkAllReceipts(isAllChecked)}
          />
        </TableHeadCell>
        <TableHeadCell />
      </TableHeader>
      <Loader loading={isLoading} waitingMessage={"Loading receipts"}>
        <TableBody canScroll={true}>
          {receipts.map((receipt) => (
            <TableBodyRow
              key={receipt.receiptId}
              gridTemplateColumns={gridTemplateColumns}
              onClick={(e) => handleOpenReceipt(e, receipt)}
            >
              <TableBodyCell />
              <TableBodyCell>
                <ReceiptStatus
                  theme={getStatusTheme(receipt.status)}
                  label={getStatusLabel(
                    receipt.status,
                    receipt.rejectionReasons,
                    reasons
                  )}
                />
              </TableBodyCell>
              <TableBodyCell>
                <Retailer {...receipt} />
              </TableBodyCell>
              <TableBodyCell>
                {!!receipt.purchaseTime && (
                  <AddedDate
                    className="date-added"
                    defaultFormat="yyyy-MM-dd hh:mm"
                    date={receipt.purchaseTime}
                  />
                )}
              </TableBodyCell>
              <TableBodyCell>
                <AddedDate
                  defaultFormat="yyyy-MM-dd hh:mm"
                  date={receipt.uploadedTime.replace(/T/, " ")}
                />
              </TableBodyCell>
              <TableBodyCell>{receipt.items.length}</TableBodyCell>
              <TableBodyCell>
                <Money amount={receipt.total} currency={receipt.currency} />
              </TableBodyCell>
              <TableBodyCell>
                <Money amount={receipt.taxTotal} currency={receipt.currency} />
              </TableBodyCell>
              <TableBodyCell>
                <Checkbox
                  label=""
                  disabled={receipt.status === "DELETED"}
                  checked={itemsChecked.includes(receipt.receiptId)}
                  onChange={() => handleRowCheckbox(receipt.receiptId)}
                />
              </TableBodyCell>
              <TableBodyCell />
            </TableBodyRow>
          ))}
        </TableBody>

        <div className="receipts-details__footer">
          <div className="receipts-details__footer-table">
            <div className="footer-total">Total</div>
            <div className="footer-count">{itemsCount}</div>
            <div className="footer-money">
              <Money amount={receiptTotal} currency={currency} />
            </div>
            <div className="footer-money">
              <Money amount={vatTotal} currency={currency} />
            </div>
            <div />
          </div>
          <div className="receipts-details__footer-actions">
            <Button
              type="primary"
              className="receipt-footer-buttons-yes-button"
              onClick={onAddReceipt}
            >
              + Upload invoice
            </Button>
            <ButtonIcon onClick={onTabRefreshed} iconName="refresh" />
            <ButtonIcon
              onClick={handleDeleteReceipts}
              iconName="bin"
              disabled={!itemsChecked.length}
            />
          </div>
        </div>
      </Loader>
    </React.Fragment>
  );
};

const Retailer = (props) => {
  if (props.retailer && props.retailer.retailerName) {
    return <strong>{props.retailer.retailerName}</strong>;
  }
  return <span style={{ color: "#9B9B9B", fontStyle: "italic" }}>Pending</span>;
};

ReceiptTable.propTypes = {
  receipts: PropTypes.array
};

ReceiptTable.defaultProps = {
  receipts: []
};

export default ReceiptTable;
