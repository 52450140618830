import { updateTripDetails } from "../../api/users/trip-details-service";
import { ModalActions } from "../actions/modal-actions";
import { setNetworkError } from "../global";

export function updateTripDetailsAction(dispatch) {
  return function (userId, update) {
    return updateTripDetails(userId, update)
      .then(() => {
        dispatch({ type: "UPDATE_TRIP_DETAILS_SUCCESS", update });
        dispatch({ type: ModalActions.CLOSE_MODAL });
      })
      .catch((error) => {
        dispatch({ type: "UPDATE_TRIP_DETAILS_FAILURE", error });
        dispatch(setNetworkError(error));
      });
  };
}
