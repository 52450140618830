import { merge } from "../../utils/functions";

export const AddReceipt = {
  USER_ADD_RECEIPT_START_UPLOAD: "USER_ADD_RECEIPT_IMAGE_START_UPLOAD",
  USER_ADD_RECEIPT_IMAGE_UPLOADED: "USER_ADD_RECEIPT_IMAGE_UPLOADED",
  USER_ADD_RECEIPT_IMAGE_UPLOAD_ERROR: "USER_ADD_RECEIPT_IMAGE_UPLOAD_ERROR",
  USER_ADD_RECEIPT_IMAGE_DELETED: "USER_ADD_RECEIPT_IMAGE_DELETED",
  USER_ADD_RECEIPT_IMAGE_SELECTED: "USER_ADD_RECEIPT_IMAGE_SELECTED",
  USER_ADD_RECEIPT_IMAGE_COMPLETE: "USER_ADD_RECEIPT_IMAGE",
  OPEN_ADD_RECEIPT: "OPEN_ADD_RECEIPT",
  CLOSE_ADD_RECEIPT: "CLOSE_ADD_RECEIPT"
};

export const openAddReceipt = (dispatch) => () =>
  dispatch({ type: AddReceipt.OPEN_ADD_RECEIPT });

export const closeAddReceipt = (dispatch) => () =>
  dispatch({ type: AddReceipt.CLOSE_ADD_RECEIPT });

export const onReceiptImgSelected = (dispatch) => (image) =>
  dispatch({ type: AddReceipt.USER_ADD_RECEIPT_IMAGE_SELECTED, image });

const initialState = {
  error: false,
  show: false,
  isLoading: false,
  receiptImgUrl: "",
  receiptImgFile: void 0
};

export const addReceiptReducer = (state = initialState, action) => {
  switch (action.type) {
    case AddReceipt.OPEN_ADD_RECEIPT:
      return merge(state, { show: true, error: false });

    case AddReceipt.CLOSE_ADD_RECEIPT:
      return initialState;

    case AddReceipt.USER_ADD_RECEIPT_IMAGE_SELECTED:
      return merge(state, { receiptImgFile: action.image });

    case AddReceipt.USER_ADD_RECEIPT_START_UPLOAD:
      return merge(state, { isLoading: true });

    case AddReceipt.USER_ADD_RECEIPT_IMAGE_UPLOADED:
      return merge(state, {
        receiptImgUrl: action.url,
        isLoading: false,
        error: false
      });

    case AddReceipt.USER_ADD_RECEIPT_IMAGE_UPLOAD_ERROR:
      return merge(state, { isLoading: false, error: true });

    case AddReceipt.USER_ADD_RECEIPT_IMAGE_DELETED:
    case AddReceipt.USER_ADD_RECEIPT_IMAGE_COMPLETE:
      return initialState;

    default:
      return state;
  }
};
