import React from "react";
import { connect } from "react-redux";
import { view } from "react-easy-state";
import TripDatesDropdown from "../receipt-details/view/TripDatesDropdown";
import { onTripChanged } from "../../../store/reducers/selected-trip-reducer";
import { merge } from "../../../utils/functions";

import "./css/trip-and-refund.css";

const mapStateToProps = (state, ownProps) => {
  const tripId = state.selectedTripState.selectedTripId;
  const trips = state.userProfileState.trips;
  const trip = trips.filter((t) => t.tripId === tripId).first();

  if (!trip) {
    return merge(ownProps);
  }

  return {
    ...ownProps,
    trips,
    selected: tripId
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onTripChanged: onTripChanged(dispatch)
  };
};

const TripAndTotal = (props) => {
  if (props.dropdownOnly) {
    return (
      <TripDatesDropdown
        trips={props.trips}
        selected={props.selected}
        onChange={props.onTripChanged}
      />
    );
  }

  return (
    <div>
      <div className="trip-and-refund">
        <TripDatesDropdown
          trips={props.trips}
          selected={props.selected}
          onChange={props.onTripChanged}
        />
      </div>
      <hr style={{ border: "1px solid #EDEDED", margin: "32px 0" }} />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(view(TripAndTotal));
