import React, { useState, useEffect, useCallback, useRef, FC } from "react";
import classNames from "classnames";
import { v4 as uuidv4 } from "uuid";

import { selectRetailers, selectRetailerId } from "@store/selectors";

import TextInput from "@components/TextInput";
import NumericInput from "@components/NumericInput";
import CurrencyInput from "@components/CurrencyInput";
import DateTimeInput from "@components/DateTimeInput";
import Checkbox from "@components/Checkbox";
import { numberify } from "@utils/numbers";
import Validator, { VALIDATORS } from "@components/Validator";
import RetailerDropdown from "@components/RetailerDropdown";
import { Grey } from "@components/ColoredText";
import ToggleableButton from "@components/ToggleableButton";
import {
  ReceiptItem,
  ItemCategory,
  selectReceipt,
  updateReceipt,
  addNewReceiptItem,
  updateSums,
  selectSums,
  selectTrip
} from "../store";
import { formatMoney } from "@utils/currency";
import { CategoryDropDown } from "../category-dropdown";
import { removeFromList } from "@utils/arrays";
import { getCurrencySymbolFromCode } from "../helpers";
import { useAppSelector, useAppDispatch } from "@src/hooks";
import ReceiptFooter from "../receipt-footer";

import "./ReceiptDetails.scss";
import receipt from "..";

type Props = {
  showAttach: boolean;
  onShowFileAttach: () => void;
};


export const ReceiptDetails: FC<Props> = ({
  onShowFileAttach,
  showAttach,
}) => {
  const contentBody = useRef<any>();
  const contentTimer = useRef<any>();

  const dispatch = useAppDispatch();
  const prevVatRate = useRef(0);
  const {
    currency,
    purchaseTime: purchaseDateTime,
    items: receiptItems,
    receiptNumber,
    retailer,
    withoutTax,
    isDigitalReceipt,
  } = useAppSelector(selectReceipt);
  const { userId, countryCode } = useAppSelector(selectTrip);
  const { totalSum, vatSum } = useAppSelector(selectSums);

  const [purchaseDate, purchaseTime] = purchaseDateTime
    ? purchaseDateTime.split(" ")
    : ["", ""];

  const [isEveryRowSelected, setIsEveryRowSelected] = useState(false);
  const [selectedItemIds, setSelectedItemIds] = useState<string[]>([]);
  const hasSelectedItems = selectedItemIds.length > 0;

  const [acceptedSum, setAcceptedSum] = useState(0);
  const currencySymbol = getCurrencySymbolFromCode(currency);

  const retailers = useAppSelector(selectRetailers);
  const retailerId = useAppSelector(selectRetailerId);

  const retailerWithData = retailerId ? retailers.find((r) => retailerId === r.retailerId) : null
  const currentWithoutTax = withoutTax == null ? !retailerWithData?.itemsWithVat ?? true : withoutTax

  const calculateSums = useCallback(
    (list: ReceiptItem[], withoutTax: boolean) => {
      let newTotalSum = 0;
      let newVatSum = 0;
      let newAcceptedSum = 0;
      const hasRejectedItems = list.some((i) => i.isRejected);

      list.forEach((i) => {
        const val = numberify(i.cost);
        const vat = 1 + parseFloat(`${i.vatRate}`) * 0.01;
        const valWithVat = withoutTax ? val * vat : val

        if (!i.isRejected) {
          newAcceptedSum += valWithVat;
          newVatSum += valWithVat - valWithVat / vat;
        }

        newTotalSum += valWithVat;

      });

      dispatch(
        updateSums({
          totalSum: newTotalSum,
          vatSum: newVatSum
        })
      );

      setAcceptedSum(hasRejectedItems ? newAcceptedSum : newTotalSum);
    },
    [dispatch]
  );

  const toggleSelectAllItems = () => {
    setIsEveryRowSelected((prevValue) => {
      const allSelected = !prevValue;

      if (allSelected) {
        const ids = receiptItems.map((i) => i.itemId);
        setSelectedItemIds(ids);
      } else {
        setSelectedItemIds([]);
      }

      return allSelected;
    });
  };

  const toggleSelectedItems = (id: string) => {
    setSelectedItemIds((prevList) => {
      const clonedList = [...prevList];

      if (clonedList.includes(id)) {
        return clonedList.filter(removeFromList(id));
      }

      clonedList.push(id);
      return clonedList;
    });
  };

  const updateItemById = useCallback(
    (id: string, data: Partial<ReceiptItem>) => {
      dispatch(
        updateReceipt({
          items: [...receiptItems].map((item) => {
            return item.itemId === id
              ? {
                ...item,
                ...data
              }
              : item;
          })
        })
      );
    },
    [receiptItems, dispatch]
  );

  const resetSelection = () => {
    setSelectedItemIds([]);
    setIsEveryRowSelected(false);
  };

  const removeSelectedItems = useCallback(
    function <T>(list: T[], key?: string) {
      const clonedList = [...list];
      return clonedList.filter(
        (i) => !selectedItemIds.includes(key ? i[key] : i)
      );
    },
    [selectedItemIds]
  );

  const handleRemoveSelectedItems = useCallback(() => {
    dispatch(
      updateReceipt({
        items: removeSelectedItems(receiptItems, "itemId")
      })
    );
    resetSelection();
  }, [receiptItems, dispatch, removeSelectedItems]);

  const handleRejectedItems = useCallback(
    (ids: string[]) => {
      const clonedList = [...receiptItems];
      const updatedItems = clonedList.map((r) => ({
        ...r,
        isRejected: r.isRejected || ids.includes(r.itemId)
      }));

      dispatch(updateReceipt({ items: updatedItems }));
      resetSelection();
    },
    [receiptItems, dispatch]
  );

  const handleAddItem = () => {
    dispatch(
      addNewReceiptItem({
        airside: false,
        cost: "0.00",
        description: "",
        itemId: uuidv4(),
        quantity: 1,
        vatRate: 20,
        isRejected: false,
        categoryCode: retailerWithData?.defaultCategory || "60"
      })
    );

    if (contentTimer.current) {
      clearTimeout(contentTimer.current);
    }

    contentTimer.current = setTimeout(() => {
      if (contentBody?.current) {
        contentBody.current.scrollTop = contentBody.current?.scrollHeight ?? 0;
      }
    }, 100);
  };

  const handleItemCategory = (itemCat: ItemCategory, itemId: string) => {
    updateItemById(itemId, {
      categoryCode: itemCat.code,
      isRejected: itemCat.autoReject
    });
  };

  const setPurchaseDateTime = (purchaseTime: string) => {
    dispatch(updateReceipt({ purchaseTime }));
  };

  const setReceiptNumber = (receiptNumber: string) => {
    dispatch(updateReceipt({ receiptNumber }));
  };

  const setWithoutTax = (withoutTax: boolean) => {
    dispatch(updateReceipt({ withoutTax }));
    calculateSums(receiptItems, withoutTax)
  }

  const setIsDigitalReceipt = (isDigitalReceipt: boolean) => {
    dispatch(updateReceipt({ isDigitalReceipt }));
  }

  const formatTime = (value: string) => {
    return value && value.match(/:/g).length === 1 ? `${value}:00` : value;
  };

  const hasScrollbar = () => {
    const element = contentBody?.current;
    return element?.scrollHeight > element?.clientHeight;
  };

  useEffect(() => {
    if (!receiptItems.length) {
      handleAddItem();
    }

    calculateSums(receiptItems, withoutTax);
  }, [receiptItems, calculateSums]);

  return (
    <div className="receipt-details">
      <div className="receipt-details-header">
        <RetailerDropdown />
        <TextInput
          value={receiptNumber}
          maxLength={30}
          placeholder="Enter invoice no."
          onChange={setReceiptNumber}
        />
        <DateTimeInput
          date={purchaseDate}
          time={purchaseTime}
          onDateChange={(updatedDate) =>
            setPurchaseDateTime(`${updatedDate}${purchaseTime ? " " + formatTime(purchaseTime) : ""}`)
          }
          onTimeChange={(updatedTime) =>
            setPurchaseDateTime(`${purchaseDate}${updatedTime ? " " + formatTime(updatedTime) : ""}`)
          }
        />
        <Checkbox
          label=""
          checked={isEveryRowSelected}
          onChange={toggleSelectAllItems}
        />
      </div>

      <div
        className={classNames("receipt-details-body", {
          "receipt-details-body--scroll": hasScrollbar()
        })}
        ref={(ele) => (contentBody.current = ele)}
      >
        <div className="receipt-details-row text-left">
          <Grey>Cat.</Grey>
          <Grey>Description</Grey>
          <Grey>Qty</Grey>
          <Grey>Price</Grey>
        </div>

        {receiptItems.map((item) => (
          <div
            key={item.itemId}
            className={classNames("receipt-details-row", {
              "receipt-details-row--rejected": item.isRejected
            })}
          >
            <CategoryDropDown
              itemCode={item?.categoryCode || retailer?.defaultCategory || "60"}
              onChange={(c) => handleItemCategory(c, item.itemId)}
            />
            <div className="input-wrap">
              <Validator validator={VALIDATORS.TEXT.NOT_EMPTY}>
                <TextInput
                  value={item.description}
                  maxLength={70}
                  onChange={(description) => {
                    updateItemById(item.itemId, { description });
                  }}
                />
              </Validator>
              <Validator
                reset={countryCode == 'FR' ? VALIDATORS.VAT_RATE.FR().apply(item.vatRate) : VALIDATORS.VAT_RATE.GENERIC().apply(item.vatRate)}
                validator={countryCode == 'FR' ? VALIDATORS.VAT_RATE.FR() : VALIDATORS.VAT_RATE.GENERIC()}
              >
                <NumericInput
                  value={item.vatRate}
                  min={1}
                  max={99}
                  alignment="center"
                  onFocus={() => (prevVatRate.current = item.vatRate)}
                  onChange={(vatRate: any) => {
                    updateItemById(item.itemId, {
                      vatRate: vatRate[0] === "." ? `0${vatRate}` : vatRate
                    });
                  }}
                  onBlur={(vatRate: any) => {
                    updateItemById(item.itemId, {
                      vatRate,
                      isRejected: vatRate === "0"
                    });
                  }}
                  onKeyUp={(e) => {
                    if (e.key === "Escape") {
                      updateItemById(item.itemId, {
                        vatRate: numberify(prevVatRate.current)
                      });
                      e?.currentTarget?.blur();
                    }
                  }}
                >
                  %
                </NumericInput>
              </Validator>
            </div>
            <Validator validator={VALIDATORS.NUMBER.BETWEEN(1, 99)}>
              <NumericInput
                value={item.quantity}
                min={1}
                max={99}
                alignment="center"
                onChange={(quantity) => {
                  updateItemById(item.itemId, {
                    quantity: numberify(quantity)
                  });
                }}
              />
            </Validator>
            <Validator validator={VALIDATORS.NUMBER.MORE_THAN(0.01)}>
              <CurrencyInput
                hideCurrency={true}
                value={item.cost}
                onChange={(cost) => updateItemById(item.itemId, { cost })}
              />
            </Validator>
            <Checkbox
              label=""
              checked={selectedItemIds.includes(item.itemId)}
              onChange={() => toggleSelectedItems(item.itemId)}
            />
          </div>
        ))}


        <div className="receipt-details-total__row">
          <div className="text-right"></div>
          <div className="text-left"></div>
          <div className="text-right">
            <ToggleableButton checked={currentWithoutTax} onChange={setWithoutTax} style={{ margin: "9px" }}>
              {currentWithoutTax ? "HT (net)" : "TTC (gross)"}
            </ToggleableButton>
            <ToggleableButton checked={isDigitalReceipt ? isDigitalReceipt : false} onChange={() => setIsDigitalReceipt(isDigitalReceipt ? !isDigitalReceipt : true)}>
              {isDigitalReceipt ? "E-invoice" : "Paper Invoice"}
            </ToggleableButton>
          </div>
        </div>
      </div>

      <div className="receipt-details-footer">
        <div className="receipt-details-footer__buttons">
          <ReceiptFooter
            isRejectBtnDisabled={!hasSelectedItems}
            isRemoveBtnDisabled={!hasSelectedItems}
            isAttachBtnDisabled={showAttach}
            userId={userId}
            confirmLabel="+ Add Item"
            onConfirm={handleAddItem}
            onRemove={handleRemoveSelectedItems}
            onReject={() => handleRejectedItems(selectedItemIds)}
            onAttach={onShowFileAttach}
          />
        </div>

        <div className="receipt-details-total">
          <div className="receipt-details-total__row">
            <div className="text-right">All</div>
            <div className="text-left">{currencySymbol}</div>
            <div className="text-right">{formatMoney(totalSum)}</div>
          </div>
          <div className="receipt-details-total__row">
            <div className="text-right">Accepted</div>
            <div className="text-left">{currencySymbol}</div>
            <div className="text-right">{formatMoney(acceptedSum)}</div>
          </div>
          <div className="receipt-details-total__row">
            <div className="text-right">VAT</div>
            <div className="text-left">{currencySymbol}</div>
            <div className="text-right">{formatMoney(vatSum)}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
