import React from "react";
import PropTypes from "prop-types";
import WevatModal from "./WevatModal";
import { Red } from "../ColoredText";

import "../css/error-modal.css";

const ErrorModal = props => (
  <div className={"error-modal"}>
    <WevatModal type="error" onHide={props.onHide} show={props.show}>
      <div className={"content"}>
        <div className="modal-header">
          <h3>Error</h3>
        </div>

        <img
          src="https://media3.giphy.com/media/Rkis28kMJd1aE/giphy.gif"
          alt={"error!"}
        />

        <p>Oops</p>

        <div className={"bottom"}>
          <Red>{props.message}</Red>
        </div>
      </div>
    </WevatModal>
  </div>
);

ErrorModal.propTypes = {
  show: PropTypes.bool,
  message: PropTypes.string,
  onHide: PropTypes.func
};

export default ErrorModal;
